/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const home_care_cleaning : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["LAUNDRY", "Na pranie a \u017eehlenie"],
        ["DISHWASHING", "Na riad, do um\u00fdva\u010dky riadu"],
        ["HOUSEHOLD_CLEANERS", "Upratovanie"],
        ["AIR_FRESHENERS", "Osvie\u017eova\u010de vzduchu a v\u00f4ne do bytu"],
        ["OTHER_HOME_CARE_CLEANING", "In\u00e9"],
    ],
};
