/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp7 } from './commonProp7';
import { commonProp4 } from './commonProp4';

export const gymnastics_ballet_clothing : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp7,
    p_brand: [
        d("ballet rosa"),
        d("bloch"),
        d("Capezio"),
        d("dancee"),
        d("DanceMaster"),
        d("DanceMe"),
        d("dansez vous"),
        d("domyos"),
        d("emza"),
        d("freed of london"),
        d("Freestyle dance"),
        d("intermezzo"),
        d("K.H. Martin"),
        d("Leo"),
        d("mirella"),
        d("Pridance"),
        d("sansha"),
        d("Skazz by Sansha"),
        d("so danca"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
