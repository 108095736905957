/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const home_lighting : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["WORK_LAMPS", "Pracovn\u00e9 lampy"],
        ["TABLE_LAMPS", "Stolov\u00e9 lampy"],
        ["FLOOR_LAMPS", "Stojacie lampy (na zem)"],
        ["CEILING_LIGHTS", "Lustre, svietidl\u00e1 na strop a stenu"],
        ["BATHROOM_LIGHTING", "K\u00fape\u013e\u0148ov\u00e9 svietidl\u00e1"],
        ["KITCHEN_LIGHTING", "Kuchynsk\u00e9 osvetlenie"],
        ["OUTDOOR_LIGHTING", "Vonkaj\u0161ie osvetlenie"],
        ["BULBS", "\u017diarovky, \u017eiarivky, LED zdroje"],
        ["OTHER_LIGHTNING", "In\u00e9"],
    ],
};
