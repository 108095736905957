/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp4 } from './commonProp4';

export const adults_handicrafts : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["HANDICRAFTS_TOOLS", "N\u00e1stroje"],
        ["HANDICRAFTS_COLORS", "Farba, lepidlo, povrchov\u00e1 \u00faprava"],
        ["HANDICRAFTS_FABRIC", "L\u00e1tky, papier"],
        ["HANDICRAFTS_THREADS", "Vlna, ni\u0165, \u0161pag\u00e1t"],
        ["HANDICRAFTS_SMALL_MATERIAL", "Mal\u00fd materi\u00e1l"],
        ["HANDICRAFTS_FORMS", "Formy, predlohy"],
        ["HANDICRAFTS_TUTORIALS", "N\u00e1vody"],
        ["HANDICRAFTS_MISC", "In\u00e9"],
    ],
    p_color: commonProp4,
};
