/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp17 } from './commonProp17';

export const multimedia_books : {[key: string]: Array<[string, string]>} = {
    p_for_whom: [
        ["SMALL_KIDS", "Pre najmen\u0161\u00edch"],
        ["SCHOOL_KIDS", "\u0160kol\u00e1k"],
        ["YOUTH", "Ml\u00e1de\u017e 15+"],
        ["FOR_ADULTS", "Pre dospel\u00fdch"],
        ["OTHER_AGE", "Neuveden\u00fd"],
    ],
    p_subject: [
        ["BOOK_FIRST_BOOKS", "Prv\u00e9 kni\u017eky"],
        ["BOOK_FAIRYTALES", " Rozpr\u00e1vky"],
        ["BOOK_ENCYCLOPEDIA", "Encyklop\u00e9die a n\u00e1u\u010dn\u00e9 knihy"],
        ["BOOK_EDUCATION", "U\u010debnice, skript\u00e1"],
        ["BOOK_HOBBIES", "Hobby a vo\u013en\u00fd \u010das"],
        ["BOOK_COVERING", "Z\u00e1lo\u017eky a obaly na knihy"],
        ["BOOK_POEMS_RHYMES", "B\u00e1sni\u010dky, riekanky"],
        ["BOOK_FICTION", "Rom\u00e1ny, beletria"],
        ["BOOK_POETRY", "Po\u00e9zia"],
        ["BOOK_FOOD_RECEPIES", "Varenie, pe\u010denie a v\u00fd\u017eiva"],
        ["BOOK_HOME_GARDEN", "Domov a z\u00e1hrada"],
        ["BOOK_PREGNANCY_BIRTH", "Tehotenstvo, p\u00f4rod"],
        ["BOOK_FAMILY_RELATIONSHIPS", "Starostlivos\u0165 o die\u0165a, v\u00fdchova "],
        ["BOOK_SPIRITUAL", "Ezoterika, astrol\u00f3gia"],
        ["BOOK_HEALTH", "Zdravie a fitness"],
        ["BOOK_TRAVEL", "Cestovanie"],
        ["BOOK_OTHER", "In\u00fd"],
    ],
    p_language: commonProp17,
};
