/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp2: Array<[string, string]> = [
    ["GIRLS", "Pre diev\u010dat\u00e1"],
    ["BOYS", "Pre chlapcov"],
];
