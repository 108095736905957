/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp7 } from './commonProp7';
import { commonProp4 } from './commonProp4';

export const mountaineering_clothing : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp7,
    p_brand: [
        d("Black Diamond"),
        d("Camp"),
        d("chillaz"),
        d("columbia"),
        d("devold"),
        d("Direct Alpine"),
        d("dynafit"),
        d("high point"),
        d("husky"),
        d("La Sportiva"),
        d("mammut"),
        d("montain equipment"),
        d("montane"),
        d("oc\u00fan"),
        d("ortovox"),
        d("Rafiki"),
        d("salewa"),
        d("sensor"),
        d("singing rock"),
        d("the north face"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
