/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp10: Array<[string, string]> = [
    ["WOMENS", "Pre d\u00e1my"],
    ["GIRLS", "Pre diev\u010dat\u00e1"],
];
