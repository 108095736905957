/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp2 } from './commonProp2';
import { commonProp3 } from './commonProp3';
import { commonProp4 } from './commonProp4';

export const sleeping_bags : {[key: string]: Array<[string, string]>} = {
    p_age_months: [
        ["0-6M", "0-6 mesiacov"],
        ["6-18M", "6-18 mesiacov"],
        ["18-36M", "18-36 mesiacov"],
        ["36+M", "3 roky +"],
    ],
    p_sex: commonProp2,
    p_brand: commonProp3,
    p_color: commonProp4,
};
