/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp22: Array<[string, string]> = [
    ["UNDER_ONE_YEAR", "Menej ako rok"],
    ["ONE_TO_FIVE_YEARS", "1-5 rokov"],
    ["MORE_THAN_FIVE_YEARS", "Viac ne\u017e 5 rokov"],
];
