/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const food : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["FRUITS", "Ovocie"],
        ["VEGETABLE", "Zelenina"],
        ["MUSHROOMS", "Huby"],
        ["BAKERY", "Chlieb, pe\u010divo"],
        ["CAKE", "Kol\u00e1\u010de, torty"],
        ["FLOUR", "M\u00faky, m\u00fa\u010dne zmesi a str\u00fahanky"],
        ["PASTA", "Cestoviny"],
        ["RICE", "Obilniny a ry\u017ea"],
        ["MUSLI", "M\u00fcsli, vlo\u010dky a ka\u0161e"],
        ["SEEDS", "Orechy, semienka, su\u0161en\u00e9 plody"],
        ["LEGUMINOUS", "Strukoviny"],
        ["DIARY", "\u017divo\u010d\u00ed\u0161ne mlieka a mlie\u010dne v\u00fdrobky"],
        ["SOY_MILK", "Rastlinn\u00e9 mlieka a v\u00fdrobky z nich"],
        ["EGGS", "Vajcia"],
        ["MEAT", "M\u00e4so a v\u00fdrobky z neho"],
        ["TOFU", "Tofu a alternat\u00edvy m\u00e4sa"],
        ["SEAFOOD", "Ryby a morsk\u00e9 plody"],
        ["COFFE_TEA", "K\u00e1va, \u010daj, k\u00e1voviny"],
        ["BEVERAGE", "N\u00e1poje, \u0161\u0165avy, sirupy"],
        ["SWEETENER", "Med, cukor, sladidl\u00e1"],
        ["JAM", "D\u017eemy a sladk\u00e9 n\u00e1tierky"],
        ["PATE", "Pa\u0161t\u00e9ty a slan\u00e9 n\u00e1tierky"],
        ["FET", "Oleje, tuky"],
        ["SEASONING", "So\u013e, korenie, ocot"],
        ["SAUCE", "Ke\u010dupy, hor\u010dice a om\u00e1\u010dky"],
        ["CRACKERS", "Slan\u00e9 keksy, krekry"],
        ["SWEETS", "Sladkosti"],
        ["PROCESSED", "Hotov\u00e9 jedl\u00e1 a polotovary"],
        ["BAKING", "Na pe\u010denie"],
        ["FOOD_OTHER", "In\u00e9"],
    ],
};
