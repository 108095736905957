// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const CAREER = "Kari\u00e9ra";
export const CAREER_URL = "http://www.modrykonik-hlada.com";
export const COMMERCIAL = "Inform\u00e1cie pre market\u00e9rov";
export const COMMERCIAL_URL = "http://www.modrykonik-pre-marketerov.sk/?utm_source=modrykonik.sk&utm_campaign=footer";
export const COOKIE_POLICY_PAGE = "Cookie pravidl\u00e1";
export const DATA_PRIVACY = "Ochrana osobn\u00fdch \u00fadajov";
export const FOLLOW_US_ON_FACEBOOK_URL = "https://i.modrykonik.sk/facebook-modreho-konika";
export const FOOTER_CODEX_LINK = "K\u00f3dex";
export const FOOTER_CONTACT = "Kontakt";
export const FOOTER_HELP_LINK = "Op\u00fdtaj sa n\u00e1s";
export const INSTAGRAM_MK_LINK = "https://www.instagram.com/modrykonik_sk/";
export const RULES_BAZAAR = "Pravidl\u00e1 baz\u00e1ra";
export const RULES_COMMON = "V\u0161eobecn\u00e9 podmienky pou\u017e\u00edvania";
export const STROLLER_STROLLEROPEDIA = "Ko\u010d\u00edkop\u00e9dia";
