/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const garden : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["GARDEN_DECORATION_LIGHTING", "Z\u00e1hradn\u00e9 dekor\u00e1cie, osvetlenie"],
        ["GARDEN_TOOLS_HOSES", "Z\u00e1hradn\u00e9 n\u00e1radie, hadice"],
        ["LAWN_MOWERS_SCARIFIERS", "Kosa\u010dky, vertikut\u00e1tory"],
        ["SAWS_AXES", "P\u00edly, sekery"],
        ["TO_THE_WORKSHOP", "Do dielne"],
        ["GARDEN_HOUSES_AND_GAZEBOS", "Z\u00e1hradn\u00e9 dom\u010deky a alt\u00e1ny"],
        ["GARDEN_OUTDOOR_FURNITURE", "Z\u00e1hradn\u00fd n\u00e1bytok"],
        ["PLANTS_TREES", "Rastliny, stromy"],
        ["POOLS_PONDS", "Baz\u00e9ny, jazierka"],
        ["GARDEN_GRILLS_AND_FIREPLACES", "Z\u00e1hradn\u00e9 grily a ohnisk\u00e1"],
        ["GATES_AND_FENCES", "Br\u00e1ny a ploty"],
        ["PAVING_TREADS", "Dla\u017eba, n\u00e1\u0161\u013eapy"],
        ["UMBRELLAS_AND_SHADING", "Slne\u010dn\u00edky a tienenie"],
        ["IRRIGATION", "Zavla\u017eovanie"],
        ["PLAYGROUNDS", "Detsk\u00e9 ihrisk\u00e1"],
        ["OTHER", "In\u00e9"],
    ],
};
