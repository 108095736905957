/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp4 } from './commonProp4';

export const changing_bag : {[key: string]: Array<[string, string]>} = {
    p_brand: [
        d("4baby"),
        d("7AM Enfant"),
        d("ABC Design"),
        d("Adamex"),
        d("AVENT"),
        d("Akuku"),
        d("Bababing"),
        d("BabyBj\u00f6rn"),
        d("Baby Joy"),
        d("BabyOno"),
        d("babylove"),
        d("Babymel"),
        d("Baby Merc"),
        d("Babymoov"),
        d("BabyStyle"),
        d("Babyzen"),
        d("Badabulle"),
        d("BamBam"),
        d("B\u00e9b\u00e9-Jou"),
        d("Bo Jungle"),
        d("Brio"),
        d("Britax"),
        d("Bugaboo"),
        d("Bumbleride"),
        d("Cam"),
        d("Camarelo"),
        d("Cambrass"),
        d("Canpol Babies"),
        d("Caretero"),
        d("Casualplay"),
        d("Coletto"),
        d("Concord"),
        d("Cosatto"),
        d("Cottonmoose"),
        d("Cybex"),
        d("Dema"),
        d("Doona"),
        d("EasyGo"),
        d("Easywalker"),
        d("Elodie Details"),
        d("Emmaljunga"),
        d("Espiro"),
        d("Esprit"),
        d("Fisher Price"),
        d("H&M"),
        d("Hartan"),
        d("Hauck"),
        d("Chicco"),
        d("Childhome"),
        d("IKEA"),
        d("Inglesina"),
        d("iL Tutto"),
        d("Insular"),
        d("Jan\u00e9"),
        d("Joolz"),
        d("Kalencom"),
        d("KARL LAGERFELD"),
        d("Kiddy"),
        d("Kidzroom"),
        d("Kinder Hop"),
        d("L\u00c4SSIG"),
        d("LEQUEEN"),
        d("Liliputi"),
        d("Little Company"),
        d("LUPILU"),
        d("Maclaren"),
        d("Mamas&Papas"),
        d("Maxi-Cosi"),
        d("Mayoral"),
        d("Melobaby"),
        d("Mima"),
        d("Moon"),
        d("Mutsy"),
        d("NaN\u00e1N"),
        d("Nellys"),
        d("Nuna"),
        d("Oilily"),
        d("PacaPod"),
        d("Pasito a pasito"),
        d("Peg-P\u00e9rego"),
        d("Petite Ch\u00e9rie"),
        d("Petite&Mars"),
        d("Pink Lining"),
        d("Pinkie"),
        d("Quinny"),
        d("Safety 1st"),
        d("Silver Cross"),
        d("Sensillo"),
        d("Skip Hop"),
        d("Stokke"),
        d("Summer Infant"),
        d("\u0160mudlo"),
        d("Tako"),
        d("Teutonia"),
        d("Wallaboo"),
        d("X-Lander"),
        d("Zara"),
        d("Zopa"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
