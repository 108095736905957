/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp22 } from './commonProp22';

export const health_and_beauty : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["HAIR_CARE", "Starostlivos\u0165 o vlasy"],
        ["NAIL_CARE", "Starostlivos\u0165 o nechty"],
        ["COSMETICS", "Kozmetika"],
        ["MASSAGES", "Mas\u00e1\u017ee"],
        ["PHYSIOTHERAPY", "Fyzioterapia"],
        ["FITNESS_ADVICE", "Fit poradenstvo"],
        ["TATTOOING", "Tetovanie"],
        ["ANIMAL_CARE", "Starostlivos\u0165 o zvierat\u00e1"],
    ],
    p_practice_length: commonProp22,
};
