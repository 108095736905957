/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp4: Array<[string, string]> = [
    ["WHITE", "Biela"],
    ["CREAMY", "Kr\u00e9mov\u00e1 / b\u00e9\u017eov\u00e1"],
    ["PALE_YELLOW", "Bledo\u017elt\u00e1"],
    ["STRONG_YELLOW", "\u017dlt\u00e1"],
    ["OCHER", "Hor\u010dicov\u00e1"],
    ["ORANGE", "Oran\u017eov\u00e1"],
    ["SALMON_ORANGE", "Lososov\u00e1"],
    ["RED", "\u010cerven\u00e1"],
    ["WINE_RED", "Bordov\u00e1"],
    ["PALE_PINK", "Bledoru\u017eov\u00e1"],
    ["STRONG_PINK", "S\u00fdto ru\u017eov\u00e1"],
    ["PURPLE", "Fialov\u00e1"],
    ["PASTEL_VIOLET", "\u0160edofialov\u00e1"],
    ["PALE_BLUE", "Bledomodr\u00e1"],
    ["DARK_BLUE", "Tmavomodr\u00e1"],
    ["TURQUOISE_BLUE", "Tyrkysov\u00e1"],
    ["MINT", "M\u00e4tov\u00e1"],
    ["PALE_GREEN", "Bledozelen\u00e1"],
    ["DARK_GREEN", "Tmavozelen\u00e1"],
    ["OLIVE_GREEN", "Olivov\u00e1 / khaki"],
    ["LIGHT_BROWN", "Bledohned\u00e1"],
    ["DARK_BROWN", "Tmavohned\u00e1"],
    ["LIGHT_GREY", "Svetlo\u0161ed\u00e1"],
    ["DARK_GREY", "Tmavo\u0161ed\u00e1"],
    ["DEEP_BLACK", "\u010cierna"],
    ["TRANSPARENT", "Priesvitn\u00e1 / prieh\u013eadn\u00e1"],
    ["GOLD", "Zlat\u00e1"],
    ["SILVER", "Strieborn\u00e1"],
    ["COPPER", "Meden\u00e1"],
    ["BLACK_AND_WHITE", "\u010ciernobiela"],
    ["CRAZY_COLOR_MIX", "\u0160ialene mnohofarebn\u00e9"],
];
