/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const toys : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["TOYS_FOR_PRAMS", "Na ko\u010d\u00edk a autoseda\u010dku"],
        ["TOYS_CREATIVE_KITS", "Hracie deky a hrazdi\u010dky"],
        ["TOYS_FIRST_TOYS", "Hrk\u00e1lky, prv\u00e9 hra\u010dky"],
        ["TOYS_PUSH_AND_PULL", "Na \u0165ahanie a tla\u010denie"],
        ["TOYS_DOLLS", "B\u00e1biky a pr\u00edslu\u0161enstvo"],
        ["TOYS_VEHICLES", "Aut\u00e1, dopravn\u00e9 prostriedky a pr\u00edslu\u0161enstvo"],
        ["TOYS_TOOLS", "Kuchynky, dom\u00e1cnos\u0165, n\u00e1radie"],
        ["TOYS_MUSIC", "Hudobn\u00e9 hra\u010dky a n\u00e1stroje"],
        ["TOYS_CONSTRUCTION_KITS", "Lego a Stavebnice"],
        ["TOYS_PUZZLE", "Puzzle, vklada\u010dky, hlavolamy"],
        ["TOYS_GAMES", "Spolo\u010densk\u00e9 hry"],
        ["COMPUTER_GAMES", "Elektronick\u00e9 hra\u010dky"],
        ["TOYS_FIGURINE", "Postavi\u010dky, fig\u00farky"],
        ["TOYS_ANIMALS", "Zvierat\u00e1"],
        ["TOYS_ROBOTS", "Roboti"],
        ["TOYS_ARMS", "Zbrane"],
        ["TOYS_SLIDES_HOUSES", "\u0160myk\u013eavky, hojda\u010dky, dom\u010deky"],
        ["TOYS_OUTDOOR", "Hra\u010dky na von"],
        ["TOWS_WATER", "Do vody"],
        ["TOYS_HANDICRAFTS", "Tvorenie, ma\u013eovanie, ru\u010dn\u00e9 pr\u00e1ce"],
        ["TOYS_EDUCATIONAL", "Vzdel\u00e1vacie a didaktick\u00e9 hra\u010dky"],
        ["TOYS_COLLECTIBLES", "Zberate\u013estvo"],
        ["WALKER_PUSHALONG", "Chod\u00edtka"],
        ["RIDEON", "Odr\u00e1\u017eadl\u00e1"],
        ["ELECTRIC-RIDEON", "Motorov\u00e9, \u0161liapacie"],
        ["TOYS_ADULTS", "Hry pre dospel\u00fdch"],
    ],
    p_material: [
        ["FROM_WOOD", "Dreven\u00e9"],
        ["FROM_PLUSH", "Ply\u0161ov\u00e9"],
        ["FROM_FABRIC", "L\u00e1tkov\u00e9"],
        ["FROM_RUBBER", "Gumen\u00e9"],
        ["FROM_FOAM", "Penov\u00e9"],
        ["FROM_AIR", "Nafukovacie"],
        ["FROM_PVC", "Plastov\u00e9"],
        ["FROM_METAL", "Kovov\u00e9"],
        ["FROM_PAPER", "Papierov\u00e9"],
        ["FROM_LIQUID_MOLDABLE", "Tekut\u00e9, plastick\u00e9"],
        ["FROM_KNIT", "H\u00e1\u010dkovan\u00e9, pleten\u00e9"],
        ["FROM_CERAMIC", "Keramick\u00e9"],
    ],
    p_sex: [
        ["GIRLS", "Pre diev\u010dat\u00e1"],
        ["BOYS", "Pre chlapcov"],
        ["ADULTS", "Pre dospel\u00fdch"],
    ],
};
