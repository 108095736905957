// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const FROM = "Od";
export const NO = "Nie";
export const number_MONTHS = ["%(number)s mesiac", "%(number)s mesiace", "%(number)s mesiacov"];
export const STROLLER_FEATURE_AVAILABILITY_ALL = "v\u0161etky (aj nevyr\u00e1ban\u00e9)";
export const STROLLER_FEATURE_AVAILABILITY_AVAILABLE = "v\u0161etky aktu\u00e1lne dostupn\u00e9";
export const STROLLER_FEATURE_AVAILABILITY_ONLY_NEW = "len nov\u00e9";
export const STROLLER_FEATURE_AVAILABILITY_ONLY_OLD = "len baz\u00e1rov\u00e9";
export const STROLLER_FEATURE_PRICE_RANGE_1 = "Menej ako 300 \u20ac";
export const STROLLER_FEATURE_PRICE_RANGE_2 = "300 \u20ac - 400 \u20ac";
export const STROLLER_FEATURE_PRICE_RANGE_3 = "400 \u20ac - 500 \u20ac";
export const STROLLER_FEATURE_PRICE_RANGE_4 = "500 \u20ac - 700 \u20ac";
export const STROLLER_FEATURE_PRICE_RANGE_5 = "700 \u20ac - 1 000 \u20ac";
export const STROLLER_FEATURE_PRICE_RANGE_6 = "1 000 \u20ac a viac";
export const STROLLER_FEATURE_RECOMMENDED_AGE_MORE_MONTHS = "a viac";
export const STROLLERS_SEARCH_FACET_BRAND = "Zna\u010dka";
export const STROLLERS_SEARCH_FULLTEXT = "H\u013eadaj v n\u00e1zve a popise ko\u010d\u00edkov";
export const TO = "Do";
export const YES = "\u00c1no";
