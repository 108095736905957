/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';

export const fancy_dress : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["FANCY_DRESS", "Kost\u00fdm"],
        ["HATS_HEADWEAR", "Klob\u00faky, \u010delenky a pokr\u00fdvky hlavy"],
        ["WIGS", "Parochne a pr\u00ed\u010desky"],
        ["MASKS", "\u0160krabo\u0161ky a masky na tv\u00e1r"],
        ["FOOTWEAR", "Obuv"],
        ["FACIAL_HAIR", "Brady a f\u00fazy"],
        ["GLASSES_JEWELLERY", "Okuliare, bi\u017eut\u00e9ria"],
        ["BOA_HAND_FAN_WINGS", "Boa, vej\u00e1re, kr\u00eddla"],
        ["WEAPONS_ARMOUR", "Me\u010de, pi\u0161tole a zbrane"],
        ["GLOVES_TIES_BRACES", "Rukavice, kravaty a traky"],
        ["FANCY_DRESS_ACCESSORIES_OTHER", "Ostatn\u00e9 doplnky"],
    ],
    p_thema: [
        ["THEMA_ANIMALS", "Zvierat\u00e1 a hmyz"],
        ["THEMA_FLOWERS", "Kvietky a rastlinky"],
        ["THEMA_PRINCESS_FAIRY_BALLET", "Princezn\u00e9, v\u00edly a tane\u010dnice"],
        ["THEMA_WITCH_WIZARD", "\u010carodejnice, \u010darodejn\u00edci, k\u00fazeln\u00edci"],
        ["THEMA_HALLOWEEN_VAMPIRE_SCARY", "Halloween, up\u00edri, kostry, stra\u0161idl\u00e1"],
        ["THEMA_CLOWN", "\u0160a\u0161ovia a klauni"],
        ["THEMA_OCCUPATION", "Profesie, remesl\u00e1"],
        ["THEMA_NINJA_WARRIOR", "Ninjovia a bojovn\u00edci"],
        ["THEMA_PIRATES_SAILORS", "Pir\u00e1ti, pir\u00e1tky a n\u00e1morn\u00edci"],
        ["THEMA_INDIAN", "Indi\u00e1ni a indi\u00e1nky"],
        ["THEMA_COWBOYS", "Kovboji"],
        ["THEMA_PRINCE_KNIGHT", "Princovia a rytieri"],
        ["THEMA_FILM_CHARACTERS", "Filmov\u00ed hrdinovia"],
        ["THEMA_FOLK_DRESS", "\u013dudov\u00fd kroj"],
        ["THEMA_ANJEL_DEVIL_SANTA", "Anjel, \u010dert, Mikul\u00e1\u0161"],
        ["THEMA_20S_30S", "20. a 30. roky"],
        ["THEMA_60S_HIPPIES", "60. roky, hippies"],
        ["THEMA_HAWAIIAN_PARTY", "Havajsk\u00e1 p\u00e1rty"],
        ["THEMA_OTHER", "In\u00e1 t\u00e9ma"],
    ],
    p_sex: commonProp5,
    p_age_years: [
        ["0-1YEARS", "B\u00e1b\u00e4tko (0-1 rok)"],
        ["1-3YEARS", "Bato\u013ea (1-3 roky)"],
        ["3-6YEARS", "\u0160k\u00f4lkar (3-6 rokov)"],
        ["6-10YEARS", "Mal\u00fd \u0161kol\u00e1k (6-10 rokov)"],
        ["10-15YEARS", "Ve\u013ek\u00fd \u0161kol\u00e1k (10-15 rokov)"],
        ["15+YEARS", "Dospel\u00fd (15+ rokov)"],
        ["OTHER_AGE", "Neuveden\u00fd"],
    ],
};
