/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const finance : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["BOOKKEEPING", "\u00da\u010dtovn\u00edctvo"],
        ["FINANCIAL_ADVICE", "Finan\u010dn\u00e9 poradenstvo"],
        ["MORTGAGE_ADVICE", "Hypotek\u00e1rne poradenstvo"],
        ["INSURANCE", "Poistenie"],
    ],
};
