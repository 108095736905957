/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const hiking_mats : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_brand: [
        d("alpine pro"),
        d("brother"),
        d("campgo"),
        d("coleman"),
        d("easy camp"),
        d("ferrino"),
        d("hannah"),
        d("husky"),
        d("king camp"),
        d("klymit"),
        d("master"),
        d("mc kinley"),
        d("naturehike"),
        d("nils camp"),
        d("outwell"),
        d("robens"),
        d("spokey"),
        d("trimm"),
        d("vango"),
        d("yate"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
