/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp13 } from './commonProp13';
import { commonProp23 } from './commonProp23';
import { commonProp4 } from './commonProp4';

export const car_seats : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["1_REAR_FACING", "Do 13 kg (vaj\u00ed\u010dko)"],
        ["1_2_FORWARD_FACING", "Do 18 kg"],
        ["2_FORWARD_FACING", "9-18 kg"],
        ["1_2_3_FORWARD_FACING", "Do 25 kg"],
        ["BAZAAR_2_3_FORWARD_FACING", "9-25 kg"],
        ["2_3_4_FORWARD_FACING", "9-36 kg"],
        ["3_BOOSTER_SEATS", "15-25 kg"],
        ["3_4_BOOSTER_SEATS", "15-36 kg"],
        ["4_BOOSTER_SEATS", "22-36 kg"],
        ["1_2_3_4_FORWARD_FACING", "0-36kg"],
    ],
    p_brand: commonProp13,
    p_isofix: commonProp23,
    p_seat_facing: [
        ["FORWARD_FACING", "V smere"],
        ["REAR_FACING", "Proti smeru"],
    ],
    p_color: commonProp4,
};
