/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp21 } from './commonProp21';
import { commonProp4 } from './commonProp4';

export const home_textiles : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["BEDDING", "Poste\u013en\u00e1 bielize\u0148"],
        ["CHAIR_COUCH_COVERS", "N\u00e1vleky na stoli\u010dky, gau\u010de"],
        ["CUSHION_COVERS", "Dekora\u010dn\u00e9 vank\u00fa\u0161e"],
        ["DUVETS_PILLOWS", "Vank\u00fa\u0161e, papl\u00f3ny"],
        ["QUILTS_ADN_THROWS", "Deky, prikr\u00fdvky a prehozy"],
        ["CURTAINS", "Z\u00e1clony, z\u00e1vesy a garni\u017ee"],
        ["KITCHEN_TEXTILES", "Utierky, ch\u0148apky, z\u00e1stery"],
        ["TABLE_CLOTHS_ADN_RUNNERS", "Obrusy a \u0161t\u00f3ly"],
        ["PLACE_MATS_COASTERS", "Prestieranie a podlo\u017eky pod poh\u00e1re"],
        ["CHAIR_PADS", "Podsed\u00e1ky a vank\u00fa\u0161e na stoli\u010dky"],
        ["TOWELS", "Uter\u00e1ky a osu\u0161ky"],
        ["GARDEN_TEXTILES", "Text\u00edlie na balk\u00f3n, terasu"],
        ["OTHER_TEXTILES", "In\u00e9"],
    ],
    p_thema: commonProp21,
    p_color: commonProp4,
};
