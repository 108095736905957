/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const heavy_machinery : {[key: string]: Array<[string, string]>} = {
    p_brand: [
        d("Balkancar"),
        d("Bobcat"),
        d("Caterpillar"),
        d("Claas"),
        d("Desta"),
        d("Gehl"),
        d("Hamm"),
        d("Hitachi"),
        d("Hyster"),
        d("JCB"),
        d("Jinma"),
        d("John Deere"),
        d("Kalmar"),
        d("Komatsu"),
        d("Kubota"),
        d("Liebherr"),
        d("Linde"),
        d("New Holland"),
        d("Toyota"),
        d("Volvo"),
        d("Yale"),
        d("Zetor"),
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
};
