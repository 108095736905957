/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const dining_other : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["LEARNING_TOWERS", "U\u010diace ve\u017ee"],
        ["CUP", "Hrn\u010dek"],
        ["BOTTLE", "F\u013ea\u0161a (nie kojeneck\u00e1)"],
        ["CUTLERY", "Pr\u00edbor"],
        ["PLATES_BOWLS", "Taniere, misky"],
        ["DINING_SET", "Jed\u00e1lensk\u00fd set"],
        ["DINING_MAT", "Jed\u00e1lensk\u00e1 podlo\u017eka"],
        ["REFILLABLE_POCKETS", "Naplnite\u013en\u00e9 kapsi\u010dky"],
        ["FRUIT_FEEDING_NETS", "K\u0155miaca sie\u0165ka na ovocie"],
        ["APRON_BIB", "Z\u00e1sterka, podbradn\u00edk"],
        ["THERMOS", "Termoska, termoobal"],
        ["OTHER_DINING", "In\u00e9"],
    ],
};
