/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp12 } from './commonProp12';
import { commonProp4 } from './commonProp4';

export const fashion_accessories_handbags : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["BIG_HANDBAGS", "Ve\u013ek\u00e9 kabelky a ta\u0161ky, shopper"],
        ["SHOULDER_HANDBAGS", "Kabelky na rameno, crossbody"],
        ["HANDBAGS", "Kabelky do ruky"],
        ["SMALL_HANDBAGS", "Listov\u00e9 a mal\u00e9 kabelky"],
        ["TEXTILE_BAGS", "Textiln\u00e9 ta\u0161ky"],
        ["HANDBAG_ACCESSORIES", "Pr\u00edslu\u0161enstvo"],
        ["FANNY_PACK", "\u013dadvinka"],
        ["BABY_HANDYBAG", "Detsk\u00e1 ta\u0161ti\u010dka, kabelka"],
        ["BABY_SUITCASE", "Kufr\u00edk"],
        ["SUITCASE", "Cestovn\u00fd kufor, ta\u0161ka"],
        ["RUCKSACK", "Ruksak"],
        ["SETS", "Kompletn\u00e9 sady"],
    ],
    p_sex: commonProp5,
    p_brand: commonProp12,
    p_color: commonProp4,
};
