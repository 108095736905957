/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp5: Array<[string, string]> = [
    ["WOMENS", "Pre d\u00e1my"],
    ["MENS", "Pre p\u00e1nov"],
    ["GIRLS", "Pre diev\u010dat\u00e1"],
    ["BOYS", "Pre chlapcov"],
];
