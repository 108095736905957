/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp13 } from './commonProp13';
import { commonProp4 } from './commonProp4';

export const car_seats_accessories : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["TRAVEL_PILLOW", "Cestovn\u00fd vank\u00fa\u0161"],
        ["CAR_SEATS_BASE", "Z\u00e1klad\u0148a, b\u00e1za"],
        ["SUN_VISORS", "Slne\u010dn\u00e9 clony"],
        ["FOOTMUFFS_BLANKETS", "Fusaky, deky"],
        ["CAR_STICKERS", "N\u00e1lepky na auto"],
        ["CAR_SEAT_INSERTS", "Vlo\u017eka"],
        ["CAR_SEAT_COVERS", "Po\u0165ah"],
        ["CAR_SEAT_PROTECTIONS", "Ochrana sedadla"],
        ["CAR_SEAT_ORGANIZERS", "Organiz\u00e9r"],
        ["CAR_SEAT_BELT_PROTECTIONS", "Chr\u00e1ni\u010d p\u00e1sov"],
        ["CAR_REARVIEW_MIRRORS", "Sp\u00e4tn\u00e9 zrkadl\u00e1 do auta"],
        ["CAR_SEATS_ACCESSORIES", "Pr\u00edslu\u0161enstvo alebo in\u00e9"],
    ],
    p_brand: commonProp13,
    p_color: commonProp4,
};
