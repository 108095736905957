/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp23: Array<[string, string]> = [
    ["YEA", "\u00c1no"],
    ["NAY", "Nie"],
];
