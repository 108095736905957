// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const DATE_ABBR_MONTH_1 = "jan";
export const DATE_ABBR_MONTH_10 = "okt";
export const DATE_ABBR_MONTH_11 = "nov";
export const DATE_ABBR_MONTH_12 = "dec";
export const DATE_ABBR_MONTH_2 = "feb";
export const DATE_ABBR_MONTH_3 = "mar";
export const DATE_ABBR_MONTH_4 = "apr";
export const DATE_ABBR_MONTH_5 = "m\u00e1j";
export const DATE_ABBR_MONTH_6 = "j\u00fan";
export const DATE_ABBR_MONTH_7 = "j\u00fal";
export const DATE_ABBR_MONTH_8 = "aug";
export const DATE_ABBR_MONTH_9 = "sep";
export const MONTH_NAME_1 = "janu\u00e1r";
export const MONTH_NAME_10 = "okt\u00f3ber";
export const MONTH_NAME_11 = "november";
export const MONTH_NAME_12 = "december";
export const MONTH_NAME_2 = "febru\u00e1r";
export const MONTH_NAME_3 = "marec";
export const MONTH_NAME_4 = "apr\u00edl";
export const MONTH_NAME_5 = "m\u00e1j";
export const MONTH_NAME_6 = "j\u00fan";
export const MONTH_NAME_7 = "j\u00fal";
export const MONTH_NAME_8 = "august";
export const MONTH_NAME_9 = "september";
export const number_DAYS = ["%(number)s de\u0148", "%(number)s dni", "%(number)s dn\u00ed"];
export const number_DAYS_AGO = ["Pred %(number)s d\u0148om", "Pred %(number)s d\u0148ami", "Pred %(number)s d\u0148ami"];
export const number_HOURS = ["%(number)s hodina", "%(number)s hodiny", "%(number)s hod\u00edn"];
export const number_HOURS_AGO = ["Pred %(number)s hodinou", "Pred %(number)s hodinami", "Pred %(number)s hodinami"];
export const number_MINUTES = ["%(number)s min\u00fata", "%(number)s min\u00faty", "%(number)s min\u00fat"];
export const number_MINUTES_AGO = ["Pred %(number)s min\u00fatou", "Pred %(number)s min\u00fatami", "Pred %(number)s min\u00fatami"];
export const number_MONTHS = ["%(number)s mesiac", "%(number)s mesiace", "%(number)s mesiacov"];
export const number_MONTHS_AGO = ["Pred %(number)s mesiacom", "Pred %(number)s mesiacmi", "Pred %(number)s mesiacmi"];
export const number_SECONDS = ["%(number)s sekunda", "%(number)s sekundy", "%(number)s sek\u00fand"];
export const number_SECONDS_AGO = ["Pred %(number)s sekundou", "Pred %(number)s sekundami", "Pred %(number)s sekundami"];
export const number_WEEKS = ["%(number)s t\u00fd\u017ede\u0148", "%(number)s t\u00fd\u017edne", "%(number)s t\u00fd\u017ed\u0148ov"];
export const number_WEEKS_AGO = ["Pred %(number)s t\u00fd\u017ed\u0148om", "Pred %(number)s t\u00fd\u017ed\u0148ami", "Pred %(number)s t\u00fd\u017ed\u0148ami"];
export const number_YEARS = ["%(number)s rok", "%(number)s roky", "%(number)s rokov"];
export const number_YEARS_AGO = ["Pred %(number)s rokom", "Pred %(number)s rokmi", "Pred %(number)s rokmi"];
export const TIMEFORMAT_AT_time = ["o %(time)s", "o %(time)s", "o %(time)s"];
export const TIMEFORMAT_TODAY = "Dnes";
export const TIMEFORMAT_YESTERDAY = "V\u010dera";
export const YESTERDAY = "V\u010dera";
