// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const ASK_AN_EXPERT = "Poradne";
export const BAZAAR = "Baz\u00e1r";
export const BAZAAR_WATCHDOG_SETTINGS_TITLE = "Ulo\u017een\u00e9 h\u013eadania";
export const BLOGS_MENU_FEEDS = "Novinky";
export const BLOGS_WALL = "Stena";
export const BZ2_ADD_PRODUCT = "Pridaj inzer\u00e1t";
export const BZ2_FAQ = "Ako funguje baz\u00e1r?";
export const BZ2_WISHLIST = "Ulo\u017een\u00e9 inzer\u00e1ty";
export const CONTESTS = "S\u00fa\u0165a\u017ee";
export const ESHOP_MENU = "E-shop";
export const FORUMS_AND_GROUPS = "Skupiny a f\u00f3ra";
export const HEADING_SELLERS = "Moji ob\u013e\u00faben\u00ed";
export const LOG_IN = "Prihl\u00e1s sa";
export const MAIN_MENU_REVIEWS = "Recenzie";
export const MORE_WIKI_CATEGORIES = "\u010eal\u0161ie t\u00e9my";
export const MY_ENTRIES = "M\u00f4j baz\u00e1r";
export const MY_SHOPPING = "Moje n\u00e1kupy";
export const PB_MENU_FIND_MOM = "N\u00e1jdi mami\u010dku";
export const PB_MENU_MY_ACTIVITY = "Zapojila som sa";
export const REGISTER_HERE = "Zaregistruj sa tu!";
export const SERVER_FULL_NAME = "Modr\u00fd kon\u00edk";
export const SERVER_SLOGAN = "Str\u00e1nka pre mami\u010dky a bud\u00face mami\u010dky";
export const STROLLERS_SUBMENU_ARTICLES_AND_REVIEWS = "\u010cl\u00e1nky a recenzie";
export const STROLLERS_SUBMENU_COUNSELLING = "Ko\u010d\u00edkov\u00e1 porad\u0148a";
export const STROLLERS_SUBMENU_FORUM = "F\u00f3rum o ko\u010d\u00edkoch";
export const STROLLERS_SUBMENU_HOW_TO_PICK = "Ako vybra\u0165 ko\u010d\u00edk?";
export const STROLLER_STROLLEROPEDIA = "Ko\u010d\u00edkop\u00e9dia";
export const YOUTUBE_MK_TITLE = "YouTube Modr\u00fd kon\u00edk";
