/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp2 } from './commonProp2';

export const kindergarden_school : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["SCHOOL_BAG", "Aktovka, \u0161kolsk\u00e1 ta\u0161ka"],
        ["SCHOOL_PEN", "P\u00edsacie potreby, geometria"],
        ["SCHOOL_CASE", "Pera\u010dn\u00edk"],
        ["SCHOOL_CREATIVE", "V\u00fdtvarn\u00e9 potreby"],
        ["SCHOOL_MUSIC", "Hudobn\u00e9 n\u00e1stroje + pr\u00edslu\u0161enstvo"],
        ["SCHOOL_FOODBOX", "Box na desiatu, f\u013ea\u0161a"],
        ["SCHOOL_OTHER", "In\u00e9 pom\u00f4cky pre \u0161kol\u00e1ka"],
    ],
    p_sex: commonProp2,
    p_age_group: [
        ["NURSERIES", "\u0160k\u00f4lkari"],
        ["FIRST_AND_SECOND_CLASS", "1. a 2. ro\u010dn\u00edk Z\u0160"],
        ["THIRD_AND_FOURTH_CLASS", "3. a 4. ro\u010dn\u00edk Z\u0160"],
        ["SECOND_LEVEL_OF_SCHOOL", "2. stupe\u0148 Z\u0160"],
        ["OTHER_AGE", "Neuveden\u00fd"],
    ],
};
