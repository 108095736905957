/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp7 } from './commonProp7';
import { commonProp4 } from './commonProp4';

export const golf_clothing : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp7,
    p_brand: [
        d("adidas"),
        d("callaway"),
        d("Galvin Green"),
        d("greg norman"),
        d("inSPORTline"),
        d("mizuno"),
        d("nike"),
        d("nivo"),
        d("ping"),
        d("srixon"),
        d("sunice"),
        d("taylormade"),
        d("titleist"),
        d("tommy hilfiger"),
        d("under armour"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
