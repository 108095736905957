/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const face_painting : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["FACE_PAINTS", "Farby na tv\u00e1r"],
        ["FACE_CRAYONS", "Ceruzky na tv\u00e1r"],
        ["FACE_BRUSHES_SPONGES", "\u0160tetce a \u0161pongie"],
        ["FACE_PAINTING_GUIDES", "\u0160abl\u00f3ny a n\u00e1vody"],
        ["FACE_PAINTING_KIT", "Sady a sety"],
    ],
};
