/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp17: Array<[string, string]> = [
    ["SLOVAK", "slovensk\u00fd"],
    ["CZECH", "\u010desk\u00fd"],
    ["ENGLISH", "anglick\u00fd"],
    ["FRENCH", "franc\u00fazsky"],
    ["ITALIAN", "taliansky"],
    ["HUNGARIAN", "ma\u010farsk\u00fd"],
    ["GERMAN", "nemeck\u00fd"],
    ["POLISH", "po\u013esk\u00fd"],
    ["RUSSIAN", "rusk\u00fd"],
    ["SPANISH", "\u0161panielsky"],
    ["OTHER_LANGUAGE", "in\u00fd jazyk"],
    ["NO_LANGUAGE", "bez slov"],
];
