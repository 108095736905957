/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp12 } from './commonProp12';
import { commonProp4 } from './commonProp4';

export const fashion_accessories_accessories : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["WATCHES", "Hodinky"],
        ["WALLET", "Pe\u0148a\u017eenka"],
        ["BELT", "Opasok"],
        ["UMBRELLA", "D\u00e1\u017ednik"],
        ["CUFFLINKS", "Man\u017eetov\u00e9 gomb\u00edky"],
        ["BOW_TIE", "Mot\u00fdlik"],
        ["NECKTIE", "Kravata"],
        ["SUSPENDER", "Traky"],
        ["HANDKERCHIEF", "Vreckovka"],
        ["EYEGLASSES", "Okuliare- dioptrick\u00e9"],
        ["SUNGLASSES", "Okuliare- slne\u010dn\u00e9"],
        ["GLASSES_CASE", "P\u00fazdro na okuliare"],
        ["COVER_FOR_DOCUMENTS", "Obal na doklady"],
        ["KEYCHAIN_PENDANT", "K\u013e\u00fa\u010denka, pr\u00edvesok"],
        ["BADGE", "Odznak"],
        ["ADULTS_FANS", "Vej\u00e1r"],
        ["HEADBAND", "\u010celenka"],
        ["HAIR_CLIP", "Sponka"],
        ["RUBBER_BAND", "Gumi\u010dka do vlasov"],
        ["GRIPPER", "\u0160tipec do vlasov"],
        ["SETS", "Kompletn\u00e9 sady"],
    ],
    p_sex: commonProp5,
    p_brand: commonProp12,
    p_color: commonProp4,
};
