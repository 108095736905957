/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp2 } from './commonProp2';

export const nursery : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["NURSERY_DESK", "P\u00edsac\u00ed st\u00f4l"],
        ["NURSERY_CHAIR_SEAT_CUSHION", "Stoli\u010dka, podsed\u00e1k"],
        ["NURSERY_ARMCHAIR", "Kresielko"],
        ["NURSERY_WARDROBE", "Skri\u0148a, komoda"],
        ["NURSERY_SHELF", "Polica"],
        ["NURSERY_HANGERS", "Ve\u0161iak, ramienka"],
        ["NURSERY_OTHER_FURNITURE", "In\u00fd n\u00e1bytok"],
        ["NURSERY_CARPET", "Koberec"],
        ["NURSERY_CURTAIN_DRAPE", "Z\u00e1clona, z\u00e1ves"],
        ["NURSERY_LAMP", "Luster, lampa"],
        ["NURSERY_WALLPAPER_STICKER", "Tapeta, n\u00e1lepka na stenu"],
        ["NURSERY_PICTURE_POSTER_FRAME", "Obr\u00e1zok, plag\u00e1t, r\u00e1m"],
        ["NURSERY_BABY_METER", "Detsk\u00fd meter"],
        ["NURSERY_WALL_ORGANIZER", "Vreck\u00e1r"],
        ["NURSERY_SEAT_BAG_STOOL", "Sedac\u00ed vak, taburet"],
        ["NURSERY_CUSHION_CUSHION_COVER", "Vank\u00fa\u0161, oblie\u010dka na vank\u00fa\u0161"],
        ["NURSERY_STORAGE_BOX_ORGANIZER", "\u00dalo\u017en\u00fd box, organiz\u00e9r"],
        ["NURSERY_CLOCK_ALARM_CLOCK", "Hodiny, bud\u00edk"],
        ["NURSERY_TRASH_CAN", "Odpadkov\u00fd k\u00f4\u0161"],
        ["NURSERY_MONEYBOX", "Pokladni\u010dka"],
        ["NURSERY_DECOR", "Dekor\u00e1cie"],
        ["NURSERY_OTHER", "In\u00e9"],
    ],
    p_sex: commonProp2,
};
