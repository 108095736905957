/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp6 } from './commonProp6';
import { commonProp4 } from './commonProp4';

export const socks_tights : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["SOCKS", "Pono\u017eky"],
        ["KNEE_SOCKS", "Podkolienky"],
        ["TIGHTS", "Pan\u010du\u0161ky"],
        ["LEG_WARMERS", "\u0160tucne a n\u00e1vleky"],
        ["SOCKS_TIGHTS_MIX", "Mix"],
    ],
    p_size: [
        d("17"),
        d("18"),
        d("19"),
        d("20"),
        d("21"),
        d("22"),
        d("23"),
        d("24"),
        d("25"),
        d("26"),
        d("27"),
        d("28"),
        d("29"),
        d("30"),
        d("31"),
        d("32"),
        d("33"),
        d("34"),
        d("35"),
        d("36"),
        d("37"),
        d("38"),
        d("39"),
        d("40"),
        d("<50"),
        d("50"),
        d("56"),
        d("62"),
        d("68"),
        d("74"),
        d("80"),
        d("86"),
        d("92"),
        d("98"),
        d("104"),
        d("110"),
        d("116"),
        d("122"),
        d("128"),
        d("134"),
        d("140"),
        d("146"),
        d("152"),
        d("158"),
        d("164"),
        d("170"),
        d("176"),
        d("182"),
        d("188"),
        d("XS"),
        d("S"),
        d("M"),
        d("L"),
        d("XL"),
        d("XXL"),
        d("XXXL"),
        d("4XL"),
        d("5XL"),
        d("42"),
        d("44"),
        d("46"),
        d("48"),
        ["ONE_SIZE_FIT_ALL", "Univerz\u00e1lna ve\u013ekos\u0165"],
    ],
    p_sex: commonProp5,
    p_brand: commonProp6,
    p_color: commonProp4,
};
