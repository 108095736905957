/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const safety : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["BABY_BREATHING_MONITORS", "Monitory dychu"],
        ["BABY_MONITORS", "Elektronick\u00e9 pest\u00fanky / Baby monitory"],
        ["VIDEO_BABY_MONITORS", "Video pest\u00fanky"],
        ["BABY_SCALES", "Detsk\u00e9 v\u00e1hy"],
        ["AIR_HUMIDIFIER_AND_PURIFIER", "Zvlh\u010dova\u010de a \u010disti\u010dky vzduchu"],
        ["INHALERS", "Inhal\u00e1tory"],
        ["NIGHT_LIGHTS_LAMPS", "No\u010dn\u00e9 svetielko, lampi\u010dka"],
        ["STAIR_BARRIERS", "Z\u00e1brany na schody, do dver\u00ed"],
        ["CORNER_PROTECTORS", "Bezpe\u010dnostn\u00e9 poistky, chr\u00e1ni\u010de rohov"],
        ["SAFETY_BRACES_LEASH", "Bezpe\u010dnostn\u00e9 popruhy, vod\u00edtko"],
        ["REFLECTIVE_VESTS_STICKERS_PENDANTS", "Reflexn\u00e9 vesty, n\u00e1lepky, pr\u00edvesky"],
        ["OTHER_EQUIPMENTS", "In\u00e9"],
    ],
    p_brand: [
        d("Angelcare"),
        d("AUDIOLINE"),
        d("Baby Control Digital"),
        d("BabyDan"),
        d("Babymoov"),
        d("BabyOno"),
        d("Babysense"),
        d("Belkin"),
        d("Beurer"),
        d("Boneco"),
        d("Brendon"),
        d("Brondi"),
        d("B\u00c9ABA"),
        d("Canpol babies"),
        d("CAPiDi"),
        d("Caretero"),
        d("Chicco"),
        d("Chipolino"),
        d("Clippasafe"),
        d("Concept"),
        d("dreambaby"),
        d("Dux"),
        d("ETA"),
        d("Gorenje"),
        d("Graco"),
        d("Grundig"),
        d("H+H"),
        d("Hartig+Hellig"),
        d("Hauck"),
        d("IKEA"),
        d("Inglesina"),
        d("Jablotron"),
        d("Jan\u00e9"),
        d("Laica"),
        d("Lindam"),
        d("Lorelli"),
        d("Luvion"),
        d("Mebby"),
        d("Miniland Baby"),
        d("Motorola"),
        d("Nanny"),
        d("Nuk"),
        d("Nuvita"),
        d("OKbaby"),
        d("Orava"),
        d("Oricom"),
        d("Petit"),
        d("Philips"),
        d("Philips Avent"),
        d("Reer"),
        d("Respisense"),
        d("Safety 1st"),
        d("Samsung"),
        d("Sencor"),
        d("Snuza"),
        d("Switel"),
        d("Tefal"),
        d("The first years"),
        d("Tipex"),
        d("Tommee Tippee"),
        d("Tomy"),
        d("Topcom"),
        d("Vtech"),
        d("Whicry"),
        d("Whiteberry"),
        d("Zelmer"),
        d("Zopa"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
};
