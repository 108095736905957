// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const STROLLER_FEATURE_ADAPTERS = "Adapt\u00e9ry na autoseda\u010dku";
export const STROLLER_FEATURE_ADAPTERS_CARRYCOT = "Adapt\u00e9ry na hlbok\u00fa vani\u010dku";
export const STROLLER_FEATURE_ADJUSTABLE_HANDLE = "Polohovate\u013en\u00e1 r\u00fa\u010dka";
export const STROLLER_FEATURE_ADJUSTABLE_SUSPENSION = "Nastavite\u013en\u00e9 odpru\u017eenie";
export const STROLLER_FEATURE_ALL_WHEELS_ARE_DOUBLE = "V\u0161etky koles\u00e1 s\u00fa zdvojen\u00e9";
export const STROLLER_FEATURE_AUTOLOCK = "Auto-lock pri skladan\u00ed - kon\u0161trukcia sa automaticky uzamkne proti rozlo\u017eeniu";
export const STROLLER_FEATURE_AVAILABILITY = "Dostupnos\u0165";
export const STROLLER_FEATURE_BABY_BLANKET = "Detsk\u00e1 deka";
export const STROLLER_FEATURE_BACK_SUPPORT_CAN_BE_EXTENDED = "Pred\u013a\u017eite\u013en\u00e1 chrbtov\u00e1 opierka";
export const STROLLER_FEATURE_BACK_SUPPORT_CAN_BE_IN_LYING_POSITION = "Chrbtov\u00e1 opierka ide do \u013eahu";
export const STROLLER_FEATURE_BACK_SUPPORT_CAN_BE_POSITIONED = "Polohovate\u013en\u00e1 chrbtov\u00e1 opierka";
export const STROLLER_FEATURE_BACK_SUPPORT_LENGTH_IN_CM = "D\u013a\u017eka chrbtovej opierky v cm";
export const STROLLER_FEATURE_BACK_SUPPORT_POSITIONING_TYPE = "Typ polohovania chrbtovej opierky";
export const STROLLER_FEATURE_BACK_SUPPORT_POSITIONS = "Po\u010det pol\u00f4h chrbtovej opierky";
export const STROLLER_FEATURE_BASE_COLOR = "Farba kon\u0161trukcie";
export const STROLLER_FEATURE_BASE_FOR_THE_CAR_SEAT = "Z\u00e1klad\u0148a k autoseda\u010dke";
export const STROLLER_FEATURE_BIG_SHOPPING_BASKET = "Ve\u013ek\u00fd n\u00e1kupn\u00fd ko\u0161\u00edk";
export const STROLLER_FEATURE_BOARD = "Skateboard";
export const STROLLER_FEATURE_BUCKET_SEAT = "Kor\u00fdtkov\u00e9 sedenie";
export const STROLLER_FEATURE_BUCKET_SECOND_SEAT = "Kor\u00fdtkov\u00e9 sedenie druh\u00e9ho sedadla";
export const STROLLER_FEATURE_BUGGY_LOCK = "Z\u00e1mok na ko\u010d\u00edk";
export const STROLLER_FEATURE_BUMPER_BAR = "Bezpe\u010dnostn\u00e9 madlo";
export const STROLLER_FEATURE_BUMPER_BAR_CAN_BE_BOUGHT_SEPARATELY = "Madlo je mo\u017en\u00e9 ku ko\u010d\u00edku dok\u00fapi\u0165 osobitne";
export const STROLLER_FEATURE_BUMPER_BAR_CAN_BE_POSITIONED = "Detsk\u00e9 madlo sa d\u00e1 polohova\u0165";
export const STROLLER_FEATURE_BUMPER_BAR_CAN_BE_REMOVED = "Detsk\u00e9 madlo sa d\u00e1 od\u0148a\u0165";
export const STROLLER_FEATURE_BUMPER_BAR_EQUIPPED_WITH_A_REMOVABLE_COVER = "Madlo m\u00e1 sn\u00edmate\u013en\u00fd po\u0165ah";
export const STROLLER_FEATURE_BUMPER_BAR_TYPE = "Typ detsk\u00e9ho madla";
export const STROLLER_FEATURE_CAN_BE_CONVERTED_TO_A_DOUBLE = "Mo\u017enos\u0165 pripoji\u0165 s\u00farodeneck\u00e9 sedadlo";
export const STROLLER_FEATURE_CAN_BE_CONVERTED_TO_A_SINGLE = "Mo\u017enos\u0165 spravi\u0165 z dvojko\u010d\u00edka jednoko\u010d\u00edk";
export const STROLLER_FEATURE_CAN_BE_PULLED_BEHIND = "Po zlo\u017een\u00ed je mo\u017en\u00e9 \u0165aha\u0165 ko\u010d\u00edk za sebou ako bato\u017einu";
export const STROLLER_FEATURE_CARRYCOT = "Hlbok\u00e1 vani\u010dka";
export const STROLLER_FEATURE_CARRYCOT_CAN_BE_ATTACHED = "Mo\u017enos\u0165 na ko\u010d\u00edk nasadi\u0165 hlbok\u00fa vani\u010dku";
export const STROLLER_FEATURE_CARRYCOT_DIMS_IN_CM = "Vn\u00fatorn\u00fd rozmer hlbokej vani\u010dky v cm";
export const STROLLER_FEATURE_CARRYCOT_HAS_HANDLE = "Hlbok\u00e1 vani\u010dka m\u00e1 \u00fachyt na pren\u00e1\u0161anie";
export const STROLLER_FEATURE_CARRYCOT_HAS_VENTILATION = "Vani\u010dka m\u00e1 otvory na vetranie v z\u00e1kladni";
export const STROLLER_FEATURE_CARRYCOT_USABLE_IN_CAR = "Autovani\u010dka - mo\u017enos\u0165 pou\u017eitia hlbokej korby namiesto autoseda\u010dky kat. 0+ ";
export const STROLLER_FEATURE_CARRYCOT_WITH_CRADLE = "Vani\u010dka m\u00e1 funkciu kol\u00edsky";
export const STROLLER_FEATURE_CARRYCOT_WITH_PANORAMIC_WINDOW_ON_SIDE = "Vani\u010dka m\u00e1 panoramatick\u00e9 okienko (na boku)";
export const STROLLER_FEATURE_CARRYCOT_WITH_VENTILATION_WINDOW_ON_ROOF = "Vani\u010dka m\u00e1 ventila\u010dn\u00e9 okienko (v strie\u0161ke)";
export const STROLLER_FEATURE_CAR_SEAT = "Autoseda\u010dka (vaj\u00ed\u010dko)";
export const STROLLER_FEATURE_CAR_SEAT_CAN_BE_ATTACHED = "Mo\u017enos\u0165 pripojenia autoseda\u010dky";
export const STROLLER_FEATURE_CAR_SEAT_COLOR = "Farba autoseda\u010dky (vaj\u00ed\u010dka)";
export const STROLLER_FEATURE_CAR_SEAT_COMPATIBILITY_MOST_BRANDS = "Mo\u017enos\u0165 pripojenia autoseda\u010diek zna\u010dky Cybex, Maxi-Cosi, GB, Recaro, BeSafe, Kiddy, Joie, Avionaut, Nuna Pipa, Safety 1st (rovnak\u00fd adapt\u00e9r)";
export const STROLLER_FEATURE_CAR_SEAT_COMPATIBILITY_ROEMER = "Mo\u017enos\u0165 pripojenia autoseda\u010diek zna\u010dky R\u00f6mer";
export const STROLLER_FEATURE_CAR_SEAT_HAS_TO_BE_BOUGHT = "Autoseda\u010dku je potrebn\u00e9 dok\u00fapi\u0165 osobitne";
export const STROLLER_FEATURE_CENTRAL_BRAKE_PRESENT = "Pr\u00edtomnos\u0165 centr\u00e1lnej brzdy";
export const STROLLER_FEATURE_CENTRAL_BRAKE_SYSTEM = "Typ centr\u00e1lnej brzdy";
export const STROLLER_FEATURE_CHECKED_BAGGAGE = "Ko\u010d\u00edk mo\u017eno zlo\u017ei\u0165 na ve\u013ekos\u0165 pr\u00edru\u010dnej bato\u017einy do lietadla";
export const STROLLER_FEATURE_CLEANING_THE_STROLLER_FABRICS = "\u010cistenie po\u0165ahu";
export const STROLLER_FEATURE_CLOSABLE_PEEK_A_BOO_WINDOW = "N\u00e1h\u013eadov\u00e9 okienko sa d\u00e1 uzavrie\u0165";
export const STROLLER_FEATURE_COCOON = "Vlo\u017en\u00e1 ta\u0161ka";
export const STROLLER_FEATURE_COLOR = "Farba";
export const STROLLER_FEATURE_CONSTRUCTION = "Kon\u0161trukcia";
export const STROLLER_FEATURE_CONSTRUCTION_TYPE = "Mono/Duo/Twin";
export const STROLLER_FEATURE_CUPHOLDER = "Dr\u017eiak n\u00e1pojov";
export const STROLLER_FEATURE_CUPHOLDER_POSSIBILITY = "Mo\u017enos\u0165 pripoji\u0165 dr\u017eiak na n\u00e1poje";
export const STROLLER_FEATURE_DISCONTINUED_YEAR = "Rok ukon\u010denia v\u00fdroby";
export const STROLLER_FEATURE_DUO_CARRYCOT = "Vani\u010dka (s\u00farodeneck\u00e1)";
export const STROLLER_FEATURE_DUO_SEAT = "Seda\u010dka (s\u00farodeneck\u00e1)";
export const STROLLER_FEATURE_EDITION = "Limitovan\u00e1 ed\u00edcia";
export const STROLLER_FEATURE_EXTENDABLE_HOOD = "Pred\u013a\u017eite\u013en\u00e1 strie\u0161ka";
export const STROLLER_FEATURE_EXTENSION_SET = "Roz\u0161iruj\u00faci set (duo)";
export const STROLLER_FEATURE_FOLDABLE_CARRYCOT = "Vani\u010dka sa d\u00e1 posklada\u0165";
export const STROLLER_FEATURE_FOLDABLE_HANDRAIL = "Prekl\u00e1pate\u013en\u00e1 r\u00fa\u010dka";
export const STROLLER_FEATURE_FOLDED_DIMENSIONS_IN_CM = "Rozmery v zlo\u017eenom stave (v\u00d7\u0161\u00d7d v cm)";
export const STROLLER_FEATURE_FOLDING_SYSTEM = "Typ skladania";
export const STROLLER_FEATURE_FOLDS_BY_ITSELF = "S\u00e1m sa posklad\u00e1/rozlo\u017e\u00ed";
export const STROLLER_FEATURE_FOLDS_WITH_BOTH_SEATS_ATTACHED = "Skladanie je mo\u017en\u00e9 s oboma nasaden\u00fdmi sedadlami";
export const STROLLER_FEATURE_FOLDS_WITH_SEAT_OUTWARDS = "Sklad\u00e1 sa sedadlom smerom von (mo\u017en\u00e9 u\u0161pinenie)";
export const STROLLER_FEATURE_FOLDS_WITH_THE_SEAT_ATTACHED = "Skladanie je mo\u017en\u00e9 aj so sedadlom";
export const STROLLER_FEATURE_FOLDS_WITH_THE_SEAT_IN_REVERSE = "Skladanie je mo\u017en\u00e9 aj so sedadlom nasaden\u00fdm v protismere";
export const STROLLER_FEATURE_FOLLOW_THE_SUN_HOOD = "Strie\u0161ka pou\u017eite\u013en\u00e1 ako slne\u010dn\u00fd \u0161t\u00edt (strie\u0161ka nasleduje slnko - d\u00e1 sa prekl\u00e1pa\u0165 dopredu)";
export const STROLLER_FEATURE_FOOTCOVER_COLOR = "Farba n\u00e1no\u017en\u00edka";
export const STROLLER_FEATURE_FOOTMUFF = "Fusak";
export const STROLLER_FEATURE_FOOTSTOOL = "Podno\u017eka";
export const STROLLER_FEATURE_FRONT_WHEELS_DIAMETER_IN_CM = "Priemer predn\u00fdch kolies (v cm)";
export const STROLLER_FEATURE_FRONT_WHEEL_TYPE = "Typ kolies vpredu";
export const STROLLER_FEATURE_FULLY_FLEDGED_SECOND_SEAT = "Druh\u00e9 sedadlo je plnohodnotn\u00e9 (rovnak\u00e9 ako prv\u00e9)";
export const STROLLER_FEATURE_HAND_BRAKE = "Ru\u010dn\u00e1 brzda";
export const STROLLER_FEATURE_HANDLE_COLOR = "Farba rukov\u00e4te";
export const STROLLER_FEATURE_HANDLE_HEIGHT_IN_CM = "V\u00fd\u0161ka r\u00fa\u010dky v cm";
export const STROLLER_FEATURE_HANDLE_MATERIAL = "Materi\u00e1l r\u00fa\u010dky/r\u00fa\u010dok";
export const STROLLER_FEATURE_HARNESS_PADDING = "Polstrovanie bezpe\u010dnostn\u00fdch p\u00e1sov";
export const STROLLER_FEATURE_HARNESS_TYPE = "Typ bezpe\u010dnostn\u00fdch p\u00e1sov pre die\u0165a";
export const STROLLER_FEATURE_HAS_CARRY_HANDLE = "Pr\u00edtomnos\u0165 \u00fachytu/popruhu na pren\u00e1\u0161anie";
export const STROLLER_FEATURE_HEIGHT_ADAPTER = "V\u00fd\u0161kov\u00fd adapt\u00e9r";
export const STROLLER_FEATURE_HEIGHT_ADJUSTABLE_HARNESS = "Bezpe\u010dnostn\u00e9 p\u00e1sy sa daj\u00fa v\u00fd\u0161kovo nastavi\u0165";
export const STROLLER_FEATURE_HEIGHT_ADJUSTABLE_HOOD = "Mo\u017enos\u0165 strie\u0161ku v\u00fd\u0161kovo nastavi\u0165";
export const STROLLER_FEATURE_HEIGHT_ADJUSTABLE_SEAT = "V\u00fd\u0161kovo nastavite\u013en\u00e9 sedadlo";
export const STROLLER_FEATURE_HIGHER_POSITIONED_CARRYCOT = "Vysoko umiestnen\u00e1 vani\u010dka";
export const STROLLER_FEATURE_HIGHER_SEAT_POSITION = "Vy\u0161\u0161ie umiestnen\u00e9 \u0161portov\u00e9 sedadadlo";
export const STROLLER_FEATURE_HOOD_EQUIPPED_WITH_A_POCKET = "Vrecko na drobnosti v strie\u0161ke";
export const STROLLER_FEATURE_HOOD_EQUIPPED_WITH_UV_PROTECTION = "UV faktor v strie\u0161ke";
export const STROLLER_FEATURE_HOOD_EQUIPPED_WITH_VENTILATION_PANEL = "Vetrac\u00ed otvor / sie\u0165ka v strie\u0161ke";
export const STROLLER_FEATURE_HOOD_FEATURES_A_SUN_VISOR = "Pr\u00edtomnos\u0165 vyklopite\u013en\u00e9ho \u0161iltu na strie\u0161ke";
export const STROLLER_FEATURE_INCLINABLE_CARRYCOT = "Polohovanie v hlbokej vani\u010dke";
export const STROLLER_FEATURE_LED_LIGHT = "LED svetlo";
export const STROLLER_FEATURE_LED_LIGHTING = "LED osvetlenie";
export const STROLLER_FEATURE_LEG_REST_CAN_BE_EXTENDED = "Pred\u013a\u017eite\u013en\u00e1 opierka n\u00f4h";
export const STROLLER_FEATURE_LEG_REST_CAN_BE_POSITIONED = "Polohovate\u013en\u00e1 opierka n\u00f4h";
export const STROLLER_FEATURE_LEG_REST_POSITIONS = "Po\u010det pol\u00f4h opierky n\u00f4h";
export const STROLLER_FEATURE_LINER = "Vlo\u017eka";
export const STROLLER_FEATURE_LINER_COLOR = "Farba vlo\u017eky";
export const STROLLER_FEATURE_MANUAL_BRAKE_PRESENT = "Pr\u00edtomnos\u0165 ru\u010dnej (spoma\u013eovacej) brzdy";
export const STROLLER_FEATURE_MATTRESS = "Matrac";
export const STROLLER_FEATURE_MAXIMUM_LOAD_PER_1ST_CHILD_IN_KG = "Maxim\u00e1lne za\u0165a\u017eenie sedadla (prv\u00e9 die\u0165a - v kg)";
export const STROLLER_FEATURE_MAXIMUM_LOAD_PER_2ND_CHILD_IN_KG = "Maxim\u00e1lne za\u0165a\u017eenie sedadla (druh\u00e9 die\u0165a - v kg)";
export const STROLLER_FEATURE_MAXIMUM_LOAD_PER_CHILD_IN_KG = "Maxim\u00e1lne za\u0165a\u017eenie sedadla (jedno die\u0165a - v kg)";
export const STROLLER_FEATURE_MEMORY_BUTTON = "M\u00e1 memory button (pam\u00e4\u0165ov\u00e9 tla\u010didlo), sta\u010d\u00ed pri ot\u00e1\u010dan\u00ed/odn\u00edman\u00ed stla\u010di\u0165 (nie dr\u017ea\u0165)";
export const STROLLER_FEATURE_MOSQUITO_NET = "Sie\u0165 proti hmyzu";
export const STROLLER_FEATURE_MOTORIZED = "Motorizovan\u00fd";
export const STROLLER_FEATURE_NUMBER_OF_DOUBLE_WHEELS = "Po\u010det zdvojen\u00fdch kolies";
export const STROLLER_FEATURE_NUMBER_OF_KIDS = "Po\u010det det\u00ed";
export const STROLLER_FEATURE_NUMBER_OF_WHEELS = "Po\u010det kolies";
export const STROLLER_FEATURE_ONE_HAND_FOLDING = "Mo\u017enos\u0165 zlo\u017ei\u0165 jednou rukou";
export const STROLLER_FEATURE_ONE_HAND_POSITIONING = "Polohovanie jednou rukou";
export const STROLLER_FEATURE_ORGANIZER = "Organiz\u00e9r";
export const STROLLER_FEATURE_ORIGIN_COUNTRIES = "P\u00f4vod zna\u010dky";
export const STROLLER_FEATURE_PARASOL = "Slne\u010dn\u00edk";
export const STROLLER_FEATURE_PARENT_TRAY_ORGANIZER = "Pult/organiz\u00e9r pre rodi\u010da pri r\u00fa\u010dke";
export const STROLLER_FEATURE_PEEK_A_BOO_WINDOW_IN_THE_HOOD = "Pr\u00edtomnos\u0165 n\u00e1h\u013eadov\u00e9ho okienka v strie\u0161ke";
export const STROLLER_FEATURE_PEEK_A_BOO_WINDOW_WITH_SILENT_CLOSURE = "Magnetick\u00e9 / nehlu\u010dn\u00e9 otv\u00e1ranie n\u00e1h\u013eadov\u00e9ho okienka (bez such\u00fdch zipsov \u010di zipsu)";
export const STROLLER_FEATURE_PHONE_HOLDER = "Dr\u017eiak na telef\u00f3n";
export const STROLLER_FEATURE_PLATFORM_MATERIAL = "Materi\u00e1l podvozku";
export const STROLLER_FEATURE_POSSIBILITY_OF_TERRAIN_WHEELS = "Mo\u017enos\u0165 dok\u00fapi\u0165 ter\u00e9nne koles\u00e1 s pneumatikami";
export const STROLLER_FEATURE_PRICE = "Cena";
export const STROLLER_FEATURE_RAINCOAT = "Pl\u00e1\u0161tenka";
export const STROLLER_FEATURE_REAR_WHEELS_DIAMETER_IN_CM = "Priemer kolies vzadu (v cm)";
export const STROLLER_FEATURE_REAR_WHEEL_TYPE = "Typ kolies vzadu";
export const STROLLER_FEATURE_RECOMMENDED_AGE = "Doporu\u010den\u00fd vek die\u0165a\u0165a (v mesiacoch)";
export const STROLLER_FEATURE_REMOVABLE_HOOD = "Mo\u017enos\u0165 strie\u0161ku od\u0148a\u0165";
export const STROLLER_FEATURE_RETROREFLECTOR = "Reflexn\u00e9 prvky";
export const STROLLER_FEATURE_ROOF = "Strie\u0161ka";
export const STROLLER_FEATURE_ROOF_PRESENT = "Pr\u00edtomnos\u0165 strie\u0161ky";
export const STROLLER_FEATURE_ROTATING_FIXATION_WHEELS = "Ot\u00e1\u010danie predn\u00fdch kolies";
export const STROLLER_FEATURE_ROTATING_SEAT = "Oto\u010dn\u00e9 sedadlo";
export const STROLLER_FEATURE_ROTATING_SECOND_SEAT = "Druh\u00e9 sedadlo je oto\u010dn\u00e9";
export const STROLLER_FEATURE_SEAT_DIMS_IN_CM = "\u0160\u00edrka x h\u013abka sedacej plochy v cm";
export const STROLLER_FEATURE_SEAT_FABRICS_CAN_BE_TAKEN_OFF = "Po\u0165ah sa d\u00e1 zvliec\u0165";
export const STROLLER_FEATURE_SEAT_SURFACE_TOTAL_LENGTH_IN_CM = "Celkov\u00e1 d\u013a\u017eka lo\u017enej plochy \u0161portov\u00e9ho sedadla v cm";
export const STROLLER_FEATURE_SEAT_UNIT_STROLLER_BODY_EQUIPPED_WITH_VENTILATION_SYSTEM = "Vetranie na kon\u0161trukcii / sedadle (napr. na sedadle, \u010di pri sklopenej polohe chrbtovej opierky)";
export const STROLLER_FEATURE_SECOND_SEAT_BACK_SUPPORT_CAN_BE_POSITIONED = "Polohovate\u013en\u00e1 chrbtov\u00e1 opierka - druh\u00e9 sedadlo";
export const STROLLER_FEATURE_SECOND_SEAT_BACK_SUPPORT_LENGTH_IN_CM = "D\u013a\u017eka chrbtovej opierky - druh\u00e9 sedadlo (v cm)";
export const STROLLER_FEATURE_SECOND_SEAT_BACK_SUPPORT_POSITIONING_TYPE = "Typ polohovania chrbtovej opierky - druh\u00e9 sedadlo";
export const STROLLER_FEATURE_SECOND_SEAT_BACK_SUPPORT_POSITIONS = "Po\u010det pol\u00f4h chrbtovej opierky - druh\u00e9 sedadlo";
export const STROLLER_FEATURE_SECOND_SEAT_BUMPER_BAR = "Pr\u00edtomnos\u0165 detsk\u00e9ho madla - druh\u00e9 sedadlo";
export const STROLLER_FEATURE_SECOND_SEAT_DIMS_IN_CM = "\u0160\u00edrka x h\u013abka sedacej plochy druh\u00e9ho sedadla v cm";
export const STROLLER_FEATURE_SECOND_SEAT_LEG_REST = "Opierka n\u00f4h u druh\u00e9ho sedadla";
export const STROLLER_FEATURE_SECOND_SEAT_ONE_HAND_POSITIONING = "Polohovanie jednou rukou - druh\u00e9 sedadlo";
export const STROLLER_FEATURE_SECOND_SEAT_RECOMMENDED_AGE = "Doporu\u010den\u00fd vek die\u0165a\u0165a - druh\u00e9 sedadlo (v mesiacoch)";
export const STROLLER_FEATURE_SECOND_SEAT_ROOF_PRESENT = "Pr\u00edtomnos\u0165 strie\u0161ky - druh\u00e9 sedadlo";
export const STROLLER_FEATURE_SECOND_SEAT_SURFACE_TOTAL_LENGTH_IN_CM = "Celkov\u00e1 d\u013a\u017eka lo\u017enej plochy \u0161portov\u00e9ho sedadla v cm - druh\u00e9 sedadlo";
export const STROLLER_FEATURE_SETS = "Set";
export const STROLLER_FEATURE_SHOPPING_BASKET = "N\u00e1kupn\u00fd ko\u0161\u00edk";
export const STROLLER_FEATURE_SHOPPING_BASKET_CAPACITY_IN_KG = "Nosnos\u0165 n\u00e1kupn\u00e9ho ko\u0161\u00edka v kg";
export const STROLLER_FEATURE_SHOPPING_BASKET_CLOSABLE = "Uzatv\u00e1rate\u013en\u00fd n\u00e1kupn\u00fd ko\u0161\u00edk";
export const STROLLER_FEATURE_SIBLINGS_TYPE = "Typ s\u00farodeneck\u00e9ho";
export const STROLLER_FEATURE_SNACK_TRAY = "Podnos na desiatu";
export const STROLLER_FEATURE_SPORTWHEELS = "\u0160portov\u00e9 kolieska (nafukovacie)";
export const STROLLER_FEATURE_STANDING_FOLD = "Po zlo\u017een\u00ed samostatne stoj\u00ed";
export const STROLLER_FEATURE_STANDUP_SUPPORT = "Stojan";
export const STROLLER_FEATURE_STEERING_WHEEL = "Volant";
export const STROLLER_FEATURE_STROLLER_BABY_BAG = "Sanit\u00e1rna ta\u0161ka";
export const STROLLER_FEATURE_STROLLER_BAG = "Ta\u0161ka/batoh";
export const STROLLER_FEATURE_STROLLER_BODY_EQUIPPED_WITH_A_POCKET = "Vrecko na drobnosti na kon\u0161trukcii";
export const STROLLER_FEATURE_STROLLER_FOOT_BAG = "N\u00e1no\u017en\u00edk";
export const STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT = "V\u00fd\u0161ka rodi\u010dov";
export const STROLLER_FEATURE_STROLLER_SEAT = "\u0160portov\u00e9 sedadlo";
export const STROLLER_FEATURE_SUN_CANOPY_COLOR = "Farba strie\u0161ky";
export const STROLLER_FEATURE_SUN_VISOR = "Svetl\u00edk";
export const STROLLER_FEATURE_SUPPLIED_CAPE = "Dod\u00e1van\u00fd s pl\u00e1\u0161tenkou";
export const STROLLER_FEATURE_SUPPLIED_CHANGING_BAG = "Dod\u00e1van\u00fd s preba\u013eovacou ta\u0161kou";
export const STROLLER_FEATURE_SUPPLIED_FOOTMUFF = "Dod\u00e1van\u00fd s n\u00e1no\u017en\u00edkom";
export const STROLLER_FEATURE_SUPPLIED_MOSQUITO_NET = "Dod\u00e1van\u00fd s moskyti\u00e9rou";
export const STROLLER_FEATURE_SUPPLIED_PAD = "Dod\u00e1van\u00fd s podlo\u017ekou ku ko\u010d\u00edku";
export const STROLLER_FEATURE_SUPPLIED_TRANSPORT_BAG = "Dod\u00e1van\u00fd s ta\u0161kou na prenos";
export const STROLLER_FEATURE_SUSPENDED_WHEELS = "Odpru\u017een\u00e9 koles\u00e1";
export const STROLLER_FEATURE_SUSPENSION = "Odpru\u017eenie";
export const STROLLER_FEATURE_SUSPENSION_TYPE = "Typ odpru\u017eenia";
export const STROLLER_FEATURE_SWING_AWAY_BUMPER_BAR = "Detsk\u00e9 madlo sa d\u00e1 ot\u00e1\u010dan\u00edm odklopi\u0165";
export const STROLLER_FEATURE_SWINGING = "Mo\u017enos\u0165 hojdania";
export const STROLLER_FEATURE_TOY = "Hra\u010dka";
export const STROLLER_FEATURE_TWIN_CARRYCOT = "Vani\u010dka (dvoj\u010da)";
export const STROLLER_FEATURE_TWO_CAR_SEATS_CAN_BE_ATTACHED = "Mo\u017enos\u0165 pripojenia dvoch autoseda\u010diek";
export const STROLLER_FEATURE_TWO_WHEEL_MODE = "Mo\u017enos\u0165 prerobi\u0165 na dvojkolesov\u00fd (sch\u00e1dzanie schodmi, \u0165ahanie po piesku)";
export const STROLLER_FEATURE_TYPE = "Typ";
export const STROLLER_FEATURE_TYPE_2IN1_SEAT = "Typ sedadla 2v1 (hlbok\u00e1 vani\u010dka sa rozlo\u017e\u00ed na \u0161portov\u00e9 sedadlo)";
export const STROLLER_FEATURE_TYPE_2KOMBI = "Typ dvojkombin\u00e1cie";
export const STROLLER_FEATURE_TYPE_OF_HANDLE_ADJUSTMENT = "Typ polohovania r\u00fa\u010dky";
export const STROLLER_FEATURE_UNFOLDED_DIMENSIONS_IN_CM = "Rozmery v rozlo\u017eenom stave (v\u00d7\u0161\u00d7d cm)";
export const STROLLER_FEATURE_VERTICAL_SEAT  = "Chrbtov\u00e1 opierka ide polohova\u0165 do kolm\u00e9ho sedu (90\u00b0)";
export const STROLLER_FEATURE_WATERPROOF_HOOD = "Nepremokav\u00fd materi\u00e1l strie\u0161ky";
export const STROLLER_FEATURE_WEIGHT_IN_KG = "Hmotnos\u0165 (v kg)";
export const STROLLER_FEATURE_WHEEL_COLOR = "Farba koliesok";
export const STROLLER_FEATURE_WHEEL_DESCRIPTION_WEB = "Koles\u00e1 - doplnenie";
export const STROLLER_FEATURE_WHEEL_MATERIAL = "Materi\u00e1l kolies";
export const STROLLER_FEATURE_WHEELS_CAN_BE_REMOVED = "Koles\u00e1 sa daj\u00fa od\u0148a\u0165";
export const STROLLER_FEATURE_WHEEL_SIZE = "Ve\u013ekos\u0165 kolies";
export const STROLLER_FEATURE_WRIST_SAFETY_STRAP = "Popruh na ruku na zastavenie ko\u010d\u00edka";
export const STROLLER_FEATURE_YOUNG_REDUCTION = "Redukcia pre mal\u00e9 die\u0165a";
