/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const atvs : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["UTILITY_ATVS", "Pracovn\u00e9"],
        ["SPORT_ATVS", "\u0160portov\u00e9"],
        ["BUGGY_ATVS", "Buggy"],
        ["KIDS_ATVS", "Detsk\u00e9"],
    ],
    p_brand: [
        d("CF Moto Gladiator"),
        d("SMC"),
        d("Access Motor"),
        d("Linhai"),
        d("TGB"),
        d("Arctic Cat"),
        d("Polaris"),
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
};
