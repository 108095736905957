/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp7 } from './commonProp7';
import { commonProp5 } from './commonProp5';
import { commonProp6 } from './commonProp6';
import { commonProp4 } from './commonProp4';

export const jackets_coats : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["JACKETS", "Bundy, vetrovky"],
        ["COATS", "Kab\u00e1ty, sak\u00e1"],
        ["GILET", "Zateplen\u00e9 vesty"],
    ],
    p_season: [
        ["SEASON_SPRING_AUTUMN", "prechodn\u00e9 / jarn\u00e9, jesenn\u00e9"],
        ["SEASON_WINTER", "ve\u013emi tepl\u00e9 / zimn\u00e9"],
    ],
    p_size: commonProp7,
    p_sex: commonProp5,
    p_brand: commonProp6,
    p_color: commonProp4,
};
