/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp18 } from './commonProp18';
import { commonProp4 } from './commonProp4';

export const pc_notebooks : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["PC_MONITOR", "Monitor"],
        ["DESKTOP", "Stoln\u00fd po\u010d\u00edta\u010d"],
        ["NOTEBOOK", "Notebook"],
        ["MODEMS_ROUTERS", "Modemy a routre"],
        ["NOTEBOOK_COVERS", "P\u00fazdra a obaly na notebooky"],
        ["NOTEBOOK_BAGS_BACKPACKS", "Ta\u0161ky a ruksaky na notebook"],
        ["NOTEBOOK_DECORATIONS", "N\u00e1lepky a ozdoby na notebook"],
        ["PRINTERS", "Tla\u010diarne a multifunk\u010dn\u00e9 zariadenia"],
        ["TONERS_CARTRIDES", "Tonery a n\u00e1plne"],
        ["PC_ACCESSORIES", "Pr\u00edslu\u0161enstvo"],
    ],
    p_brand: commonProp18,
    p_color: commonProp4,
};
