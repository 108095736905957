/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp4 } from './commonProp4';

export const home_carpets_rugs : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["CARPETS_RUNNERS", "Koberce a beh\u00fane"],
        ["SHEEPSKIN_COW_HIDE_RUGS", "Ko\u017ee a ko\u017eu\u0161iny"],
        ["DOOR_MATS", "Roho\u017eky"],
        ["BATH_MATS", "Predlo\u017eky do k\u00fape\u013ene a WC"],
        ["ANTISLIP_MATS", "Proti\u0161mykov\u00e9 podlo\u017eky"],
    ],
    p_color: commonProp4,
};
