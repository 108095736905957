/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp23 } from './commonProp23';

export const employment : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["JOBS_ADMINISTRATION", "Administrat\u00edva"],
        ["JOBS_FINANCE", "Financie"],
        ["JOBS_GASTRONOMY", "Gastro"],
        ["JOBS_CRAFTS", "Remesl\u00e1"],
        ["JOBS_TRADE", "Obchod"],
        ["JOBS_SERVICES", "Slu\u017eby"],
        ["JOBS_TRANSPORT_AND_LOGISTICS", "Doprava a logistika"],
        ["JOBS_TOURISM", "Cestovn\u00fd ruch"],
        ["JOBS_EDUCATION", "\u0160kolstvo a vzdel\u00e1vanie"],
        ["JOBS_MANUFACTURING", "V\u00fdroba"],
        ["JOBS_HEALTHCARE", "Zdravotn\u00edctvo"],
        ["JOBS_TECHNICAL_STAFF", "Technick\u00ed pracovn\u00edci"],
        ["JOBS_INFORMATION_TECHNOLOGY", "IT"],
        ["JOBS_SAFETY", "Bezpe\u010dnos\u0165"],
        ["JOBS_MARKETING", "Marketing"],
        ["JOBS_AGRICULTURE", "Po\u013enohospod\u00e1rstvo"],
        ["JOBS_WORKERS_PROFESSIONS", "Robotn\u00edcke profesie"],
        ["JOBS_SCIENCE_AND_RESEARCH", "Veda a v\u00fdskum"],
        ["JOBS_CULTURE_AND_SPORTS", "Kult\u00fara a \u0161port"],
        ["JOBS_MANAGEMENT", "Management"],
        ["JOBS_JUSTICE", "Pr\u00e1vo"],
        ["JOBS_GOVERNMENT", "\u0160t\u00e1tna spr\u00e1va"],
        ["JOBS_CREATIVE_WORK", "Tvoriv\u00e1 pr\u00e1ca a dizajn"],
        ["JOBS_OTHER", "In\u00e9"],
    ],
    p_working_time: [
        ["FULL_TIME_JOB", "Pln\u00fd"],
        ["PART_TIME_JOB", "\u010ciasto\u010dn\u00fd"],
        ["TEMPORARY_JOB", "Brig\u00e1da"],
    ],
    p_education: [
        ["SECONDARY_WITHOUT_DIPLOMA", "Bez maturity"],
        ["SECONDARY_WITH_DIPLOMA", "S maturitou"],
        ["COLLEGE", "V\u0160"],
    ],
    p_maternity_leave_suitable: commonProp23,
    p_homeoffice: commonProp23,
};
