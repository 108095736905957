/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const adults_homeware : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["SHOPPING_BAGS", "N\u00e1kupn\u00e9 ta\u0161ky a voz\u00edky"],
        ["STATIONERY", "Kancel\u00e1rske a p\u00edsacie potreby"],
        ["OTHER", "In\u00e9"],
    ],
};
