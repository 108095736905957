/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const pc_gaming : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["PC_GAME", "Hra"],
        ["GAME_CONSOLE", "Hern\u00e1 konzola"],
        ["GAMING_ACCESSORIES", "Hern\u00e9 pr\u00edslu\u0161enstvo"],
    ],
    p_gaming_platform: [
        d("MICROSOFT XBOX"),
        d("NINTENDO"),
        d("NINTENDO WII"),
        d("SONY PLAYSTATION"),
        d("PC"),
        ["OTHER_PLATFORM", "In\u00e1 platforma"],
    ],
};
