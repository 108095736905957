/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp18 } from './commonProp18';
import { commonProp4 } from './commonProp4';

export const phones_tablets_ereaders : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["SMARTPHONE", "Smartphone/ smartf\u00f3n"],
        ["MOBILE_PHONE", "Klasick\u00fd mobiln\u00fd telef\u00f3n"],
        ["HOME_PHONE", "Telef\u00f3n pre pevn\u00fa linku, fax"],
        ["TABLET", "Tablet"],
        ["EREADER", "\u010c\u00edta\u010dka kn\u00edh"],
        ["PHONE_TABLET_CHARGERS", "Nab\u00edja\u010dky"],
        ["PHONE_TABLET_SCREEN_PROTECTORS", "F\u00f3lie a ochrann\u00e9 skl\u00e1 na obrazovku"],
        ["PHONE_TABLET_CASES_COVERS", "P\u00fazdra, obaly a kryty"],
        ["PHONE_TABLET_DECORATIONS", "Pr\u00edvesky a ozdoby"],
        ["PHONE_TABLET_ACCESSORIES", "Pr\u00edslu\u0161enstvo a doplnky"],
    ],
    p_screen_size: [
        ["NA", "Neuveden\u00e9"],
        d("1\" - 1.9\""),
        d("2\" - 2.9\""),
        d("3\" - 3.9\""),
        d("4\" - 4.9\""),
        d("5\" -  5.9\""),
        d("6\" - 6.9\""),
        d("7\" - 7.9\""),
        d("8\" - 9.9\""),
        d("10\"+"),
    ],
    p_brand: commonProp18,
    p_color: commonProp4,
};
