/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const skateboards_boards : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_brand: [
        d("aga"),
        d("Aga4Kids"),
        d("darpeje"),
        d("disney"),
        d("enero"),
        d("globe"),
        d("kryptonics"),
        d("master"),
        d("mondo"),
        d("nils extreme"),
        d("razor"),
        d("reaper"),
        d("roxy"),
        d("sedco"),
        d("shumee"),
        d("skatey"),
        d("spartan"),
        d("stiga"),
        d("street surfing"),
        d("sulov"),
        d("tempish"),
        d("truly"),
        d("warner bros"),
        d("worker"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
