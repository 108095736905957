/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const rental : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["CAR_RENTAL", "Aut\u00e1"],
        ["TOOLS_RENTAL", "Stavebn\u00e9 stroje a n\u00e1radie"],
        ["RENTAL_FOR_CHILDREN", "Pre deti"],
        ["SPORT_RENTAL", "\u0160portov\u00e9 vybavenie"],
        ["OTHER_RENTAL", "In\u00e9"],
    ],
};
