/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const hiking_tents : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_brand: [
        d("acra"),
        d("bestway"),
        d("coleman"),
        d("crossroad"),
        d("easy camp"),
        d("forclaz"),
        d("high peak"),
        d("highlander"),
        d("husky"),
        d("king camp"),
        d("malatec"),
        d("mc kinley"),
        d("merco"),
        d("mfh"),
        d("outwell"),
        d("pinguin"),
        d("pronett"),
        d("regatta"),
        d("robens"),
        d("rock empire"),
        d("snugpak"),
        d("terra nova equipment"),
        d("trimm"),
        d("vango"),
        d("wild country by terra nova"),
        d("zulu"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
