/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp6 } from './commonProp6';
import { commonProp4 } from './commonProp4';

export const hats_mittens_scarves : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["HATS", "\u010ciapka"],
        ["PEAKED_CUPS", "\u0160iltovka, bekovka"],
        ["STRAW_HATS", "Klob\u00fak"],
        ["MITTENS", "Rukavice"],
        ["SCARVES", "\u0160\u00e1l"],
        ["BANDANNAS", "\u0160atka na krk"],
        ["HAT_MASK", "Kukla"],
        ["HAT_HEADBAND", "\u010celenka"],
        ["HEADSCARF", "Pir\u00e1tka, \u0161atka na hlavu"],
        ["NECKWARMER", "N\u00e1kr\u010dn\u00edk"],
        ["MUFF", "Ruk\u00e1vnik"],
        ["EARMUFF", "Klapky, sl\u00fachadl\u00e1 na u\u0161i"],
        ["HAT_SETS", "S\u00faprava, set"],
    ],
    p_size: [
        d("<50"),
        d("50"),
        d("56"),
        d("62"),
        d("68"),
        d("74"),
        d("80"),
        d("86"),
        d("92"),
        d("98"),
        d("104"),
        d("110"),
        d("116"),
        d("122"),
        d("128"),
        d("134"),
        d("140"),
        d("146"),
        d("152"),
        d("158"),
        d("XS"),
        d("S"),
        d("M"),
        d("L"),
        d("XL"),
        d("XXL"),
        d("XXXL"),
        d("4XL"),
        d("5XL"),
        d("34"),
        d("36"),
        d("38"),
        d("40"),
        d("42"),
        d("44"),
        d("46"),
        d("48"),
        d("52"),
        d("54"),
        d("58"),
        d("60"),
        ["ONE_SIZE_FIT_ALL", "Univerz\u00e1lna ve\u013ekos\u0165"],
    ],
    p_sex: commonProp5,
    p_brand: commonProp6,
    p_color: commonProp4,
};
