// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const _500_MESSAGE_2 = "Str\u00e1nka je z technick\u00fdch pr\u00ed\u010din nedostupn\u00e1";
export const _500_REPORT = "Nahl\u00e1si\u0165";
export const CTA_GO_TO_HOMEPAGE = "Cho\u010f na homepage";
export const CTA_RETURN_BACK = "Vr\u00e1\u0165 sa sp\u00e4\u0165";
export const LOAD_ERROR_RETRY = "Sk\u00fas znovu";
export const RAVEN_REPORT_ERROR_FORM_ENTRY = "Niektor\u00e9 polo\u017eky s\u00fa neplatn\u00e9. Pros\u00edm, skontroluj je.";
export const RAVEN_REPORT_ERROR_GENERIC = "Nastala neo\u010dak\u00e1van\u00e1 chyba pri odosielan\u00ed tvojho reportu. Sk\u00fas to pros\u00edm znova.";
export const RAVEN_REPORT_LABEL_CLOSE = "Zavrie\u0165";
export const RAVEN_REPORT_LABEL_COMMENTS = "\u010co presne sa stalo? \u010co si chcela urobi\u0165?";
export const RAVEN_REPORT_LABEL_EMAIL = "E-mail";
export const RAVEN_REPORT_LABEL_NAME = "Login (prihlasovacie meno) ";
export const RAVEN_REPORT_SUBMIT = "Odosla\u0165";
export const RAVEN_REPORT_SUBTITLE = "Modr\u00fd kon\u00edk team bol informovan\u00fd.";
export const RAVEN_REPORT_SUBTITLE2 = "Ak n\u00e1m chce\u0161 pom\u00f4c\u0165, nap\u00ed\u0161 n\u00e1m, \u010do sa stalo.";
export const RAVEN_REPORT_SUCCESS_MESSAGE = "Tvoj report bol \u00faspe\u0161ne odoslan\u00fd. \u010eakujeme";
export const RAVEN_REPORT_TITLE = "Vyzer\u00e1 to, \u017ee m\u00e1me nejak\u00fd technick\u00fd probl\u00e9m.";
