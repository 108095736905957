/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp14 } from './commonProp14';
import { commonProp4 } from './commonProp4';

export const ice_hockey_footwear : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp14,
    p_brand: [
        d("Barum"),
        d("Bauer"),
        d("Botas"),
        d("CCM"),
        d("Crowned"),
        d("Fila"),
        d("Graf"),
        d("Rulyt"),
        d("Sportteam"),
        d("Sulov"),
        d("Tempish"),
        d("True"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
