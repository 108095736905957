/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const electric_bicycle : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_brand: [
        d("4ever"),
        d("Agogs"),
        d("Apache"),
        d("BESV"),
        d("Bianchi"),
        d("BMC"),
        d("CANNONDALE"),
        d("Convay"),
        d("Crussis"),
        d("CTM"),
        d("Cycleman"),
        d("Devron"),
        d("FOCUS"),
        d("Fuzlu"),
        d("Ghost"),
        d("Haibike"),
        d("Inmotion"),
        d("KALKHOFF"),
        d("Kellys"),
        d("Kross"),
        d("KTM"),
        d("Lapierre"),
        d("Leader Fox"),
        d("Lectron"),
        d("Merida"),
        d("MONDRAKER"),
        d("Orbea"),
        d("RIESE & M\u00dcLLER"),
        d("ROMET"),
        d("S-BIKES"),
        d("SANTA CRUZ"),
        d("Sava"),
        d("SUPERIOR"),
        d("WOOM"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
