/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const automoto_spare_parts : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["CAR_AUDIO", "Autor\u00e1di\u00e1"],
        ["CAR_GLASS", "Autoskl\u00e1"],
        ["CAR_BRAKES", "Brzdy"],
        ["CAR_CHASSIS_PARTS", "\u010casti karos\u00e9rie"],
        ["CAR_ELECTRICAL_WIRING", "Elektroin\u0161tal\u00e1cia"],
        ["CAR_WHEELS_AND_RIMS", "Koles\u00e1 a disky"],
        ["CAR_SPARE_PARTS", "N\u00e1hradn\u00e9 diely a pr\u00edslu\u0161enstvo"],
        ["CAR_SUMMER_TIRES", "Pneumatiky letn\u00e9"],
        ["CAR_WINTER_TIRES", "Pneumatiky zimn\u00e9"],
        ["CAR_MANDATORY_EQUIPMENT", "Povinn\u00e1 v\u00fdbava"],
        ["CAR_LIGHTS", "Svetl\u00e1 a blinkre"],
        ["CAR_INTERIOR_EQUIPMENT", "Vybavenie interi\u00e9ru"],
        ["CAR_COSMETICS", "Autokozmetika, v\u00f4\u0148a do auta"],
    ],
};
