/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const baby_feeding : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["FEEDING_BOTTLES", "Doj\u010densk\u00e9 f\u013ea\u0161e a spol."],
        ["DUMMIES", "Dudl\u00edky, cuml\u00edky"],
        ["STERILIZER", "Steriliz\u00e1tory, pr\u00edprava stravy"],
        ["THERMOMETER", "Teplomery, ods\u00e1va\u010dky na sopl\u00edky"],
        ["WARMERS", "Ohrieva\u010de flia\u0161 a detskej stravy"],
        ["BIBS", "Podbradn\u00edky pre doj\u010dat\u00e1"],
        ["MILK_DISPENSERS", "D\u00e1vkova\u010de mlieka"],
        ["DRIP_TRAYS", "Odkvapk\u00e1va\u010de"],
        ["BOTTLE_THERMOPACK", "Termoobal na f\u013ea\u0161u"],
        ["SUPPLEMENTOR", "Suplementor"],
    ],
    p_brand: [
        d("akuku"),
        d("Arianna"),
        d("AVENT"),
        d("baby mix"),
        d("babylove"),
        d("babymoov"),
        d("babyono"),
        d("BAYBY"),
        d("beurer"),
        d("bibi"),
        d("BIBS"),
        d("BRAUN"),
        d("B\u00c9ABA"),
        d("Canpol babies"),
        d("Chicco"),
        d("Disney"),
        d("Dr. Brown's"),
        d("Elodie Details"),
        d("F&F"),
        d("GAMA"),
        d("George"),
        d("H&M"),
        d("IKEA"),
        d("Jan\u00e9"),
        d("Kikko/Xkko"),
        d("Lansinoh"),
        d("lovi"),
        d("lupilu"),
        d("MAM"),
        d("medela"),
        d("microlife"),
        d("munchkin"),
        d("mushie"),
        d("New Baby"),
        d("Nuby"),
        d("NUK"),
        d("NUVITA"),
        d("N\u00fbby"),
        d("OMRON"),
        d("Pura"),
        d("Reer"),
        d("Sassy"),
        d("Suavinex"),
        d("Tefal"),
        d("Tommee Tippee"),
        d("Topcom"),
        d("TWISTSHAKE"),
        d("VITAL BABY"),
        d("yoomi"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
};
