/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const hiking_backpack : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_brand: [
        d("adidas"),
        d("boll"),
        d("capital sports"),
        d("coleman"),
        d("converse"),
        d("corazon"),
        d("dakine"),
        d("dynafit"),
        d("fjord dansen"),
        d("gemma"),
        d("hannah"),
        d("husky"),
        d("karrimor"),
        d("king camp"),
        d("loap"),
        d("lowe alpine"),
        d("mammut"),
        d("nike"),
        d("nikko"),
        d("nitro"),
        d("ortovox"),
        d("pinguin"),
        d("puma"),
        d("reebok"),
        d("salewa"),
        d("salomon"),
        d("the north face"),
        d("thule"),
        d("tsl"),
        d("under armour"),
        d("vans"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
