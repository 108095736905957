/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const ski_poles : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: [
        d("65 cm"),
        d("70 cm"),
        d("75 cm"),
        d("80 cm"),
        d("85 cm"),
        d("90 cm"),
        d("95 cm"),
        d("100 cm"),
        d("105 cm"),
        d("110 cm"),
        d("115 cm"),
        d("120 cm"),
        d("125 cm"),
        d("130 cm"),
        d("135 cm"),
        d("140 cm"),
        d("145 cm"),
        d("150 cm"),
        d("155 cm"),
        d("160 cm"),
        d("165 cm"),
        d("170 cm"),
        d("175 cm"),
        d("180 cm"),
    ],
    p_brand: [
        d("Atomic"),
        d("Blizzard"),
        d("FISCHER"),
        d("Head"),
        d("Komperdell"),
        d("Leki"),
        d("Salomon"),
        d("Sulov"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
