/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp19: Array<[string, string]> = [
    ["MANUAL_GEARBOX", "manu\u00e1lna"],
    ["AUTOMATIC_GEARBOX", "automatick\u00e1"],
];
