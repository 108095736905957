/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const photography : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["PHOTOGRAPHY_BACKGROUNDS", "Fotopozadia"],
        ["PHOTOGRAPHY_PROPS", "Rekvizity a doplnky na fotenie"],
    ],
};
