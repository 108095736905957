/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp14 } from './commonProp14';
import { commonProp4 } from './commonProp4';

export const hiking_footwear : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp14,
    p_brand: [
        d("adidas"),
        d("alfa"),
        d("alpine crown"),
        d("alpine pro"),
        d("alpinus"),
        d("asics"),
        d("ba\u0165a"),
        d("bennon"),
        d("blizzard"),
        d("bosp"),
        d("bushman"),
        d("caterpillar"),
        d("cmp"),
        d("conway"),
        d("crocs"),
        d("demar"),
        d("dunlop"),
        d("fox outdoor"),
        d("gar sport"),
        d("garmont"),
        d("gerert"),
        d("gore tex"),
        d("hannah"),
        d("helly hansen"),
        d("hi tec"),
        d("isotex"),
        d("karimor"),
        d("keen"),
        d("la sportiva"),
        d("lizard"),
        d("lotto"),
        d("lowa"),
        d("mammut"),
        d("mc kinley"),
        d("meindl"),
        d("merrel"),
        d("montura"),
        d("new balance"),
        d("nike"),
        d("nordblanc"),
        d("olang"),
        d("OutDry"),
        d("ptx"),
        d("reebok"),
        d("regi tex"),
        d("rieker"),
        d("salewa"),
        d("salomon"),
        d("scarpa"),
        d("skechers"),
        d("sprandi"),
        d("tecnica"),
        d("Texapore"),
        d("the north face"),
        d("trek"),
        d("trespass"),
        d("under armour"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
