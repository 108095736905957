/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const accommodation : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["HOTELS", "Hotely a penzi\u00f3ny"],
        ["CAMPING", "Kempy"],
        ["COTTAGES", "Chaty a chalupy"],
        ["ABROAD_ACCOMMODATION", "V zahrani\u010d\u00ed"],
        ["ANIMAL_HOTELS", "Hotely pre zvierat\u00e1"],
    ],
};
