/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp22 } from './commonProp22';

export const babysitting : {[key: string]: Array<[string, string]>} = {
    p_frequency: [
        ["ONE_TIME_OR_IRREGULARLY", "Jednor\u00e1zovo alebo nepravidelne"],
        ["REGULARLY_IN_THE_MORNING", "Pravidelne dopoludnia"],
        ["REGULARLY_AFTERNOON_AND_EVENING", "Pravidelne poobede a ve\u010der"],
        ["REGULARLY_ALL_DAY", "Pravidelne cel\u00fd de\u0148"],
    ],
    p_age_years: [
        ["0-1YEARS", "B\u00e1b\u00e4tko (0-1 rok)"],
        ["1-3YEARS", "Bato\u013ea (1-3 roky)"],
        ["3-6YEARS", "\u0160k\u00f4lkar (3-6 rokov)"],
        ["6-10YEARS", "Mal\u00fd \u0161kol\u00e1k (6-10 rokov)"],
        ["10-15YEARS", "Ve\u013ek\u00fd \u0161kol\u00e1k (10-15 rokov)"],
    ],
    p_practice_length: commonProp22,
    p_place: [
        ["CHILDREN_RESIDENCE", "V bydlisku det\u00ed"],
        ["BABYSITTER_RESIDENCE", "V bydlisku opatrovate\u013eky"],
    ],
};
