/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const household_help : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["REGULAR_HOUSEHOLD_CLEANING", "Be\u017en\u00e9 upratovanie dom\u00e1cnosti"],
        ["WASHING_WINDOWS", "Um\u00fdvanie okien"],
        ["WASHING_AND_IRONING", "Pranie a \u017eehlenie"],
        ["SHOPPING_AND_DELIVERY", "N\u00e1kupy a don\u00e1\u0161ky"],
        ["COOKING_AND_BAKING", "Varenie a pe\u010denie"],
        ["ANIMAL_CARE", "Starostlivos\u0165 o zvierat\u00e1"],
        ["GARDEN_MAINTENANCE", "\u00dadr\u017eba zahrady"],
        ["BIG_CLEANING", "Ve\u013ek\u00e9 upratovanie"],
        ["OTHER_HOUSEHOLD_HELP", "In\u00e1 v\u00fdpomoc"],
    ],
    p_space_type: [
        ["FLAT_1_TO_3_ROOMS", "1-3-izbov\u00fd byt"],
        ["FLAT_4_PLUS_ROOMS", "4 a viac izbov\u00fd byt"],
        ["FAMILY_HOUSE", "Rodinn\u00fd dom"],
        ["OFFICE_SPACE", "Kancel\u00e1rsk\u00e9 priestory"],
        ["COMMON_NONRESIDENTIAL_PREMISES", "Spolo\u010dn\u00e9 nebytov\u00e9 priestory"],
        ["GARDEN", "Z\u00e1hrada"],
        ["OTHER_SPACE_TYPE", "In\u00fd"],
    ],
    p_frequency: [
        ["ABRUPTLY", "N\u00e1razovo"],
        ["EVERY_DAY", "Ka\u017ed\u00fd de\u0148"],
        ["ONCE_PER_WEEK", "1x t\u00fd\u017edenne"],
        ["OTHER_FREQUENCY", "In\u00e1"],
    ],
};
