/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const gifts : {[key: string]: Array<[string, string]>} = {
    p_occasion: [
        ["CHILDBIRTH", "Narodenie die\u0165atka"],
        ["ALBUM", "Albumy"],
        ["BAPTISM", "Krst"],
        ["COMMUNION", "Prij\u00edmanie, konfirm\u00e1cia"],
        ["BIRTHDAY", "Narodeniny"],
        ["WEDDING", "Svadba"],
        ["WEDDING_ANNIVERSARY", "V\u00fdro\u010die svadby"],
        ["IN_LOVE", "Pre zamilovan\u00fdch"],
        ["GIFT_WRAP", "Obaly na dar\u010deky"],
        ["GIFTS_OTHER", "In\u00e9"],
    ],
    p_sex: [
        ["GIRLS", "Pre diev\u010dat\u00e1"],
        ["BOYS", "Pre chlapcov"],
        ["KIDS", "Pre die\u0165a (unisex)"],
        ["FAMILY", "Pre rodinu"],
        ["COUPLE", "Pre p\u00e1r"],
        ["WOMENS", "Pre d\u00e1my"],
        ["MENS", "Pre p\u00e1nov"],
        ["ADULTS", "Pre dospel\u00fdch"],
    ],
};
