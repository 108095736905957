/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const balance_bike : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_brand: [
        d("ARTI Speedy"),
        d("B-Toys"),
        d("Bayo"),
        d("Berg"),
        d("Big"),
        d("Bino"),
        d("Chicco"),
        d("Chillafish"),
        d("Clementoni"),
        d("Coccolle"),
        d("DEMA"),
        d("DHS"),
        d("Dohany"),
        d("Eccoiffier"),
        d("EURObaby"),
        d("Falk"),
        d("Ferbedo"),
        d("Firstbike"),
        d("Fisher Price"),
        d("GLOBBER"),
        d("Hudora"),
        d("Imaginarium"),
        d("Jamara"),
        d("Jdbug"),
        d("KaZAM"),
        d("KELLYS"),
        d("KinderKraft"),
        d("Kruzzel"),
        d("Lamps"),
        d("Lifefit"),
        d("LITTLE DUTCH"),
        d("Lorelli"),
        d("MamaLove"),
        d("MamaToyz"),
        d("Master"),
        d("MGA"),
        d("Micro"),
        d("Mikro Trading"),
        d("Milly Mally"),
        d("Mondo Toys"),
        d("Movino"),
        d("Pilsan"),
        d("Puky"),
        d("Qplay"),
        d("Royal Baby"),
        d("S'COOL"),
        d("Shumee"),
        d("Skip Hop"),
        d("Smoby"),
        d("SporTrike"),
        d("Stamp"),
        d("Stiga"),
        d("Street Surfing"),
        d("STRIDER"),
        d("STX"),
        d("Sulov"),
        d("Teddies"),
        d("TOYZ"),
        d("Trolls"),
        d("Trunki"),
        d("TRYBIKE"),
        d("Viga"),
        d("Vilac"),
        d("Worker"),
        d("Yedoo"),
        d("Zopa"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
