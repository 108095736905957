/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp14 } from './commonProp14';
import { commonProp4 } from './commonProp4';

export const dancing_footwear : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp14,
    p_brand: [
        d("anna kern"),
        d("artis"),
        d("BD Dance"),
        d("bloch"),
        d("botan"),
        d("capezio"),
        d("Freed of London"),
        d("nueva epoca"),
        d("portdance"),
        d("Ratchet"),
        d("rummos"),
        d("starever"),
        d("werner kern"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
