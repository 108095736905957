// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const NO = "Nie";
export const STROLLER_BACK_SUPPORT_POSITIONING_BUCKLE = "prackou";
export const STROLLER_BACK_SUPPORT_POSITIONING_BUTTON = "tla\u010didlom";
export const STROLLER_BACK_SUPPORT_POSITIONING_FRONT_RECLINE = "mechanizmom nakl\u00e1\u0148ania pod sedadlom";
export const STROLLER_BACK_SUPPORT_POSITIONING_REAR_RECLINE = "mechanizmom nakl\u00e1\u0148ania vzadu";
export const STROLLER_BACK_SUPPORT_POSITIONING_SIDE_HANDLES = "p\u00e1\u010dkami po bokoch";
export const STROLLER_BACK_SUPPORT_POSITIONING_SIDE_RECLINE = "mechanizmom nakl\u00e1\u0148ania po bokoch sedadla";
export const STROLLER_BACK_SUPPORT_POSITIONING_STRAP = "p\u00e1som";
export const STROLLER_BACK_SUPPORT_POSITIONING_ZIP = "zipsom";
export const STROLLER_BUMPER_BAR_TYPE_BAR = "ty\u010dov\u00e9";
export const STROLLER_BUMPER_BAR_TYPE_TRAY = "pultov\u00e9";
export const STROLLER_BUMPER_BAR_TYPE_T_SHAPED = "v tvare T";
export const STROLLER_CARD_RECOMMENDED_CHOICE_HAPPY_MEDIUM = "Zlat\u00fd stred";
export const STROLLER_CARD_RECOMMENDED_CHOICE_POPULAR = "Za babku";
export const STROLLER_CARD_RECOMMENDED_CHOICE_TOP = "TOP";
export const STROLLER_CENTRAL_BRAKE_SYSTEM_HAND_OPERATED = "mechanizmus na r\u00fa\u010dke";
export const STROLLER_CENTRAL_BRAKE_SYSTEM_ONE_STEP_CRADLE_BRAKE = "n\u00e1\u0161\u013eapn\u00e1 kol\u00edskov\u00e1";
export const STROLLER_CENTRAL_BRAKE_SYSTEM_PUSH_LIFT = "n\u00e1\u0161\u013eapn\u00e1 stla\u010d/zdvihni";
export const STROLLER_CENTRAL_BRAKE_SYSTEM_PUSH_PUSH = "n\u00e1\u0161\u013eapn\u00e1 stla\u010d/stla\u010d";
export const STROLLER_CLEANING_THE_STROLLER_FABRICS_MANUALLY = "ru\u010dne";
export const STROLLER_CLEANING_THE_STROLLER_FABRICS_WACHINE_MACHINE = "pranie";
export const STROLLER_FEATURE_BACK_SUPPORT_ALMOST_FLAT = "takmer \u00fapln\u00fd rovn\u00fd \u013eah (165\u00b0)";
export const STROLLER_FEATURE_BACK_SUPPORT_ALMOST_TROUGH = "takmer \u00fapln\u00fd kor\u00fdtkov\u00fd \u013eah";
export const STROLLER_FEATURE_BACK_SUPPORT_FULL_FLAT = "\u00fapln\u00fd rovn\u00fd \u013eah (180\u00b0)";
export const STROLLER_FEATURE_BACK_SUPPORT_FULL_TROUGH = "\u00fapln\u00fd kor\u00fdtkov\u00fd \u013eah";
export const STROLLER_FEATURE_BACK_SUPPORT_HALF_FLAT = "polo\u013eah (150\u00b0)";
export const STROLLER_FEATURE_BUCKET_SEAT_ADJUSTABLE_RECLINE = "Nastavite\u013en\u00fd \u013eah";
export const STROLLER_FEATURE_BUCKET_SEAT_BUCKET = "Kor\u00fdtko";
export const STROLLER_FEATURE_BUCKET_SEAT_HYBRID = "Hybrid";
export const STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT_AVERAGE = "vhodn\u00fd pre priemerne vysok\u00fdch rodi\u010dov";
export const STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT_SHORT = "vhodn\u00fd pre n\u00edzkych rodi\u010dov";
export const STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT_TALL = "vhodn\u00fd pre vysok\u00fdch rodi\u010dov";
export const STROLLER_FOLDING_SYSTEM_3D = "3D";
export const STROLLER_FOLDING_SYSTEM_FLAT = "na plocho";
export const STROLLER_FOLDING_SYSTEM_TELESCOPIC = "teleskopick\u00fd";
export const STROLLER_FOLDING_SYSTEM_UMBRELLA = "na d\u00e1\u017ednik";
export const STROLLER_HANDLE_ADJUSTMENT_FIXED = "pevn\u00e1 (fixn\u00e1)";
export const STROLLER_HANDLE_ADJUSTMENT_PIVOTING = "oto\u010dn\u00e1";
export const STROLLER_HANDLE_ADJUSTMENT_TELESCOPIC = "teleskopick\u00e1";
export const STROLLER_HARNESS_TYPE_2B = "2 bodov\u00fd";
export const STROLLER_HARNESS_TYPE_3B = "3 bodov\u00fd";
export const STROLLER_HARNESS_TYPE_5B = "5 bodov\u00fd";
export const STROLLER_HARNESS_TYPE_6B = "6 bodov\u00fd";
export const STROLLER_MATERIAL_ALUMINIUM = "hlin\u00edk";
export const STROLLER_MATERIAL_CARBON = "karb\u00f3n";
export const STROLLER_MATERIAL_CORK = "korok";
export const STROLLER_MATERIAL_DURAL = "dural";
export const STROLLER_MATERIAL_FABRIC = "l\u00e1tka";
export const STROLLER_MATERIAL_FOAM = "pena";
export const STROLLER_MATERIAL_HARD_RUBBER = "tvrden\u00e1 guma";
export const STROLLER_MATERIAL_IRON = "\u017eelezo";
export const STROLLER_MATERIAL_LEATHER = "ko\u017ea";
export const STROLLER_MATERIAL_LEATHERETTE = "ko\u017eenka";
export const STROLLER_MATERIAL_MAGNESIUM = "hor\u010d\u00edk";
export const STROLLER_MATERIAL_METAL = "kov";
export const STROLLER_MATERIAL_NYLON = "nylon";
export const STROLLER_MATERIAL_OTHER = "ostatn\u00e9";
export const STROLLER_MATERIAL_PLASTIC = "plast";
export const STROLLER_MATERIAL_RECYCLED_PLASTIC = "recyklovan\u00fd plast";
export const STROLLER_MATERIAL_RUBBER = "guma";
export const STROLLER_MATERIAL_STEEL = "oce\u013e";
export const STROLLER_MATERIAL_WOOD = "drevo";
export const STROLLER_NUMBER_OF_KIDS_MORE = "pre dvoji\u010dky/s\u00farodencov";
export const STROLLER_NUMBER_OF_KIDS_ONE = "pre jedno die\u0165a";
export const STROLLER_OR_CONJUNCTION = " alebo ";
export const STROLLER_ROTATING_FIXATION_WHEELS_FIXED = "fixn\u00e9 koles\u00e1";
export const STROLLER_ROTATING_FIXATION_WHEELS_ROTATING = "oto\u010dn\u00e9 koles\u00e1";
export const STROLLER_ROTATING_FIXATION_WHEELS_ROTATING_LOCKABLE = "oto\u010dn\u00e9 s mo\u017enos\u0165ou fix\u00e1cie";
export const STROLLER_SUSPENSION_ELASTOMERS = "elastom\u00e9rmi";
export const STROLLER_SUSPENSION_FRAME = "kon\u0161trukciou";
export const STROLLER_SUSPENSION_MECHANICAL = "mechanick\u00e9";
export const STROLLER_SUSPENSION_MECHANICAL_ELASTOMERS = "mechanick\u00e9 a elastom\u00e9rmi";
export const STROLLER_SUSPENSION_MECHANICAL_FRAME = "mechanick\u00e9 a kon\u0161trukciou";
export const STROLLER_SUSPENSION_MECHANICAL_ROCKER = "mechanick\u00e9 a v\u00e1hadl\u00e1";
export const STROLLER_SUSPENSION_NONE = "bez odpru\u017eenia";
export const STROLLER_SUSPENSION_PNEUMATIC = "pneumatick\u00e9";
export const STROLLER_SUSPENSION_PNEUMATIC_FRAME = "pneumatick\u00e9 a kon\u0161trukciou";
export const STROLLER_SUSPENSION_PNEUMATIC_MECHANICAL = "pneumatick\u00e9 a mechanick\u00e9";
export const STROLLER_SUSPENSION_PNEUMATIC_SPRING = "pru\u017einov\u00e9 a pneumatick\u00e9";
export const STROLLER_SUSPENSION_ROCKER = "vahadl\u00e1";
export const STROLLER_SUSPENSION_SPRING = "pru\u017einov\u00e9";
export const STROLLER_SUSPENSION_SPRING_ELASTOMERS = "pru\u017einov\u00e9 a elastom\u00e9rmi";
export const STROLLER_SUSPENSION_SPRING_MECHANICAL = "pru\u017einov\u00e9 a mechanick\u00e9";
export const STROLLER_SUSPENSION_SPRING_MECHANICAL_FRAME = "pru\u017einov\u00e9 a mechanick\u00e9 a kon\u0161trukciou";
export const STROLLER_SUSPENSION_SPRING_MECHANICAL_PNEUMATIC = "pru\u017einov\u00e9 a mechanick\u00e9 a pneumatick\u00e9";
export const STROLLER_SUSPENSION_STRAPS = "remienky";
export const STROLLER_TYPE_2IN1 = "2v1";
export const STROLLER_TYPE_2KOMBI = "dvojkombin\u00e1cia";
export const STROLLER_TYPE_2KOMBI_DEEP_SPORTY = "hlbok\u00fd+\u0161portov\u00fd";
export const STROLLER_TYPE_2KOMBI_DEEP_TRAVEL_SYSTEM = "hlbok\u00fd+vaj\u00ed\u010dko";
export const STROLLER_TYPE_2KOMBI_SPORTY_TRAVEL_SYSTEM = "\u0161portov\u00fd+vaj\u00ed\u010dko";
export const STROLLER_TYPE_3KOMBI = "trojkombin\u00e1cia";
export const STROLLER_TYPE_CHECKED_BAGGAGE = "Cestovn\u00fd";
export const STROLLER_TYPE_CITY_TERRAIN = "Mestsk\u00fd";
export const STROLLER_TYPE_COUNTRYSIDE_TERRAIN = "Dedina";
export const STROLLER_TYPE_DEEP = "hlbok\u00fd";
export const STROLLER_TYPE_GOLF = "golfov\u00fd";
export const STROLLER_TYPE_HARD_TERRAIN = "Ter\u00e9nny";
export const STROLLER_TYPE_LONG_NAME_2KOMBI = "Ko\u010d\u00edky dvojkombin\u00e1cie";
export const STROLLER_TYPE_LONG_NAME_3KOMBI = "Ko\u010d\u00edky trojkombin\u00e1cie";
export const STROLLER_TYPE_LONG_NAME_CHECKED_BAGGAGE = "Cestovn\u00e9 ko\u010d\u00edky";
export const STROLLER_TYPE_LONG_NAME_CITY_TERRAIN = "Ko\u010d\u00edky vhodn\u00e9 do mesta";
export const STROLLER_TYPE_LONG_NAME_COUNTRYSIDE_TERRAIN = "Ko\u010d\u00edky vhodn\u00e9 na dedinu";
export const STROLLER_TYPE_LONG_NAME_DEEP = "Hlbok\u00e9 ko\u010d\u00edky";
export const STROLLER_TYPE_LONG_NAME_GOLF = "Golfov\u00e9 ko\u010d\u00edky";
export const STROLLER_TYPE_LONG_NAME_HARD_TERRAIN = "Ter\u00e9nne ko\u010d\u00edky";
export const STROLLER_TYPE_LONG_NAME_RETRO = "Retro ko\u010d\u00edky";
export const STROLLER_TYPE_LONG_NAME_SIBLINGS = "S\u00farodeneck\u00e9 ko\u010d\u00edky";
export const STROLLER_TYPE_LONG_NAME_SPORT_RUNNING = "Ko\u010d\u00edk na beh a \u0161port";
export const STROLLER_TYPE_LONG_NAME_SPORTY = "\u0160portov\u00e9 ko\u010d\u00edky";
export const STROLLER_TYPE_RETRO = "Retro";
export const STROLLER_TYPE_SIBLINGS = "s\u00farodeneck\u00fd";
export const STROLLER_TYPE_SIBLINGS_DOUBLE = "double (2 deti ved\u013ea seba)";
export const STROLLER_TYPE_SIBLINGS_TANDEM = "tandem (deti umiestnen\u00e9 za sebou/nad sebou)";
export const STROLLER_TYPE_SIBLINGS_TRIPLE = "tripple (3 deti ved\u013ea seba)";
export const STROLLER_TYPE_SPORT_RUNNING = "Beh a \u0161port";
export const STROLLER_TYPE_SPORTY = "\u0161portov\u00fd";
export const STROLLER_TYPE_TRAVEL_SYSTEM = "cestovn\u00fd syst\u00e9m";
export const STROLLER_WHEELS_ALL = "v\u0161etky";
export const STROLLER_WHEELS_FRONT = "predn\u00e9";
export const STROLLER_WHEELS_REAR = "zadn\u00e9";
export const STROLLER_WHEEL_TYPE_WEB_AIRLESS_WHEELS = "nenafukovacie (s v\u00fdpl\u0148ou)";
export const STROLLER_WHEEL_TYPE_WEB_AIR_WHEELS = "nafukovacie";
export const STROLLER_WHEEL_TYPE_WEB_EBONITE_WHEELS = "tvrden\u00e1 guma";
export const STROLLER_WHEEL_TYPE_WEB_FOAM_WHEELS = "penov\u00e9";
export const STROLLER_WHEEL_TYPE_WEB_PLASTIC_WHEELS = "plastov\u00e9";
export const YES = "\u00c1no";
