/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp7 } from './commonProp7';
import { commonProp4 } from './commonProp4';

export const crosscountry_skiing_clothing : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp7,
    p_brand: [
        d("4F"),
        d("Adidas"),
        d("Alpine Pro"),
        d("Asics"),
        d("Blizzard"),
        d("Bula"),
        d("CMP"),
        d("Colmar"),
        d("Columbia"),
        d("Etape"),
        d("Fila"),
        d("Finmark"),
        d("Fischer"),
        d("Halti"),
        d("Hannah"),
        d("Head"),
        d("Helly Hansen"),
        d("Hi-Tec"),
        d("Champion"),
        d("Kama"),
        d("Kappa"),
        d("Klimatex"),
        d("Leki"),
        d("Level"),
        d("Lewro"),
        d("Loap"),
        d("Lotto"),
        d("Nike"),
        d("Nordica"),
        d("Northfinder"),
        d("Oakley"),
        d("Oneil"),
        d("Progress"),
        d("Puma"),
        d("Rex"),
        d("Rossignol"),
        d("Runto"),
        d("Salomon"),
        d("Sencor"),
        d("Sherpa"),
        d("Smartwool"),
        d("Sportful"),
        d("Spyder"),
        d("Sulov"),
        d("Swix"),
        d("The North Face"),
        d("Trimm"),
        d("Umbro"),
        d("Under Armour"),
        d("Voxx"),
        d("Warner Bros"),
        d("Zanier"),
        d("Ziener"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
