/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const building_and_construction_material : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["ELECTRICAL_WIRING", "Elektroin\u0161tal\u00e1cia"],
        ["PLUMBING", "Vodoin\u0161tal\u00e1cia"],
        ["INSULATION", "Izol\u00e1cia"],
        ["HEATING_AND_AIR_CONDITIONING", "K\u00farenie a klimatiz\u00e1cia"],
        ["TILING_AND_PAVING", "Obklady a dla\u017eba"],
        ["SAND_PEBBLES_AND_SOIL", "Piesok, kamienky a zemina"],
        ["BUILDING_MATERIAL", "Stavebn\u00fd materi\u00e1l"],
        ["ROOFING", "Stre\u0161n\u00e1 krytina"],
        ["FACADE_PLASTER_AND_PAINT", "Fas\u00e1da, omietky a farby"],
        ["WINDOWS_AND_DOORS", "Okn\u00e1 a dvere"],
        ["PROJECTS", "Projekty"],
        ["CONSTRUCTION_SHEDS", "Stavebn\u00e9 b\u00fady"],
        ["CONSTRUCTION_MACHINERY_AND_TOOLS", "Stavebn\u00e9 stroje a n\u00e1stroje"],
        ["SCAFFOLDING", "Le\u0161enie"],
        ["OTHER", "In\u00e9"],
    ],
};
