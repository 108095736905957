/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const cosmetics_bathing : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["BATHING", "K\u00fapacie potreby"],
        ["TOWELS", "Uter\u00e1ky a osu\u0161ky"],
        ["NAPPY_CHANGING", "Na preba\u013eovanie"],
        ["POTTY", "No\u010dn\u00edky, redukcie na WC"],
        ["BABY_COSMETICS", "Detsk\u00e1 kozmetika"],
        ["COMBS_BRUSHES", "Hrebene, kefy, no\u017eni\u010dky"],
        ["TOOTHBRUSHES", "\u010cistenie zubov"],
        ["CHANGING_TABLES", "Preba\u013eovacie pulty"],
        ["COSMETICS_BATHING_STEP", "Stupienok, schod\u00edk"],
        ["OTHER_COSMETICS_BATHING", "In\u00e9"],
    ],
};
