/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp7 } from './commonProp7';
import { commonProp5 } from './commonProp5';
import { commonProp6 } from './commonProp6';
import { commonProp4 } from './commonProp4';

export const swimwear_dressing_gowns : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["SWIMWEAR_ONEPIECE", "Jednodielne"],
        ["SWIMWEAR_SEPARATES", "Dvojdielne"],
        ["SUN_PROTECTION_WEAR", "Oble\u010denie do vody"],
        ["SWIMWEAR_ACCESSORIES", "Doplnky"],
    ],
    p_size: commonProp7,
    p_sex: commonProp5,
    p_brand: commonProp6,
    p_color: commonProp4,
};
