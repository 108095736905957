/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp14 } from './commonProp14';
import { commonProp4 } from './commonProp4';

export const footwear : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["FIRST_SHOES", "Capa\u010dky"],
        ["BALLERINAS", "Baler\u00edny"],
        ["SANDALS", "Sand\u00e1lky"],
        ["TRAINERS", "Tenisky"],
        ["BOOTS", "\u010ci\u017emy a zimn\u00e1 obuv"],
        ["WELLIES", "Gum\u00e1ky"],
        ["OCCASION_FOOTWARE", "Sl\u00e1vnostn\u00e1 obuv"],
        ["SHOES", "Top\u00e1nky"],
        ["SLIPPERS", "Papu\u010de a dom\u00e1ca obuv"],
        ["FLIPFLOPS", "\u0160\u013eapky a kroksy"],
        ["WATER_SHOES", "Do vody"],
        ["EXERCISE_SHOES", "Cvi\u010dky"],
        ["BAREFOOT", "Barefoot"],
        ["INDOOR_FOOTWEAR", "S\u00e1lovky, halovky"],
        ["FOOTWEAR_ACCESSORIES", "Doplnky (vlo\u017eky do top\u00e1nok, \u0161n\u00farky\u2026)"],
    ],
    p_heel: [
        ["WITH_HEEL", "S podp\u00e4tkom"],
        ["NO_HEEL", "Bez podp\u00e4tku"],
    ],
    p_size: commonProp14,
    p_sex: [
        ["GIRLS_WOMEN", "Pre diev\u010dat\u00e1, \u017eeny"],
        ["BOYS_MEN", "Pre chlapcov, mu\u017eov"],
    ],
    p_brand: [
        d("Adams"),
        d("Adidas"),
        d("Aeropostale"),
        d("Afelo"),
        d("Affenzahn"),
        d("Agaxy"),
        d("Ahinsa"),
        d("Aigle"),
        d("AIR JORDAN"),
        d("Akomi"),
        d("Alpine Pro"),
        d("Always"),
        d("American eagle"),
        d("Anatomic"),
        d("Antony Morato"),
        d("ara"),
        d("Armani"),
        d("ASICS"),
        d("ASSO"),
        d("Asylum"),
        d("Atmosphere"),
        d("Attipas"),
        d("AUTHORITY"),
        d("Azal\u00e9ia"),
        d("Baba Boo"),
        d("Baby"),
        d("Baby Bare Shoes"),
        d("Baby Club"),
        d("Balada"),
        d("Baldinini"),
        d("Bama"),
        d("Bambino"),
        d("Bambulini"),
        d("Barbie"),
        d("Bartek"),
        d("Barton"),
        d("Ba\u0165a"),
        d("be lenka"),
        d("BEDA"),
        d("Bee Bo"),
        d("Befado"),
        d("Benetton"),
        d("BERGSTEIN"),
        d("Bershka"),
        d("Beverly Hills"),
        d("Billabong"),
        d("Birki"),
        d("Birk\u2019s"),
        d("Bobbi Shoes"),
        d("BoboBaby"),
        d("Bobux"),
        d("Botas"),
        d("Bubble Gummers"),
        d("Bump"),
        d("Bundgaard"),
        d("Burberry"),
        d("B\u00e4ren-Schuhe"),
        d("C&A"),
        d("Camo"),
        d("Camper"),
        d("Campri"),
        d("CAPIKI"),
        d("Caribie"),
        d("Carter's"),
        d("Champion"),
        d("Cheraq"),
        d("Cherokee"),
        d("Chicco"),
        d("Chipmunks"),
        d("Chix"),
        d("Ciciban"),
        d("Ciyba Collection"),
        d("Clara Barson"),
        d("Clarks"),
        d("Coccodrillo"),
        d("Collegien"),
        d("COLMAR"),
        d("Colorful"),
        d("Columbia"),
        d("Converse"),
        d("COQUI"),
        d("COS"),
        d("CQ"),
        d("Crocs"),
        d("Cult design"),
        d("Cup&Chino"),
        d("Cupcake"),
        d("D.D.step"),
        d("Dadoos"),
        d("DC"),
        d("Decathlon"),
        d("DEI-tex"),
        d("Deichmann"),
        d("Demar"),
        d("Desigual"),
        d("DEVERGO"),
        d("Didriksons1913"),
        d("Diesel"),
        d("Dijean"),
        d("Dior"),
        d("Disney"),
        d("DKNY"),
        d("Dolce&Gabbana"),
        d("Domyos"),
        d("Donnay"),
        d("DPK"),
        d("Dr.Punto"),
        d("Dunlop"),
        d("Early Days"),
        d("ECCO"),
        d("Ecolution"),
        d("Ed Hardy"),
        d("Elefanten"),
        d("EN FANT"),
        d("Esprit"),
        d("ESSI"),
        d("Everlast"),
        d("EXIray"),
        d("F&F"),
        d("F.A.X."),
        d("Fare"),
        d("Feelmax"),
        d("Fila"),
        d("Filii"),
        d("FIREFLY"),
        d("Fishbone"),
        d("Fisher Price"),
        d("Fornarina"),
        d("FOX"),
        d("fox & bunny"),
        d("Francesco Morichetti"),
        d("Freycoo"),
        d("Froddo"),
        d("Gabor"),
        d("GANT"),
        d("Gap"),
        d("GAUDI"),
        d("gelert"),
        d("George"),
        d("GEOX"),
        d("GERRY WEBER"),
        d("Giesswein"),
        d("Gina"),
        d("GINO ROSSI"),
        d("Girl2girl"),
        d("Gmini"),
        d("Graceland"),
        d("Gucci"),
        d("Guess"),
        d("H&M"),
        d("Hatley"),
        d("Heelys"),
        d("Hi-Tec"),
        d("Hollister"),
        d("HUGO BOSS"),
        d("Humanic"),
        d("Hunter"),
        d("Hush Puppies"),
        d("ICETEX"),
        d("Intimissimi"),
        d("Items"),
        d("Jack & Lily"),
        d("Jack Wolfskin"),
        d("JAS Bardejov"),
        d("Jennifer&Jennifer"),
        d("JIMMY CHOO"),
        d("Jinwood"),
        d("John Garfield"),
        d("Joma"),
        d("Jonap"),
        d("K-Swiss"),
        d("Kamik"),
        d("KangaROOS"),
        d("Kappa"),
        d("KARL LAGERFELD"),
        d("Karrimor"),
        d("Keen"),
        d("Kenvelo"),
        d("Kickers"),
        d("Kidofit"),
        d("Kigo"),
        d("Kik"),
        d("Kilimanjaro"),
        d("Killah"),
        d("Killtec"),
        d("Kipsta"),
        d("Koel4kids"),
        d("Koka"),
        d("Kornecki"),
        d("Kuniboo"),
        d("Kustom"),
        d("L.A. Gear"),
        d("Lacoste"),
        d("Lady Ann"),
        d("Ladybird"),
        d("Lasocki"),
        d("Le Coq Sportif"),
        d("Ledeng"),
        d("Lee Cooper"),
        d("Lelli Kelly"),
        d("Levis"),
        d("Liberta"),
        d("Liliputi"),
        d("Lime"),
        d("Lindex"),
        d("Little Angel"),
        d("Little Angel\u00ae"),
        d("Little Blue Lamb"),
        d("Little Things"),
        d("Liu Jo"),
        d("Livie & Luca"),
        d("LOAP"),
        d("Lonsdale"),
        d("Lotto"),
        d("LOUIS VUITTON"),
        d("LOWA"),
        d("Lupilu"),
        d("Lurchi"),
        d("M&Co"),
        d("Mango"),
        d("Manik"),
        d("Marc O\u00b4Polo"),
        d("Marco Tozzi"),
        d("Marks & Spencer"),
        d("Marvel"),
        d("Matalan"),
        d("Mayoral"),
        d("MBT"),
        d("McKinley"),
        d("mel"),
        d("Melissa"),
        d("Merrel"),
        d("Merrell"),
        d("Mexx"),
        d("MICHAEL KORS"),
        d("Minene"),
        d("Mini Mode"),
        d("Minozzi"),
        d("Miss Fiori"),
        d("Miss Sixty"),
        d("Mixer"),
        d("Mizuno"),
        d("MLD"),
        d("MOHITO"),
        d("Morris Mouse"),
        d("MOSCHINO"),
        d("Mothercare"),
        d("Muddy Puddles"),
        d("Mustang"),
        d("MyMayu"),
        d("Nabaiji"),
        d("Nanga"),
        d("NAPAPIJRI"),
        d("Naturino"),
        d("Nazo"),
        d("Nelli Blu"),
        d("New Balance"),
        d("New Look"),
        d("Next"),
        d("Nike"),
        d("NINE WEST"),
        d("No Fear"),
        d("Nolen"),
        d("Norn"),
        d("Obutex"),
        d("Ocean Pacific"),
        d("OLANG"),
        d("Old Navy"),
        d("Paolo Santini"),
        d("Peddy"),
        d("Pediped"),
        d("Pegres"),
        d("Penn"),
        d("PEPCO"),
        d("Pepe Jeans"),
        d("Pepe shoe"),
        d("Pepperts"),
        d("Piazza Italia"),
        d("PINKO"),
        d("Playboy"),
        d("Playshoes"),
        d("PRADA"),
        d("Preschoolians"),
        d("Primark"),
        d("Primigi"),
        d("Protetika"),
        d("Pull&Bear"),
        d("Pulse"),
        d("PUMA"),
        d("Quechua"),
        d("Queen"),
        d("Quelle"),
        d("Quiksilver"),
        d("RAK"),
        d("Ralph Lauren"),
        d("Rebel"),
        d("Reebok"),
        d("Regatta"),
        d("Reima"),
        d("Ren But"),
        d("Reno"),
        d("Replay"),
        d("Reserved"),
        d("Richter"),
        d("Ricosta"),
        d("Rieker"),
        d("Rifle"),
        d("RIVER ISLAND"),
        d("ROBeeZ"),
        d("Rocer"),
        d("Rocket Dog"),
        d("Roh\u00e1\u010dan"),
        d("Rolling&Skate"),
        d("Rose et Chocolat"),
        d("Roxy"),
        d("RTX"),
        d("S.A.M"),
        d("s.Oliver"),
        d("SALAMANDER"),
        d("SALEWA"),
        d("Salomon"),
        d("Samlux"),
        d("Sandic"),
        d("Sant\u00e9"),
        d("Schiesser"),
        d("Scholl"),
        d("SDS"),
        d("Setino"),
        d("Shenda"),
        d("Shooshoos"),
        d("Siesta"),
        d("sinsay"),
        d("Skeanie"),
        d("Skechers"),
        d("Skippy"),
        d("Slazenger"),
        d("Slovenka"),
        d("Slovobuv"),
        d("Soft Touch"),
        d("Softstars shoes"),
        d("SOLE RUNNER"),
        d("Sorel"),
        d("Speedo"),
        d("Sprandi"),
        d("St. Bernard"),
        d("Starheight"),
        d("Sterntaler"),
        d("STEVE MADDEN"),
        d("Stival"),
        d("Stonz"),
        d("Stradivarius"),
        d("Stride Rite"),
        d("Super Gear"),
        d("Super In"),
        d("Superdry"),
        d("Superfit"),
        d("S\u00b4Botex"),
        d("Takko"),
        d("Tamaris"),
        d("TapTapi"),
        d("TCM"),
        d("TED BAKER"),
        d("Teddy shoes"),
        d("Tesco"),
        d("Texbasic"),
        d("Tikki"),
        d("Timberland"),
        d("TipsieToes"),
        d("Tom Tailor"),
        d("Tomar"),
        d("Tommy Hilfiger"),
        d("Topolino"),
        d("Tribord"),
        d("Tripos"),
        d("TU"),
        d("Twinkle toes"),
        d("U.S. POLO ASSN."),
        d("UGG"),
        d("Umbro"),
        d("UNDER ARMOUR"),
        d("Vagabond"),
        d("Vans"),
        d("Vanvaan"),
        d("Venture"),
        d("Versace"),
        d("vertbaudet"),
        d("Victoria's Secret"),
        d("Victory"),
        d("Viking"),
        d("Vivobarefoot"),
        d("Von Dutch"),
        d("Wallis"),
        d("Wanda"),
        d("Wear"),
        d("Weiser"),
        d("Wink"),
        d("Wojas"),
        d("WOJTYLKO"),
        d("Wolf"),
        d("Women'Secret"),
        d("Woolf"),
        d("Wrangler"),
        d("Young Dimension"),
        d("Young Spirit"),
        d("Zandonella"),
        d("Zara"),
        d("Zaxy"),
        d("Zeazoo"),
        d("Zetpol"),
        d("Zkama"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
