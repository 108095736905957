/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp4 } from './commonProp4';

export const home_dining : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["PLATES_BOWLS", "Taniere, misky"],
        ["DINNERWARE_SETS", "Jed\u00e1lensk\u00e9 s\u00fapravy"],
        ["MUGS_CUPS", "Hrn\u010deky a \u0161\u00e1lky"],
        ["GLASSES", "Poh\u00e1re"],
        ["TEA_COFFEE_SETS", "\u010cajov\u00e9 a k\u00e1vov\u00e9 s\u00fapravy"],
        ["TEA_POTS_JUGS_CARAFES", "Kanvice, d\u017eb\u00e1ny, karafy"],
        ["CUTLERY", "Pr\u00edbor"],
        ["SERVEWARE", "Serv\u00edrovacie taniere, podnosy a misy"],
        ["TEA_COFFEE_ACCESSOIRES", "Doplnky na \u010daj a k\u00e1vu"],
        ["WINE_ACCESSOIRES", "Doplnky k v\u00ednu"],
        ["BAR_ACCESSOIRES", "Barov\u00e9 doplnky"],
        ["PICNICK", "Z\u00e1hradn\u00e1 p\u00e1rty, grilovanie"],
        ["SALT_AND_PEPPER_SHAKERS", "So\u013eni\u010dka, koreni\u010dka"],
        ["OTHER_DINING", "In\u00e9"],
    ],
    p_color: commonProp4,
};
