/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const high_chairs : {[key: string]: Array<[string, string]>} = {
    p_material: [
        ["FROM_PVC", "Plastov\u00e9"],
        ["FROM_WOOD", "Dreven\u00e9"],
        ["FROM_FABRIC", "L\u00e1tkov\u00e9"],
    ],
    p_brand: [
        d("4baby"),
        d("Baby Design"),
        d("brevi"),
        d("Bumbo"),
        d("Cam"),
        d("Caretero"),
        d("Chicco"),
        d("Childhome"),
        d("Chipolino"),
        d("Coto Baby"),
        d("Cybex"),
        d("Eco Toys"),
        d("Gmini"),
        d("Hauck"),
        d("IKEA"),
        d("ingenuity"),
        d("Inglesina"),
        d("Jan\u00e9"),
        d("Joie"),
        d("KinderKraft"),
        d("Lorelli"),
        d("Milly Mally"),
        d("MIMA"),
        d("NEW BABY"),
        d("Peg-P\u00e9rego"),
        d("Petite&Mars"),
        d("Stokke"),
        d("Sun Baby"),
        d("Zopa"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
};
