/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const nourishment : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["FORMULA_MILK", "Umel\u00e9 mlieka"],
        ["PORRIDGES", "Ka\u0161a"],
        ["BABY_FOOD", "Detsk\u00e9 pr\u00edkrmy"],
        ["DIETARY_SUPPLEMENTS", "Potravinov\u00e9 doplnky"],
        ["JUICES_DRINKS", "\u0160\u0165avy, n\u00e1poje"],
        ["FRUIT_PUREE", "Ovocn\u00e9 pyr\u00e9, kapsi\u010dky"],
        ["BISCUITS", "Su\u0161ienky, sladkosti"],
        ["TEES", "\u010caje"],
    ],
};
