/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp14: Array<[string, string]> = [
    d("<17"),
    d("17"),
    d("18"),
    d("19"),
    d("20"),
    d("21"),
    d("22"),
    d("23"),
    d("24"),
    d("25"),
    d("26"),
    d("27"),
    d("28"),
    d("29"),
    d("30"),
    d("31"),
    d("32"),
    d("33"),
    d("34"),
    d("35"),
    d("36"),
    d("37"),
    d("38"),
    d("39"),
    d("40"),
    d("41"),
    d("42"),
    d("43"),
    d("44"),
    d("45"),
    d("46"),
    d("47"),
    d("48"),
    d("49"),
    d("50"),
    d("51"),
    d("52"),
    ["ONE_SIZE_FIT_ALL", "Univerz\u00e1lna ve\u013ekos\u0165"],
];
