/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp7 } from './commonProp7';
import { commonProp4 } from './commonProp4';

export const tennis_squash_clothing : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp7,
    p_brand: [
        d("Adidas"),
        d("Arcore"),
        d("Aress"),
        d("Asics"),
        d("Babolat"),
        d("Bula"),
        d("Champion"),
        d("Craft"),
        d("Diesel"),
        d("Dorko_Hungary"),
        d("Fila"),
        d("Finmark"),
        d("Fox"),
        d("Head"),
        d("kappa"),
        d("Lotto"),
        d("Mons Royale"),
        d("Nike"),
        d("Odlo"),
        d("Progress"),
        d("Russell Athletic"),
        d("Salomon"),
        d("Sensor"),
        d("Umbro"),
        d("Under Armour"),
        d("Wilson"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
