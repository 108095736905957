/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const scooter : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_brand: [
        d("Acra"),
        d("Aga"),
        d("Allright"),
        d("AO Scooters"),
        d("Authentic"),
        d("Bestial Wolf"),
        d("Buddy Toys"),
        d("Chilli"),
        d("CLS Motorcycle"),
        d("Crisp Scooters"),
        d("Darpeje"),
        d("Dominator Scooters"),
        d("Enero"),
        d("Ewor"),
        d("Fizz"),
        d("Frenzi"),
        d("Hudora"),
        d("JD Bug"),
        d("Kruzzel"),
        d("Little Tikes"),
        d("Longway"),
        d("Lorelli"),
        d("Lucky Scooters"),
        d("Madd Gear"),
        d("Malatec"),
        d("Master"),
        d("Milly Mally"),
        d("Mondo"),
        d("Mondo Toys"),
        d("Motorro"),
        d("Movino"),
        d("MTR"),
        d("NILS"),
        d("Olpran"),
        d("PB"),
        d("Premiere Sport"),
        d("Razor"),
        d("S'COOL"),
        d("Spokey"),
        d("Stiga"),
        d("Sulov"),
        d("Tempish"),
        d("Yedoo"),
        d("OXELO"),
        d("Micro"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
