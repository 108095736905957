/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const breastfeeding_accessories : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["BREASTPUMP", "Ods\u00e1va\u010dky matersk\u00e9ho mlieka"],
        ["NURSING_PILLOW", "Vank\u00fa\u0161e na doj\u010denie"],
        ["NURSING_NIGHTY", "No\u010dn\u00e9 ko\u0161ele na doj\u010denie"],
        ["NURSING_SHIRTS", "Tri\u010dk\u00e1, bl\u00fazky, svetre na doj\u010denie"],
        ["BREASTS", "Na bradavky"],
        ["BREASTFEED_BOOSTER", "Na podporu doj\u010denia"],
        ["BREASTFEED_ARMBAND", "\u0160atky na kojenie"],
    ],
};
