/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp4 } from './commonProp4';

export const home_appliances : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["TV_HOME_CINEMA", "TV, prehr\u00e1va\u010de a dom\u00e1ce kin\u00e1"],
        ["AUDIO_HIFI", "R\u00e1di\u00e1, audio a HiFi"],
        ["ANTENNA_SATELLITE_SETTOP_BOX", "Ant\u00e9na, satelit, set-top box"],
        ["WASHERS_DRYERS", "Pr\u00e1\u010dky a su\u0161i\u010dky"],
        ["OVENS_COOKERS_HOBS", "Spor\u00e1ky, r\u00fary a varn\u00e9 dosky"],
        ["MICROWAVE_OVENS", "Mikrovlnky"],
        ["FRIDGES_FREEZERS", "Chladni\u010dky a mrazni\u010dky"],
        ["COOKER_HOODS", "Digestory"],
        ["DISHWASHERS", "Um\u00fdva\u010dky riadu"],
        ["FRYERS", "Frit\u00e9zy"],
        ["STEAMERS_RICE_COOKERS", "Elektrick\u00e9 hrnce, panvice a ry\u017eovary"],
        ["TOASTERS_SANDWICH_MAKERS", "Hriankova\u010de, toastova\u010de, vaflova\u010de"],
        ["COFEE_MACHINES_KETTLES", "K\u00e1vovary, r\u00fdchlovarn\u00e9 kanvice"],
        ["FOOD_MIXERS", "Mix\u00e9ry, od\u0161\u0165avova\u010de a kuchynsk\u00e9 roboty"],
        ["BREADMAKERS", "Pek\u00e1rne na chlieb"],
        ["SODA_MAKERS", "V\u00fdroba s\u00f3dy"],
        ["WATER_FILTERS", "Filtra\u010dn\u00e9 kanvice"],
        ["GRILLS", "Elektrick\u00e9 grily (nie z\u00e1hradn\u00e9)"],
        ["VACUUM_STEAM_CLEANERS", "Vys\u00e1va\u010de, tepova\u010de a parn\u00e9 \u010disti\u010de"],
        ["IRONS", "\u017dehli\u010dky"],
        ["AIRCONDITIONERS", "Prenosn\u00e9 ohrieva\u010de a ventil\u00e1tory"],
        ["ICE_CREAM_MAKERS_YOGURT_MAKERS", "Zmrzlinova\u010de, jogurtova\u010de"],
        ["BOILER_RADIATOR", "Bojler, radi\u00e1tor"],
        ["BIO_ELECTRIC_FIREPLACE", "Biokrb, elektrick\u00fd krb"],
        ["OTHER_HOME_APPLIANCES", "In\u00e9"],
    ],
    p_brand: [
        d("AEG"),
        d("Amica"),
        d("Ariston"),
        d("Baumatic"),
        d("Beko"),
        d("BOSCH"),
        d("Braun"),
        d("Brita"),
        d("Electrolux"),
        d("ETA"),
        d("Fagor"),
        d("Gorenje"),
        d("Grundig"),
        d("Hyundai"),
        d("Ikea"),
        d("Indesit"),
        d("K\u00e4rcher"),
        d("Krups"),
        d("LG"),
        d("Mora"),
        d("Moulinex"),
        d("Orava"),
        d("Panasonic"),
        d("Philips"),
        d("Rowenta"),
        d("Samsung"),
        d("Sencor"),
        d("Siemens"),
        d("Silver Crest"),
        d("Sodastream"),
        d("Sony"),
        d("Tefal"),
        d("Whirlpool"),
        d("Zanussi"),
        d("Zelmer"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
