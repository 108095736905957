/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const adults_health_beauty : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["HAIR_COLOR", "Farba na vlasy"],
        ["HAIR_SHAMPOO", "\u0160amp\u00f3ny, vlasov\u00e1 kozmetika"],
        ["HAIR_STYLING", "Vlasov\u00fd styling"],
        ["MANICURE", "Manik\u00fara, pedik\u00fara"],
        ["MAKE_UP_DECORATIVE", "Dekorat\u00edvna kozmetika, make-up"],
        ["BODY_CARE", "Starostlivos\u0165 o telo"],
        ["FACE_CARE", "Starostlivos\u0165 o tv\u00e1r"],
        ["PARFUME", "Parf\u00e9m"],
        ["DEPILATION", "Depil\u00e1cia a holenie"],
        ["HYGIENE", "Hygiena"],
        ["COSMETIC_BAG", "Kufr\u00edk/kozmetick\u00e1 ta\u0161ti\u010dka"],
        ["MEDICAL_NEEDS", "Zdravotn\u00edcke potreby"],
        ["NUTRITIONAL_SUPPLEMENTS", "Vitam\u00edny, v\u00fd\u017eivov\u00e9 doplnky"],
        ["WIGS_AND_HAIRPIECES", "Parochne a pr\u00ed\u010desky"],
        ["PERSONAL_SCALES", "Osobn\u00e9 v\u00e1hy"],
        ["MASSAGES", "Mas\u00e1\u017ee"],
    ],
};
