/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const trolleys_trailers : {[key: string]: Array<[string, string]>} = {
    p_brand: [
        d("Agados"),
        d("Autovia"),
        d("Benalu"),
        d("BODEX"),
        d("BSS Metaco"),
        d("Eduard"),
        d("Fliegl"),
        d("Goldhofer"),
        d("K\u00e4ssbohrer"),
        d("K\u00f6gel"),
        d("Krone"),
        d("Nooteboom"),
        d("Panav"),
        d("Paragan"),
        d("Pongratz"),
        d("Schmitz"),
        d("Schwarzm\u00fcller"),
        d("Svan"),
        d("Vanhool"),
        d("Vapp"),
        d("Wielton"),
        d("Zremb"),
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
};
