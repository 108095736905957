/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp12 } from './commonProp12';
import { commonProp4 } from './commonProp4';

export const fashion_accessories_jewelry : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["RINGS", "Prstene"],
        ["EARRINGS", "N\u00e1u\u0161nice"],
        ["PIERCING", "Piercing"],
        ["BRACELETS", "N\u00e1ramok"],
        ["NECKLACES", "N\u00e1hrdeln\u00edk"],
        ["PENDANT", "Pr\u00edvesok"],
        ["BROOCHES", "Bro\u0161\u0148a"],
        ["JEWELRY_SET", "Kompletn\u00e1 sada"],
        ["JEWEL_CASE", "\u0160perkovnica"],
    ],
    p_sex: commonProp5,
    p_brand: commonProp12,
    p_color: commonProp4,
};
