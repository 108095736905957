/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp6: Array<[string, string]> = [
    d("Abercrombie&Fitch"),
    d("Active"),
    d("AD STYLE"),
    d("Adams"),
    d("Adidas"),
    d("Admiral"),
    d("Aeropostale"),
    d("Age"),
    d("Aigle"),
    d("AIR JORDAN"),
    d("AJS"),
    d("Alibi"),
    d("Alive"),
    d("Alles"),
    d("Alpine Pro"),
    d("Amaranto"),
    d("American Eagle"),
    d("Amisu"),
    d("Andrie"),
    d("Angie"),
    d("Animal"),
    d("Anita"),
    d("Antony"),
    d("Antony Morato"),
    d("Arex"),
    d("Arkano Collection"),
    d("Armani"),
    d("Artex"),
    d("ASICS"),
    d("Atmosphere"),
    d("Aubade"),
    d("Autex"),
    d("Authentic"),
    d("AUTHORITY"),
    d("Avon"),
    d("Baba Boo"),
    d("Babaluno"),
    d("Babeskin"),
    d("Baby"),
    d("Baby Club"),
    d("Baby Mac"),
    d("Baby Paradies"),
    d("Baby Service"),
    d("BabyKap"),
    d("Babyland"),
    d("BabyLegs"),
    d("Bajo"),
    d("Bambinex"),
    d("Bambini"),
    d("Bambino"),
    d("Bambino Mio"),
    d("Bandolera"),
    d("Barbie"),
    d("Basics"),
    d("Baty Fashion"),
    d("Bay"),
    d("Beautynight"),
    d("bebe"),
    d("Bee Bo"),
    d("Beebies"),
    d("Bellinda"),
    d("Bellissima"),
    d("Bench"),
    d("Benefit"),
    d("Benetton"),
    d("Benniny"),
    d("Benter"),
    d("Bershka"),
    d("Berti"),
    d("Bess"),
    d("Best Basics"),
    d("Betty Mode"),
    d("BHS"),
    d("Billabong"),
    d("Bise Bille"),
    d("Bj\u00e4llra"),
    d("Black Tulip"),
    d("Blue Base"),
    d("Bluezoo"),
    d("BoBo"),
    d("BoboBaby"),
    d("Bombshell"),
    d("Bonmarch\u00e9"),
    d("Bonnie Jean"),
    d("bonprix"),
    d("booso"),
    d("Boots"),
    d("Bout'chout"),
    d("Bows & Arrows"),
    d("Boys"),
    d("Brendon"),
    d("Broadway"),
    d("Broel"),
    d("Brubeck"),
    d("Brugi"),
    d("Brums"),
    d("bubbe"),
    d("Buffalo"),
    d("Burberry"),
    d("Burton"),
    d("Bushman"),
    d("B\u00e9b\u00e9 Cool"),
    d("C&A"),
    d("Cactus Clone"),
    d("CALLIOPE"),
    d("Calvin Klein"),
    d("Calzedonia"),
    d("Camaieu"),
    d("Candide"),
    d("Canpol Babies"),
    d("Carter's"),
    d("Champion"),
    d("CHANTALL"),
    d("Chantelle"),
    d("Chapter Young"),
    d("Charles V\u00f6gele"),
    d("Charming"),
    d("Cheese"),
    d("Cherokee"),
    d("chicca"),
    d("Chicco"),
    d("Children's Place"),
    d("Chilirose"),
    d("Chilled Chick"),
    d("Circo"),
    d("Clematis"),
    d("Clockhouse"),
    d("CMP"),
    d("Coccobello"),
    d("Coccodrillo"),
    d("Coemi"),
    d("Colins"),
    d("COLMAR"),
    d("Colours of the World"),
    d("Columbia"),
    d("Comet"),
    d("Converse"),
    d("CoolClub"),
    d("Coonoor"),
    d("Cornette"),
    d("COS"),
    d("CQ"),
    d("CRAFT"),
    d("Crashone"),
    d("Crivit"),
    d("Cubus"),
    d("Damini"),
    d("Daniela"),
    d("Debenhams"),
    d("Decathlon"),
    d("Denim"),
    d("DENIM Co"),
    d("Desigual"),
    d("Detex"),
    d("DEVERGO"),
    d("Diadora"),
    d("Diamond"),
    d("Didriksons1913"),
    d("Diesel"),
    d("Dim"),
    d("DIMO"),
    d("Dirkje"),
    d("Disana"),
    d("Disney"),
    d("Diva"),
    d("DKNY"),
    d("Dodipetto"),
    d("Dognose"),
    d("Domaja"),
    d("Domyos"),
    d("Donnay"),
    d("Dopodopo"),
    d("Dorothy Perkins"),
    d("DPam (Du Pareil Au Meme)"),
    d("DRAPS"),
    d("Dromedar"),
    d("ducKsday"),
    d("Dudeskin"),
    d("E-Vie"),
    d("Early Days"),
    d("Echt"),
    d("Ecko Unltd."),
    d("Ed Hardy"),
    d("ELLE"),
    d("Elo Melo"),
    d("Elodie Details"),
    d("Emily"),
    d("Emy"),
    d("envy"),
    d("Ergee"),
    d("ESITO"),
    d("esmara"),
    d("Esoline"),
    d("Esprit"),
    d("Essence"),
    d("eSTYLE"),
    d("Etam"),
    d("Ethel Austin"),
    d("Etirel"),
    d("Ever-Pretty"),
    d("Evie"),
    d("Evie Angel"),
    d("Evita"),
    d("Evona"),
    d("Ewa"),
    d("Ewident"),
    d("Exe"),
    d("EXIray"),
    d("Extra-Me"),
    d("EZAJO"),
    d("F&F"),
    d("F&G"),
    d("Fagottino"),
    d("Favab"),
    d("Feretti"),
    d("Figi"),
    d("Fila"),
    d("Fillikid"),
    d("FIREFLY"),
    d("Fishbone"),
    d("Fixoni"),
    d("Flexi"),
    d("Forest"),
    d("Forever Friends"),
    d("Fornarina"),
    d("Fox"),
    d("fox & bunny"),
    d("Fracomina"),
    d("Frog"),
    d("Funky Diva"),
    d("Funky Trunks"),
    d("Funny Bunny"),
    d("FUNSTORM"),
    d("G-STAR RAW"),
    d("Gaia"),
    d("Gaji"),
    d("Ganali"),
    d("GANT"),
    d("Gap"),
    d("Gate"),
    d("GAUDI"),
    d("gelert"),
    d("George"),
    d("GEOX"),
    d("Gerber"),
    d("GERRY WEBER"),
    d("Gina"),
    d("Girl2girl"),
    d("Glo-Story"),
    d("Glorious"),
    d("Gmini"),
    d("Golem"),
    d("Good Children"),
    d("Gorsenia"),
    d("GRACE"),
    d("Green Trees"),
    d("Guess"),
    d("Guga Jeans"),
    d("Gymboree"),
    d("H&M"),
    d("Handy"),
    d("Hanna Style"),
    d("Hannah"),
    d("Happy Rabbit"),
    d("Hasbro"),
    d("Helada"),
    d("Hello Kitty"),
    d("Helly Hansen"),
    d("HEMA"),
    d("Herma"),
    d("Hi-Tec"),
    d("Hollister"),
    d("Horsefeathers"),
    d("House"),
    d("Huber"),
    d("HUGO BOSS"),
    d("Hummel"),
    d("Husky"),
    d("Idexe"),
    d("iDO"),
    d("Ikea"),
    d("Impidimpi"),
    d("Imsevimse"),
    d("In Extenso"),
    d("Intimissimi"),
    d("iobio"),
    d("Items"),
    d("Ivemababy"),
    d("Jac"),
    d("Jac Baby"),
    d("Jacadi"),
    d("Jack Wolfskin"),
    d("Jacky"),
    d("Jane"),
    d("Janus"),
    d("Japitex"),
    d("Jayne Copeland"),
    d("Jean Bourget"),
    d("Jitex"),
    d("John Lewis"),
    d("John Lewis Baby"),
    d("JO\u017d\u00c1NEK"),
    d("Julius Z\u00f6llner"),
    d("Jumping Beans"),
    d("Just Play"),
    d("Just Too Cute"),
    d("JUUJ"),
    d("K&Y"),
    d("Kaarsgaren"),
    d("Kamulino"),
    d("Kanz"),
    d("Kappa"),
    d("KappAhl"),
    d("Kariban"),
    d("karrimor"),
    d("Kasto"),
    d("Katval"),
    d("Katyv Baby"),
    d("Kay"),
    d("Kenguru Gold"),
    d("Kenvelo"),
    d("KIDS"),
    d("Kids Club"),
    d("Kids Fashion"),
    d("Kid\u2019s Korner"),
    d("Kik"),
    d("Kiki&Koko"),
    d("Kilimanjaro"),
    d("Killah"),
    d("Kimbaloo"),
    d("Kings"),
    d("Kipsta"),
    d("Koala Baby"),
    d("Konrad"),
    d("Kookai"),
    d("Kostar"),
    d("Krisline"),
    d("Kugo"),
    d("Kuniboo"),
    d("L.A. Gear"),
    d("L.O.G.G."),
    d("La Senza"),
    d("Lacoste"),
    d("Lady Ann"),
    d("Ladybird"),
    d("Lafel"),
    d("Lama"),
    d("Lamido"),
    d("Laura Ashley"),
    d("Lazy Town"),
    d("Le Coq Sportif"),
    d("Lee"),
    d("Lee Cooper"),
    d("Lego"),
    d("Leilieve"),
    d("Lejaby"),
    d("Lemila"),
    d("Levis"),
    d("Lewro"),
    d("Lindex"),
    d("Lindy Bop"),
    d("Linia"),
    d("Lipsy London"),
    d("Litex"),
    d("Little Angel\u00ae"),
    d("Little Bundle"),
    d("Little People"),
    d("Little Star"),
    d("Little Things"),
    d("Liu Jo"),
    d("Livia Corsetti"),
    d("Loana"),
    d("Loap"),
    d("Lodger"),
    d("Lonsdale"),
    d("Look"),
    d("Lormar"),
    d("LOSAN"),
    d("Lotties"),
    d("Lotto"),
    d("LOUIS VUITTON"),
    d("lubime"),
    d("Lucky Lax"),
    d("lupilu"),
    d("Lupoline"),
    d("Lusa"),
    d("M&Co"),
    d("M. Elysee"),
    d("Mac"),
    d("Madonna"),
    d("Magnet"),
    d("Magrof"),
    d("Mamas & Papas"),
    d("Mammut"),
    d("Manai"),
    d("Mango"),
    d("Marc O\u00b4Polo"),
    d("Margita"),
    d("MARIKA"),
    d("Mariquita"),
    d("Marks & Spencer"),
    d("Marvel"),
    d("Matalan"),
    d("maybe4baby"),
    d("MAYO CHIX"),
    d("Mayoral"),
    d("McKenzie"),
    d("McKINLEY"),
    d("MET"),
    d("Mewa"),
    d("mexton"),
    d("Mexx"),
    d("MICHAEL KORS"),
    d("miLe"),
    d("Mills Baby"),
    d("Minene"),
    d("Mini Boden"),
    d("Mini Mode"),
    d("Miniclub"),
    d("Miniwear"),
    d("Minoti"),
    d("Minx"),
    d("Miran"),
    d("Mirau"),
    d("Miriale"),
    d("Miss Fiori"),
    d("Miss Selfridge"),
    d("Miss Sixty"),
    d("MK One"),
    d("Modelko"),
    d("MOHITO"),
    d("Monak"),
    d("Monnalisa"),
    d("Monsoon"),
    d("Morgan"),
    d("Morris Mouse"),
    d("MOSCHINO"),
    d("Mothercare"),
    d("Mountain Warehouse"),
    d("Mud Pie"),
    d("Muddy Puddles"),
    d("Muddyfox"),
    d("Mustang"),
    d("Muzzy"),
    d("My Little Bear"),
    d("Nabaiji"),
    d("Naf Naf"),
    d("Name it"),
    d("NAPAPIJRI"),
    d("Naturana"),
    d("Navigator"),
    d("NEBBIA"),
    d("Neckermann"),
    d("Neha"),
    d("Neverest"),
    d("NEW BABY"),
    d("New Balance"),
    d("New Look"),
    d("New Yorker"),
    d("newbie"),
    d("Next"),
    d("Nickelodeon"),
    d("Nike"),
    d("NKD"),
    d("No Fear"),
    d("Nolita Pocket"),
    d("Nordblanc"),
    d("NORTHFINDER\u00ae"),
    d("Nutmeg"),
    d("O'Neill"),
    d("Obaibi"),
    d("Obsessive"),
    d("Ocean Pacific"),
    d("Okay"),
    d("Old Navy"),
    d("One by One"),
    d("Only"),
    d("Ono"),
    d("Original Marines"),
    d("Orsay"),
    d("OshKosh"),
    d("Otto"),
    d("Outfit"),
    d("OVS"),
    d("O\u2019Neill"),
    d("Palmers"),
    d("Palomino"),
    d("Pampolina"),
    d("PANDA"),
    d("Papaya"),
    d("Parpro Mimi"),
    d("Passion"),
    d("Passionata"),
    d("Peanuts"),
    d("PEPCO"),
    d("Pepe Jeans"),
    d("Pepperts"),
    d("Petit Lulu"),
    d("Philip Russel"),
    d("Piazza Italia"),
    d("Pidilidi"),
    d("Pierre Cardin"),
    d("Pietro Filipi"),
    d("Pimkie"),
    d("Pinkie"),
    d("PINKO"),
    d("Pippa & Julie"),
    d("Pitter Patter"),
    d("PixyBaby"),
    d("Playboy"),
    d("Playshoes"),
    d("Pleas"),
    d("POMPdeLUX"),
    d("Port Louis"),
    d("PRADA"),
    d("Pretty girl"),
    d("Primark"),
    d("PRIMIGI"),
    d("Prince Oliver"),
    d("Princess"),
    d("Principles"),
    d("Promod"),
    d("Pr\u00e9maman"),
    d("Pr\u00e9natal"),
    d("Pull&Bear"),
    d("PUMA"),
    d("Pumpkin Patch"),
    d("Pupill"),
    d("Quechua"),
    d("Queen"),
    d("Quelle"),
    d("Quiksilver"),
    d("Rada"),
    d("Radetex"),
    d("Ralph Lauren"),
    d("Rappazin"),
    d("Rare Editions"),
    d("RDX"),
    d("Rebel"),
    d("Rebel Kids"),
    d("REDSTAR"),
    d("Reebok"),
    d("Reflex"),
    d("Refree"),
    d("Regatta"),
    d("Reima"),
    d("Rejoice"),
    d("Relax"),
    d("RELLECIGA"),
    d("Renka"),
    d("Repal"),
    d("Replay"),
    d("Reporter"),
    d("Reserved"),
    d("Resort"),
    d("Respect"),
    d("REVIEW"),
    d("Richelieu"),
    d("Rifle"),
    d("RIP CURL"),
    d("RIVER ISLAND"),
    d("Robert"),
    d("Rocha.Little.Rocha"),
    d("Rockino"),
    d("Roger"),
    d("Roly"),
    d("Rosalita Senoritas"),
    d("Rose"),
    d("Roxy"),
    d("S.A.M."),
    d("s.Oliver"),
    d("SALEWA"),
    d("Salomon"),
    d("Sam73"),
    d("Sam74"),
    d("Sando"),
    d("Sanetta"),
    d("Sanrio"),
    d("Sariana"),
    d("scamp"),
    d("Scarlett"),
    d("Schiesser"),
    d("Schnizler"),
    d("Secret"),
    d("Secret Possessions"),
    d("Select"),
    d("Sensor"),
    d("Sergent Major"),
    d("Setino"),
    d("Sherpa"),
    d("Sielei"),
    d("Simone"),
    d("sinsay"),
    d("SISLEY"),
    d("Skapi"),
    d("Skiny"),
    d("SKOGSTAD"),
    d("Slazenger"),
    d("Sleep Wear"),
    d("Slight"),
    d("SLOVENKA"),
    d("Smafolk"),
    d("Smash!"),
    d("Smile"),
    d("Snoopy"),
    d("Soccx"),
    d("Sofija"),
    d("Soft Touch"),
    d("Sol's"),
    d("Solla"),
    d("South"),
    d("Speedo"),
    d("Splash About"),
    d("St Michael"),
    d("St. Bernard"),
    d("St. Michael"),
    d("STACCATO"),
    d("Sterntaler"),
    d("Stormberg"),
    d("Stradivarius"),
    d("Street One"),
    d("Success"),
    d("Sucre d'Orge"),
    d("Sugar Pink"),
    d("Sugarbird"),
    d("Superdry"),
    d("Sweet Baby"),
    d("T&Go"),
    d("Tajpex"),
    d("Takko"),
    d("Tally Weijl"),
    d("Taro"),
    d("TATRASVIT"),
    d("Tchibo"),
    d("TCM"),
    d("Teeny Tiny"),
    d("Terranova"),
    d("Terykon"),
    d("Tesco"),
    d("Texbasic"),
    d("Tezenis"),
    d("The Gro Company (Grobag)"),
    d("Tik & Tak"),
    d("Timberland"),
    d("Timbuktoo"),
    d("Timeout"),
    d("Timo"),
    d("Tinko"),
    d("Tiny Ted"),
    d("Tom Tailor"),
    d("Tommy Hilfiger"),
    d("Tony Trevis"),
    d("Topolino"),
    d("Topomini"),
    d("Topshop"),
    d("Track&Field"),
    d("Tribord"),
    d("Triola"),
    d("Triumph"),
    d("TROLLKIDS"),
    d("TU"),
    d("tuc tuc"),
    d("Tulec Trend"),
    d("Tup Tup"),
    d("TWINSET"),
    d("U.S. POLO ASSN."),
    d("UAX"),
    d("Umbro"),
    d("Unbra"),
    d("UNDER ARMOUR"),
    d("unuo"),
    d("Urban Rascals"),
    d("US Angels"),
    d("USA PRO"),
    d("Vamp"),
    d("Vandenberg"),
    d("Vanvaan"),
    d("Veba"),
    d("Vero Moda"),
    d("Versace"),
    d("Vertbaudet"),
    d("Victoria's Secret"),
    d("Vienetta Kids"),
    d("Vienetta Secret"),
    d("Vingino"),
    d("Vl\u010dek"),
    d("Von Dutch"),
    d("Wallis"),
    d("Warehouse"),
    d("Wedze"),
    d("Weiser"),
    d("Wenice"),
    d("Werso"),
    d("William & Delvin"),
    d("Wojcik"),
    d("Wolf"),
    d("Women'Secret"),
    d("WOXX"),
    d("Wrangler"),
    d("Yessica"),
    d("Yetty"),
    d("YIGGA"),
    d("Yorker"),
    d("Young Dimension"),
    d("Yumi"),
    d("Zaffe"),
    d("ZAJO"),
    d("Zara"),
    d("Zip Zap"),
    ["HANDMADE", "Handmade"],
    ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
];
