/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp14 } from './commonProp14';
import { commonProp4 } from './commonProp4';

export const horseback_riding_footwear : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp14,
    p_brand: [
        d("Cavallo"),
        d("Covalliero"),
        d("Daslo"),
        d("DeNiro BootCo"),
        d("Euroriding"),
        d("FreeJump"),
        d("HKM"),
        d("Horze"),
        d("Kavalkade"),
        d("Muck"),
        d("Muck Boot Company"),
        d("Penelope"),
        d("Sergio Grasso"),
        d("Smell Well"),
        d("Tattini"),
        d("Veredus"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
