/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp16: Array<[string, string]> = [
    ["FOR_KIDS", "Pre deti"],
    ["FOR_ADULTS", "Pre dospel\u00fdch"],
];
