/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp4 } from './commonProp4';

export const twins : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["UNDERWEAR", "Spodn\u00e1 bielize\u0148"],
        ["SWIMWEAR_DRESSING_GOWNS", "Plavky, \u017eupany"],
        ["SOCKS_TIGHTS", "Pan\u010du\u0161ky, pono\u017eky, n\u00e1vleky"],
        ["BODYSUITS_SLEEPSUITS_ROMPERS", "Dupa\u010dky, body"],
        ["TSHIRTS_TOPS", "Tri\u010dk\u00e1, tielka (nie spodn\u00e1 bielize\u0148)"],
        ["BLOUSE_SHIRTS", "Ko\u0161ele, bl\u00fazky"],
        ["DRESSES_SKIRTS", "Sukni\u010dky, \u0161ati\u010dky"],
        ["TROUSERS_JEANS_JOGGERS", "Nohavice, rifle, tepl\u00e1ky"],
        ["SHORTS_CROPS", "\u0160ortky, tri\u0161tvr\u0165\u00e1ky"],
        ["OUTFITS", "Komplety, s\u00fapravy (nie zimn\u00e9)"],
        ["SWEATSHIRTS_JUMPERS_VESTS", "Mikiny, svetre, bolerk\u00e1"],
        ["JACKETS_COATS", "Bundy, kab\u00e1ty, sak\u00e1"],
        ["SNOWSUITS", "Zimn\u00e9 kombin\u00e9zy a otep\u013eova\u010dky"],
        ["HATS_MITTENS_SCARVES", "\u010ciapky, rukavice, \u0161\u00e1ly"],
        ["SLEEPWEAR", "Py\u017eam\u00e1"],
        ["SLEEPING_BAGS", "Spacie vaky"],
        ["FOOTWEAR", "Obuv"],
        ["MEGAPACKS", "Bal\u00edk oble\u010denia"],
    ],
    p_size: [
        d("<50"),
        d("50"),
        d("56"),
        d("62"),
        d("68"),
        d("74"),
        d("80"),
        d("86"),
        d("92"),
        d("98"),
        d("104"),
        d("110"),
        d("116"),
        d("122"),
        d("128"),
        d("134"),
        d("140"),
        d("146"),
        d("152"),
        d("158"),
        d("164"),
        d("170"),
        d("176"),
        d("182"),
        d("188"),
        d("17"),
        d("18"),
        d("19"),
        d("20"),
        d("21"),
        d("22"),
        d("23"),
        d("24"),
        d("25"),
        d("26"),
        d("27"),
        d("28"),
        d("29"),
        d("30"),
        d("31"),
        d("32"),
        d("33"),
        d("34"),
        d("35"),
        d("36"),
        d("37"),
        d("38"),
        d("39"),
        d("40"),
    ],
    p_sex_twin: [
        ["GIRLS", "Pre diev\u010dat\u00e1"],
        ["BOYS", "Pre chlapcov"],
        ["GIRLBOY", "Chlapec a diev\u010da"],
    ],
    p_color: commonProp4,
};
