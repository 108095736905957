/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const accommodation_rental : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["FLAT_1+KK", "Byt 1+kk a 1+1"],
        ["FLAT_2+KK", "Byt 2+kk a 2+1"],
        ["FLAT_3+KK", "Byt 3+kk a 3+1"],
        ["FLAT_4+KK", "Byt 4+kk a 4+1"],
        ["FLAT_5+KK", "Byt 5+kk a v\u00e4\u010d\u0161\u00ed"],
        ["ROOM", "Izba"],
        ["HOUSE", "Dom"],
        ["COTTAGE", "Chata, chalupa"],
        ["GARDEN", "Z\u00e1hrada"],
        ["LAND", "Pozemok"],
        ["GARAGE", "Gar\u00e1\u017e, diel\u0148a"],
        ["WAREHOUSE", "Komer\u010dn\u00e9 priestory, sklady"],
    ],
};
