/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp12: Array<[string, string]> = [
    d("Abercrombie&Fitch"),
    d("ABOUT YOU"),
    d("Adidas"),
    d("Aldo"),
    d("Alexander Wang"),
    d("Ammyla"),
    d("Anekke"),
    d("ANNA GRACE"),
    d("ANSWEAR"),
    d("Apple"),
    d("Armani"),
    d("ASTRA POLSKA"),
    d("Atmosphere"),
    d("AUTHORITY"),
    d("Avon"),
    d("Baby Banz"),
    d("BAGGER"),
    d("Baldinini"),
    d("BALENCIAGA"),
    d("Ba\u0165a"),
    d("Bella Belly"),
    d("Benetton"),
    d("BERING"),
    d("Bershka"),
    d("Betty Barclay"),
    d("Bijou Brigitte"),
    d("Bogner"),
    d("bonprix"),
    d("Burberry"),
    d("BURTON"),
    d("BVLGARI"),
    d("C&A"),
    d("Calvin Klein"),
    d("Camaieu"),
    d("CARPISA"),
    d("Casio"),
    d("CCC"),
    d("CELINE"),
    d("CELINE DION"),
    d("Cerda"),
    d("CHANEL"),
    d("CHANTALL"),
    d("Chicco"),
    d("Childhome"),
    d("Chlo\u00e9"),
    d("Christian Audigier"),
    d("Citizen"),
    d("COACH"),
    d("COCCINELLE"),
    d("Converse"),
    d("COS"),
    d("cromia"),
    d("CROPP"),
    d("Dajana Rodriguez"),
    d("DAVID JONES"),
    d("Deichmann"),
    d("Desigual"),
    d("DIANA&CO"),
    d("Diesel"),
    d("Dior"),
    d("Disney"),
    d("DJECO"),
    d("DKNY"),
    d("Dolce&Gabbana"),
    d("Dorothy Perkins"),
    d("dudlin"),
    d("ECCO"),
    d("Ed Hardy"),
    d("ellesse"),
    d("Elodie Details"),
    d("Envirosax"),
    d("ESCADA"),
    d("Esprit"),
    d("EUROSWAN"),
    d("F&F"),
    d("farfalla"),
    d("FENDI"),
    d("FESTINA"),
    d("Fila"),
    d("FIORANGELO"),
    d("Fishbone"),
    d("FJ\u00c4LLR\u00c4VEN"),
    d("FLORA&CO"),
    d("Fossil"),
    d("FRACOMINA"),
    d("FURLA"),
    d("G-STAR RAW"),
    d("GANT"),
    d("Gap"),
    d("Garmin"),
    d("GAS"),
    d("Gate"),
    d("GAUDI"),
    d("Geneva"),
    d("George"),
    d("GIANFRANCO FERRE"),
    d("GINO ROSSI"),
    d("GIVENCHY"),
    d("GREGORIO"),
    d("GROSSO"),
    d("Gucci"),
    d("Guess"),
    d("GUSSACI"),
    d("Gymboree"),
    d("H&M"),
    d("Haloo"),
    d("HERM\u00c9S"),
    d("Herschel"),
    d("House"),
    d("HUGO BOSS"),
    d("ICE watch"),
    d("IVANKA TRUMP"),
    d("JACQUEMUS"),
    d("Jenny Fairy"),
    d("JIMMY CHOO"),
    d("John Galliano"),
    d("JOOP!"),
    d("JOUMMA BAGS"),
    d("Juicy Couture"),
    d("JU\u00b4STO"),
    d("KARACTERMANIA"),
    d("KARL LAGERFELD"),
    d("kate spade"),
    d("Kenvelo"),
    d("KENZO"),
    d("KiETLA"),
    d("Kik"),
    d("Kindz Banz"),
    d("KIPLING"),
    d("KURA"),
    d("LA MARTINA"),
    d("Lacoste"),
    d("Laken"),
    d("LARA BAGS"),
    d("Lasocki"),
    d("LEVI\u2019S"),
    d("LIEBESKIND"),
    d("Lindex"),
    d("Little People"),
    d("Liu Jo"),
    d("LOCCATI"),
    d("Lonsdale"),
    d("LOUIS VUITTON"),
    d("Love Moschino"),
    d("MADISON NEW YORK"),
    d("Mango"),
    d("Marc Cain"),
    d("Marc Jacobs"),
    d("Marco Picard"),
    d("Marco Polo"),
    d("Marks & Spencer"),
    d("MARY KAY"),
    d("Max Mara"),
    d("MAYO CHIX"),
    d("MCM"),
    d("MEDICINE"),
    d("Metoo"),
    d("Michael Kors"),
    d("MICUSSI"),
    d("Mirau"),
    d("Miss Lulu"),
    d("Miss Sixty"),
    d("Miu Miu"),
    d("MOHITO"),
    d("Monart"),
    d("Monsoon"),
    d("MORELLATO"),
    d("MOSCHINO"),
    d("Mulberry"),
    d("NAPAPIJRI"),
    d("NEW BERRY"),
    d("New Look"),
    d("New Yorker"),
    d("newbie"),
    d("Next"),
    d("NICI"),
    d("Nike"),
    d("NINE WEST"),
    d("Nobo"),
    d("NYMPH"),
    d("O bag"),
    d("O'Neill"),
    d("Opravi\u0165 Xiaom na Xiaomi"),
    d("Oriflame"),
    d("Orsay"),
    d("PANDORA"),
    d("Paris Hilton"),
    d("Patricia Pepe"),
    d("Patrizia Piu"),
    d("Paul's Boutique"),
    d("PEPCO"),
    d("Pepe Jeans"),
    d("PERLETTI"),
    d("PETRA TOTH"),
    d("PICARD"),
    d("PIECES"),
    d("Pierre Cardin"),
    d("PINKO"),
    d("PINOKIO"),
    d("Playboy"),
    d("Polaroid"),
    d("PRADA"),
    d("Prestige"),
    d("Primark"),
    d("Promod"),
    d("Pull&Bear"),
    d("PUMA"),
    d("PUNCE"),
    d("Quartz"),
    d("Quiksilver"),
    d("RADLEY LONDON"),
    d("Ralph Lauren"),
    d("Ray-Ban"),
    d("Rebecca Minkoff"),
    d("Reebok"),
    d("Replay"),
    d("Reserved"),
    d("RETRO JEANS"),
    d("Rieker"),
    d("RINASCIMENTO"),
    d("RIVER ISLAND"),
    d("Roberto Cavalli"),
    d("roccobarocco"),
    d("ROLEX"),
    d("Rovicky"),
    d("Roxy"),
    d("s.Oliver"),
    d("Safta"),
    d("SALAMANDER"),
    d("Samsonite"),
    d("Samsung"),
    d("SANTORO"),
    d("SAPPHIRE"),
    d("SECCO"),
    d("SEIKO"),
    d("Selena"),
    d("Settino"),
    d("SILVIAN HEACH"),
    d("sinsay"),
    d("Sisley"),
    d("SKIP HOP"),
    d("SKYLINE"),
    d("Stella McCartney"),
    d("STEVE MADDEN"),
    d("Stradivarius"),
    d("Superdry"),
    d("Swarovski"),
    d("Takko"),
    d("Tally Weijl"),
    d("Tamaris"),
    d("Tchibo"),
    d("TED BAKER"),
    d("Terranova"),
    d("TESSRA"),
    d("Thomas Sabo"),
    d("Tissot"),
    d("Tod\u2019s"),
    d("TOM FORD"),
    d("Tom Tailor"),
    d("Tommy Hilfiger"),
    d("Tory Burch"),
    d("TOSCA BLU"),
    d("TOUS"),
    d("Trunki"),
    d("TRUSSARDI"),
    d("TWINSET"),
    d("TY"),
    d("U.S. POLO ASSN."),
    d("UGG"),
    d("VALENTINI"),
    d("VALENTINO"),
    d("Vans"),
    d("Vero Moda"),
    d("Versace"),
    d("vertbaudet"),
    d("Victoria's Secret"),
    d("Vivienne Westwood"),
    d("Vogue"),
    d("Wayuu"),
    d("WINGS"),
    d("Wojas"),
    d("Xiaom"),
    d("Yves Saint Laurent"),
    d("ZAC Zac Posen"),
    d("Zara"),
    d("ZOOT"),
    ["HANDMADE", "Handmade"],
    ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
];
