/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp22 } from './commonProp22';

export const caretaking : {[key: string]: Array<[string, string]>} = {
    p_practice_length: commonProp22,
    p_frequency: [
        ["IRREGULARLY", "Nepravidelne"],
        ["EVERY_DAY", "Ka\u017ed\u00fd de\u0148"],
        ["ONCE_TWICE_PER_WEEK", "1-2x za t\u00fd\u017ede\u0148"],
        ["OTHER_FREQUENCY", "In\u00e1"],
    ],
};
