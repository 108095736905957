/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const hiking_sleeping_bags : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_brand: [
        d("acra"),
        d("alpine pro"),
        d("boll"),
        d("brother"),
        d("cattara"),
        d("coleman"),
        d("crossroad"),
        d("delphin"),
        d("everett"),
        d("ferrino"),
        d("hannah"),
        d("head"),
        d("high peak"),
        d("husky"),
        d("loap"),
        d("marmot"),
        d("mc kinley"),
        d("mountain equipment"),
        d("outwell"),
        d("patizon"),
        d("ratikon"),
        d("robins"),
        d("spokey"),
        d("the north face"),
        d("vango"),
        d("vaude"),
        d("warmpeace"),
        d("zulu"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
