// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const STROLLER_FEATURE_PRODUCT_IS_NO_LONGER_SOLD = "V\u00fdroba ukon\u010den\u00e1";
export const STROLLER_FEATURE_PRODUCT_IS_NO_LONGER_SOLD_SINCE_date = "V\u00fdroba ukon\u010den\u00e1 %(date)s";
export const STROLLER_RATING_1 = "Katastrofa";
export const STROLLER_RATING_10 = "Dokonal\u00fd";
export const STROLLER_RATING_2 = "Hrozn\u00fd";
export const STROLLER_RATING_3 = "Zl\u00fd";
export const STROLLER_RATING_4 = "Podpriemern\u00fd";
export const STROLLER_RATING_5 = "Akceptovate\u013en\u00fd";
export const STROLLER_RATING_6 = "Ujde";
export const STROLLER_RATING_7 = "Dobr\u00fd";
export const STROLLER_RATING_8 = "Ve\u013emi dobr\u00fd";
export const STROLLER_RATING_9 = "Skvel\u00fd";
