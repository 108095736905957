/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp4 } from './commonProp4';

export const home_furniture : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["KITCHEN_CABINETS", "Kuchynsk\u00e9 skrinky a linky"],
        ["DINING_TABLES_CHAIRS", "Jed\u00e1lensk\u00e9 stoly a stoli\u010dky"],
        ["BAR_TABLES_CAHIRS", "Barov\u00e9 stoly a stoli\u010dky"],
        ["SOFAS_ARMCHAIRS", "Gau\u010de, pohovky a kresl\u00e1"],
        ["LIVING_ROOM_TV_FURNITURE", "Ob\u00fdvacia stena, n\u00e1bytok na TV a m\u00e9dia"],
        ["COFFEE_SIDE_TABLES", "Konfere\u010dn\u00e9 a pr\u00edru\u010dn\u00e9 stol\u00edky"],
        ["BOOKCASES_SHELVES", "Kni\u017enice, police, vitr\u00edny"],
        ["CHESTS_OF_DRAWERS", "Komody"],
        ["WARDROBES", "Skrine na \u0161aty"],
        ["BEDS_MATTRESSES", "N\u00e1bytok do sp\u00e1lne"],
        ["BATHROOM_CABINTES", "N\u00e1bytok do k\u00fape\u013ene"],
        ["OFFICE_TABLES_CHAIRS", "N\u00e1bytok do pracovne"],
        ["HALLWAY_FURNITURE", "N\u00e1bytok do predsiene"],
        ["GARDEN_FURNITURE", "N\u00e1bytok na balk\u00f3n, terasu"],
        ["OTHER_FURNITURE", "In\u00e9"],
    ],
    p_color: commonProp4,
};
