/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp8 } from './commonProp8';
import { commonProp4 } from './commonProp4';

export const pregnancy_clothing : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["PREGNANCY_UNDERWEAR", "Spodn\u00e1 bielize\u0148"],
        ["ADULTS_TIGHTS", "Pan\u010duchy"],
        ["TSHIRTS_TOPS", "Tri\u010dk\u00e1, tielka (nie spodn\u00e1 bielize\u0148)"],
        ["TUNIC", "Ko\u0161ele, bl\u00fazky"],
        ["SKIRTS", "Sukne"],
        ["DRESSES", "\u0160aty"],
        ["TROUSERS", "Nohavice"],
        ["JEANS", "Rifle"],
        ["JOGGERS", "Tepl\u00e1ky"],
        ["SHORTS", "\u0160ortky"],
        ["CROPS", "Tri\u0161tvr\u0165\u00e1ky"],
        ["SWEATSHIRT", "Mikina"],
        ["KNITWEAR", "Sveter, pul\u00f3ver"],
        ["PYJAMAS", "Dvojdielne py\u017eamo"],
        ["NIGHTIES", "No\u010dn\u00e9 ko\u0161ele"],
        ["OUTFITS", "Komplety, s\u00fapravy (nie zimn\u00e9)"],
        ["JACKETS", "Bundy, vetrovky"],
        ["COATS", "Kab\u00e1ty, sak\u00e1"],
        ["SWIMWEAR", "Plavky"],
        ["MEGAPACKS", "Bal\u00edk oble\u010denia"],
        ["LEGGINGS", "Leg\u00edny"],
    ],
    p_size: [
        d("XS"),
        d("S"),
        d("M"),
        d("L"),
        d("XL"),
        d("XXL"),
        d("XXXL"),
        d("4XL"),
        d("5XL"),
        d("24"),
        d("26"),
        d("28"),
        d("30"),
        d("32"),
        d("34"),
        d("35"),
        d("36"),
        d("37"),
        d("38"),
        d("39"),
        d("40"),
        d("41"),
        d("42"),
        d("43"),
        d("44"),
        d("45"),
        d("46"),
        d("47"),
        d("48"),
        d("49"),
        d("50"),
        d("52"),
        d("54"),
        d("56"),
        d("58"),
        d("60"),
        d("62"),
        ["ONE_SIZE_FIT_ALL", "Univerz\u00e1lna ve\u013ekos\u0165"],
    ],
    p_style: commonProp8,
    p_brand: [
        d("Abercrombie&Fitch"),
        d("AD STYLE"),
        d("Adams"),
        d("Adidas"),
        d("Admiral"),
        d("Aeropostale"),
        d("Aigle"),
        d("AIR JORDAN"),
        d("Alles"),
        d("Alpine Pro"),
        d("Amaranto"),
        d("American Eagle"),
        d("Amisu"),
        d("Andrie"),
        d("Angie"),
        d("Animal"),
        d("Anita"),
        d("Antony Morato"),
        d("Arkano Collection"),
        d("Armani"),
        d("ASICS"),
        d("Atmosphere"),
        d("Aubade"),
        d("Authentic"),
        d("AUTHORITY"),
        d("Avon"),
        d("Bandolera"),
        d("Basics"),
        d("Baty Fashion"),
        d("Bay"),
        d("Beautynight"),
        d("bebe"),
        d("Bellinda"),
        d("Bellissima"),
        d("Bench"),
        d("Benefit"),
        d("Benetton"),
        d("Benter"),
        d("Bershka"),
        d("Betty Mode"),
        d("BHS"),
        d("Billabong"),
        d("Black Tulip"),
        d("Bombshell"),
        d("Bonmarch\u00e9"),
        d("bonprix"),
        d("Broadway"),
        d("Brubeck"),
        d("Buffalo"),
        d("Burberry"),
        d("Burton"),
        d("Bushman"),
        d("C&A"),
        d("Cactus Clone"),
        d("Calvin Klein"),
        d("Calzedonia"),
        d("Camaieu"),
        d("Canpol Babies"),
        d("Champion"),
        d("CHANTALL"),
        d("Chantelle"),
        d("Charming"),
        d("Cheese"),
        d("Cherokee"),
        d("chicca"),
        d("Chilirose"),
        d("Clematis"),
        d("Clockhouse"),
        d("Coemi"),
        d("Colins"),
        d("COLMAR"),
        d("Colours of the World"),
        d("Columbia"),
        d("Comet"),
        d("Converse"),
        d("Coonoor"),
        d("Cornette"),
        d("COS"),
        d("CRAFT"),
        d("Crivit"),
        d("Cubus"),
        d("Daniela"),
        d("Denim"),
        d("Desigual"),
        d("Diadora"),
        d("Diamond"),
        d("Didriksons1913"),
        d("Diesel"),
        d("Dim"),
        d("Disney"),
        d("Diva"),
        d("DKNY"),
        d("Domyos"),
        d("Dorothy Perkins"),
        d("DRAPS"),
        d("Dromedar"),
        d("E-Vie"),
        d("Echt"),
        d("Ecko Unltd."),
        d("Ed Hardy"),
        d("Emily"),
        d("envy"),
        d("esmara"),
        d("Esoline"),
        d("Esprit"),
        d("Essence"),
        d("Etam"),
        d("Ethel Austin"),
        d("Ever-Pretty"),
        d("Evie"),
        d("Evita"),
        d("Ewident"),
        d("Exe"),
        d("EXIray"),
        d("EZAJO"),
        d("F&F"),
        d("Favab"),
        d("Fila"),
        d("Fishbone"),
        d("Forest"),
        d("Forever Friends"),
        d("Fornarina"),
        d("Fox"),
        d("Fracomina"),
        d("Frog"),
        d("Funky Diva"),
        d("Funky Trunks"),
        d("FUNSTORM"),
        d("G-STAR RAW"),
        d("Gaia"),
        d("GANT"),
        d("Gap"),
        d("Gate"),
        d("GAUDI"),
        d("gelert"),
        d("George"),
        d("GERRY WEBER"),
        d("Gina"),
        d("Glo-Story"),
        d("Gorsenia"),
        d("Green Trees"),
        d("Guess"),
        d("Guga Jeans"),
        d("H&M"),
        d("Handy"),
        d("Hanna Style"),
        d("Hannah"),
        d("Happy Rabbit"),
        d("Hello Kitty"),
        d("Helly Hansen"),
        d("Hi-Tec"),
        d("Hollister"),
        d("Horsefeathers"),
        d("House"),
        d("Huber"),
        d("Husky"),
        d("Intimissimi"),
        d("Items"),
        d("Jack Wolfskin"),
        d("Jane"),
        d("Jitex"),
        d("Kappa"),
        d("KappAhl"),
        d("Kariban"),
        d("karrimor"),
        d("Kasto"),
        d("Kenvelo"),
        d("Kik"),
        d("Kilimanjaro"),
        d("Killah"),
        d("Konrad"),
        d("Kookai"),
        d("Kostar"),
        d("Krisline"),
        d("L.A. Gear"),
        d("La Senza"),
        d("Lacoste"),
        d("Lamido"),
        d("Laura Ashley"),
        d("Lee"),
        d("Lee Cooper"),
        d("Le Coq Sportif"),
        d("Leilieve"),
        d("Lejaby"),
        d("Lemila"),
        d("Levis"),
        d("Lindex"),
        d("Lindy Bop"),
        d("Linia"),
        d("Lipsy London"),
        d("Litex"),
        d("Little Angel\u00ae"),
        d("Liu Jo"),
        d("Livia Corsetti"),
        d("Loana"),
        d("Loap"),
        d("Lonsdale"),
        d("Look"),
        d("Lormar"),
        d("Lotto"),
        d("LOUIS VUITTON"),
        d("Lupoline"),
        d("M&Co"),
        d("M. Elysee"),
        d("Madonna"),
        d("Magnet"),
        d("Mammut"),
        d("Mango"),
        d("Marc O\u00b4Polo"),
        d("Margita"),
        d("Marks & Spencer"),
        d("Matalan"),
        d("McKenzie"),
        d("MET"),
        d("Mewa"),
        d("mexton"),
        d("Mexx"),
        d("miLe"),
        d("Miran"),
        d("Miriale"),
        d("Miss Fiori"),
        d("Miss Selfridge"),
        d("Miss Sixty"),
        d("MK One"),
        d("MOHITO"),
        d("Morgan"),
        d("Mothercare"),
        d("Muddyfox"),
        d("Mustang"),
        d("Muzzy"),
        d("Naf Naf"),
        d("NAPAPIJRI"),
        d("Naturana"),
        d("Neha"),
        d("New Balance"),
        d("New Look"),
        d("New Yorker"),
        d("Next"),
        d("Nike"),
        d("No Fear"),
        d("Nordblanc"),
        d("NORTHFINDER\u00ae"),
        d("O\u2019Neill"),
        d("Obsessive"),
        d("Ocean Pacific"),
        d("Old Navy"),
        d("Only"),
        d("Okay"),
        d("Orsay"),
        d("Otto"),
        d("Outfit"),
        d("OVS"),
        d("Palmers"),
        d("Papaya"),
        d("Passion"),
        d("Passionata"),
        d("Pepe Jeans"),
        d("Philip Russel"),
        d("Piazza Italia"),
        d("Pierre Cardin"),
        d("Pietro Filipi"),
        d("Pimkie"),
        d("PINKO"),
        d("Playboy"),
        d("Pleas"),
        d("Port Louis"),
        d("PRADA"),
        d("Primark"),
        d("Princess"),
        d("Principles"),
        d("Promod"),
        d("Pull&Bear"),
        d("PUMA"),
        d("Quelle"),
        d("Quiksilver"),
        d("Ralph Lauren"),
        d("Rebel"),
        d("REDSTAR"),
        d("Reebok"),
        d("Refree"),
        d("Regatta"),
        d("Rejoice"),
        d("RELLECIGA"),
        d("Replay"),
        d("Reserved"),
        d("Resort"),
        d("Respect"),
        d("Rifle"),
        d("Rip Curl"),
        d("RIVER ISLAND"),
        d("Roger"),
        d("Roly"),
        d("Roxy"),
        d("S.A.M."),
        d("s.Oliver"),
        d("SALEWA"),
        d("Salomon"),
        d("Sam73"),
        d("Sam74"),
        d("Sando"),
        d("Sariana"),
        d("Schiesser"),
        d("Secret"),
        d("Secret Possessions"),
        d("Select"),
        d("Sensor"),
        d("Setino"),
        d("Sherpa"),
        d("Sielei"),
        d("Simone"),
        d("Sisley"),
        d("Skiny"),
        d("Slazenger"),
        d("Sleep Wear"),
        d("Slight"),
        d("SLOVENKA"),
        d("Smash!"),
        d("Snoopy"),
        d("Soccx"),
        d("Sol's"),
        d("South"),
        d("Speedo"),
        d("St. Bernard"),
        d("St. Michael"),
        d("Stormberg"),
        d("Stradivarius"),
        d("Street One"),
        d("Sugarbird"),
        d("Takko"),
        d("Tally Weijl"),
        d("Taro"),
        d("TCM"),
        d("Terranova"),
        d("Terykon"),
        d("Tesco"),
        d("Texbasic"),
        d("Tezenis"),
        d("Timberland"),
        d("Timbuktoo"),
        d("Timeout"),
        d("Timo"),
        d("Tom Tailor"),
        d("Tommy Hilfiger"),
        d("Tony Trevis"),
        d("Topolino"),
        d("Topshop"),
        d("Tribord"),
        d("Triola"),
        d("Triumph"),
        d("TWINSET"),
        d("UAX"),
        d("Umbro"),
        d("Unbra"),
        d("UNDER ARMOUR"),
        d("USA PRO"),
        d("Vamp"),
        d("Vandenberg"),
        d("Vanvaan"),
        d("Veba"),
        d("Vero Moda"),
        d("Versace"),
        d("Victoria's Secret"),
        d("Vienetta Secret"),
        d("Von Dutch"),
        d("Wallis"),
        d("Warehouse"),
        d("Werso"),
        d("William & Delvin"),
        d("Wedze"),
        d("Wolf"),
        d("Women'Secret"),
        d("WOXX"),
        d("Wrangler"),
        d("Yessica"),
        d("Yumi"),
        d("Zaffe"),
        d("Zara"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
