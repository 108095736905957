/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp3: Array<[string, string]> = [
    d("Abercrombie&Fitch"),
    d("Active"),
    d("Adams"),
    d("Adidas"),
    d("Age"),
    d("AIR JORDAN"),
    d("AJS"),
    d("Alibi"),
    d("Alive"),
    d("Alpine Pro"),
    d("Animal"),
    d("Antony"),
    d("Antony Morato"),
    d("Arex"),
    d("Armani"),
    d("Artex"),
    d("ASICS"),
    d("Autex"),
    d("Authority"),
    d("Baba Boo"),
    d("Babaluno"),
    d("Babeskin"),
    d("Baby"),
    d("Baby Club"),
    d("Baby Mac"),
    d("Baby Paradies"),
    d("Baby Service"),
    d("BabyKap"),
    d("Babyland"),
    d("BabyLegs"),
    d("Bajo"),
    d("Bambinex"),
    d("Bambini"),
    d("Bambino"),
    d("Bambino Mio"),
    d("Barbie"),
    d("Basics"),
    d("Bee Bo"),
    d("Beebies"),
    d("Bellinda"),
    d("Bench"),
    d("Benetton"),
    d("Benniny"),
    d("Benter"),
    d("Berti"),
    d("Bess"),
    d("Best Basics"),
    d("Betty Mode"),
    d("BHS"),
    d("Billabong"),
    d("Bise Bille"),
    d("Bj\u00e4llra"),
    d("Blue Base"),
    d("Bluezoo"),
    d("BoBo"),
    d("BoboBaby"),
    d("Bonnie Jean"),
    d("bonprix"),
    d("booso"),
    d("Boots"),
    d("Bout'chout"),
    d("Bows & Arrows"),
    d("Boys"),
    d("Brendon"),
    d("Broel"),
    d("Brugi"),
    d("Brums"),
    d("bubbe"),
    d("Burberry"),
    d("Burton"),
    d("B\u00e9b\u00e9 Cool"),
    d("C&A"),
    d("Cactus Clone"),
    d("CALLIOPE"),
    d("Calvin Klein"),
    d("Calzedonia"),
    d("Candide"),
    d("Carter's"),
    d("Champion"),
    d("Chapter Young"),
    d("Charles V\u00f6gele"),
    d("Cherokee"),
    d("Chicco"),
    d("Children's Place"),
    d("Chilled Chick"),
    d("Circo"),
    d("CMP"),
    d("Coccobello"),
    d("Coccodrillo"),
    d("COLMAR"),
    d("Colours of the World"),
    d("Columbia"),
    d("Converse"),
    d("CoolClub"),
    d("Coonoor"),
    d("COS"),
    d("CQ"),
    d("Crashone"),
    d("Crivit"),
    d("Cubus"),
    d("Damini"),
    d("Debenhams"),
    d("Decathlon"),
    d("Dedoles"),
    d("Denim"),
    d("DENIM Co"),
    d("Desigual"),
    d("Detex"),
    d("Diadora"),
    d("Didriksons1913"),
    d("Diesel"),
    d("DIMO"),
    d("Dirkje"),
    d("Disana"),
    d("Disney"),
    d("DKNY"),
    d("Dodipetto"),
    d("Dognose"),
    d("Domaja"),
    d("Domyos"),
    d("Donnay"),
    d("Dopodopo"),
    d("DPam (Du Pareil Au Meme)"),
    d("Dromedar"),
    d("ducKsday"),
    d("Dudeskin"),
    d("Early Days"),
    d("Ed Hardy"),
    d("ELLE"),
    d("Elo Melo"),
    d("Elodie Details"),
    d("Emy"),
    d("envy"),
    d("Ergee"),
    d("ESITO"),
    d("Esprit"),
    d("eSTYLE"),
    d("Ethel Austin"),
    d("Etirel"),
    d("Evie Angel"),
    d("Evona"),
    d("Ewa"),
    d("Ewident"),
    d("EXIray"),
    d("Extra-Me"),
    d("F&F"),
    d("F&G"),
    d("Fagottino"),
    d("Feretti"),
    d("Figi"),
    d("Fila"),
    d("Fillikid"),
    d("FIREFLY"),
    d("Fishbone"),
    d("Fixoni"),
    d("Flexi"),
    d("fox & bunny"),
    d("Frog"),
    d("Funky Diva"),
    d("Funky Trunks"),
    d("Funny Bunny"),
    d("Gaji"),
    d("Ganali"),
    d("GANT"),
    d("Gap"),
    d("GAS"),
    d("Gate"),
    d("GAUDI"),
    d("gelert"),
    d("George"),
    d("GEOX"),
    d("Gerber"),
    d("Girl2girl"),
    d("Glo-Story"),
    d("Glorious"),
    d("Gmini"),
    d("Golem"),
    d("Good Children"),
    d("GRACE"),
    d("Guess"),
    d("Gymboree"),
    d("H&M"),
    d("Hannah"),
    d("Hasbro"),
    d("Helada"),
    d("Helly Hansen"),
    d("HEMA"),
    d("Herma"),
    d("Hi-Tec"),
    d("Hollister"),
    d("Horsefeathers"),
    d("HUGO BOSS"),
    d("Hummel"),
    d("Husky"),
    d("Idexe"),
    d("iDO"),
    d("Ikea"),
    d("Impidimpi"),
    d("Imsevimse"),
    d("In Extenso"),
    d("iobio"),
    d("Items"),
    d("Ivemababy"),
    d("Jac"),
    d("Jac Baby"),
    d("Jacadi"),
    d("Jack Wolfskin"),
    d("Jacky"),
    d("Janina"),
    d("Janus"),
    d("Japitex"),
    d("Jayne Copeland"),
    d("Jean Bourget"),
    d("John Lewis"),
    d("John Lewis Baby"),
    d("JOOP!"),
    d("JO\u017d\u00c1NEK"),
    d("Juicy Couture"),
    d("Julius Z\u00f6llner"),
    d("Jumping Beans"),
    d("Just Play"),
    d("Just Too Cute"),
    d("JUUJ"),
    d("K&Y"),
    d("Kaarsgaren"),
    d("Kamulino"),
    d("Kanz"),
    d("Kappa"),
    d("KappAhl"),
    d("KARL LAGERFELD"),
    d("karrimor"),
    d("Katval"),
    d("Katyv Baby"),
    d("Kay"),
    d("Kenguru Gold"),
    d("Kenvelo"),
    d("KENZO"),
    d("KIDS"),
    d("Kids Club"),
    d("Kids Fashion"),
    d("Kid\u2019s Korner"),
    d("Kik"),
    d("Kiki&Koko"),
    d("Kikko"),
    d("Kilimanjaro"),
    d("Kimbaloo"),
    d("Kings"),
    d("Kipsta"),
    d("Koala Baby"),
    d("Kugo"),
    d("Kuniboo"),
    d("KURA"),
    d("L.A. Gear"),
    d("L.O.G.G."),
    d("LA MARTINA"),
    d("Lacoste"),
    d("Lady Ann"),
    d("Ladybird"),
    d("Lafel"),
    d("Lama"),
    d("Lazy Town"),
    d("Le Coq Sportif"),
    d("Lee"),
    d("Lee Cooper"),
    d("Lego"),
    d("Levis"),
    d("Lewro"),
    d("Lindex"),
    d("Litex"),
    d("Little Angel\u00ae"),
    d("Little Bundle"),
    d("Little People"),
    d("Little Star"),
    d("Little Things"),
    d("Liu Jo"),
    d("Loana"),
    d("Loap"),
    d("Lodger"),
    d("Lonsdale"),
    d("LOSAN"),
    d("Lotties"),
    d("Lotto"),
    d("LOUIS VUITTON"),
    d("lubime"),
    d("Lucky Lax"),
    d("lupilu"),
    d("Lusa"),
    d("M&Co"),
    d("Mac"),
    d("Magrof"),
    d("Mamas & Papas"),
    d("Manai"),
    d("Mango"),
    d("Marc O\u00b4Polo"),
    d("MARIKA"),
    d("Mariquita"),
    d("Marks & Spencer"),
    d("Marvel"),
    d("Matalan"),
    d("maybe4baby"),
    d("Mayoral"),
    d("McKINLEY"),
    d("MET"),
    d("Mexx"),
    d("MICHAEL KORS"),
    d("miLe"),
    d("Mills Baby"),
    d("Minene"),
    d("Mini Boden"),
    d("Mini Mode"),
    d("Miniclub"),
    d("Miniwear"),
    d("Minoti"),
    d("Minx"),
    d("Mirau"),
    d("Miss Fiori"),
    d("Modelko"),
    d("MOHITO"),
    d("Monak"),
    d("Monnalisa"),
    d("Monsoon"),
    d("Morris Mouse"),
    d("Mothercare"),
    d("Mountain Warehouse"),
    d("Mud Pie"),
    d("Muddy Puddles"),
    d("My Little Bear"),
    d("Nabaiji"),
    d("Name it"),
    d("NAPAPIJRI"),
    d("Navigator"),
    d("Neckermann"),
    d("Neverest"),
    d("NEW BABY"),
    d("New Balance"),
    d("New Look"),
    d("newbie"),
    d("Next"),
    d("Nickelodeon"),
    d("Nike"),
    d("NKD"),
    d("No Fear"),
    d("Nolita Pocket"),
    d("NORTHFINDER\u00ae"),
    d("Nutmeg"),
    d("O'Neill"),
    d("Obaibi"),
    d("Ocean Pacific"),
    d("Okay"),
    d("Old Navy"),
    d("One by One"),
    d("Ono"),
    d("Original Marines"),
    d("OshKosh"),
    d("Outfit"),
    d("OVS"),
    d("Palomino"),
    d("Pampolina"),
    d("PANDA"),
    d("Parpro Mimi"),
    d("Peanuts"),
    d("PEPCO"),
    d("Pepe Jeans"),
    d("Pepperts"),
    d("Petit Lulu"),
    d("Piazza Italia"),
    d("Pidilidi"),
    d("Pimkie"),
    d("Pinkie"),
    d("PINOKIO"),
    d("Pippa & Julie"),
    d("Pitter Patter"),
    d("PixyBaby"),
    d("Playshoes"),
    d("Pleas"),
    d("POMPdeLUX"),
    d("PRADA"),
    d("Pretty girl"),
    d("Primark"),
    d("PRIMIGI"),
    d("Prince Oliver"),
    d("Pr\u00e9maman"),
    d("Pr\u00e9natal"),
    d("PUMA"),
    d("Pumpkin Patch"),
    d("Pupill"),
    d("Quechua"),
    d("Queen"),
    d("Quelle"),
    d("Quiksilver"),
    d("Rada"),
    d("Radetex"),
    d("Ralph Lauren"),
    d("Rappazin"),
    d("Rare Editions"),
    d("RDX"),
    d("Rebel"),
    d("Rebel Kids"),
    d("Reebok"),
    d("Reflex"),
    d("Regatta"),
    d("Reima"),
    d("Relax"),
    d("Renka"),
    d("Repal"),
    d("Replay"),
    d("Reporter"),
    d("Reserved"),
    d("Respect"),
    d("REVIEW"),
    d("Richelieu"),
    d("Rifle"),
    d("RINASCIMENTO"),
    d("RIP CURL"),
    d("RIVER ISLAND"),
    d("Robert"),
    d("Rocha.Little.Rocha"),
    d("Rockino"),
    d("Rosalita Senoritas"),
    d("Rose"),
    d("Roxy"),
    d("s.Oliver"),
    d("SALEWA"),
    d("Salomon"),
    d("Sando"),
    d("Sanetta"),
    d("Sanrio"),
    d("scamp"),
    d("Scarlett"),
    d("Schiesser"),
    d("Schnizler"),
    d("Select"),
    d("Sergent Major"),
    d("Setino"),
    d("SILVIAN HEACH"),
    d("sinsay"),
    d("SISLEY"),
    d("Skapi"),
    d("SKOGSTAD"),
    d("Slazenger"),
    d("Slovenka"),
    d("Smafolk"),
    d("Smash!"),
    d("Smile"),
    d("Sofija"),
    d("Soft Touch"),
    d("Solla"),
    d("Speedo"),
    d("Splash About"),
    d("SPORTALM"),
    d("St Michael"),
    d("St. Bernard"),
    d("STACCATO"),
    d("Sterntaler"),
    d("Stormberg"),
    d("Success"),
    d("Sucre d'Orge"),
    d("Sugar Pink"),
    d("Sweet Baby"),
    d("T&Go"),
    d("Tajpex"),
    d("Takko"),
    d("Tally Weijl"),
    d("TATRASVIT"),
    d("Tchibo"),
    d("TCM"),
    d("TED BAKER"),
    d("Teeny Tiny"),
    d("Terranova"),
    d("Tesco"),
    d("Texbasic"),
    d("Tezenis"),
    d("The Gro Company (Grobag)"),
    d("Tik & Tak"),
    d("Timberland"),
    d("Timbuktoo"),
    d("Timiami"),
    d("Tinko"),
    d("Tiny Ted"),
    d("Tom Tailor"),
    d("Tommy Hilfiger"),
    d("Topolino"),
    d("Topomini"),
    d("Track&Field"),
    d("Tribord"),
    d("TROLLKIDS"),
    d("TU"),
    d("tuc tuc"),
    d("Tulec Trend"),
    d("Tup Tup"),
    d("U.S. POLO ASSN."),
    d("Umbro"),
    d("UNDER ARMOUR"),
    d("unuo"),
    d("Urban Rascals"),
    d("US Angels"),
    d("Versace"),
    d("Vertbaudet"),
    d("Vienetta Kids"),
    d("Vingino"),
    d("Vl\u010dek"),
    d("Wedze"),
    d("Weiser"),
    d("Wenice"),
    d("William & Delvin"),
    d("Wojcik"),
    d("Wolf"),
    d("WOXX"),
    d("Yetty"),
    d("YIGGA"),
    d("Yorker"),
    d("Young Dimension"),
    d("Yumi"),
    d("ZAJO"),
    d("Zara"),
    d("Zip Zap"),
    ["HANDMADE", "Handmade"],
    ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
];
