/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const thermometers : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["CONTACTLESS", "Bezkontaktn\u00e9"],
        ["CONTACT", "Kontaktn\u00e9"],
        ["CHILDRENS", "Detsk\u00e9"],
    ],
};
