/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp7 } from './commonProp7';
import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const occasion_wear : {[key: string]: Array<[string, string]>} = {
    p_occasion: [
        ["BAPTISM", "Krst"],
        ["WEDDING", "Svadba"],
        ["XMAS", "Vianoce"],
        ["BIRTHDAY", "Narodeniny"],
        ["FIRST_HOLY_COMMUNION", "Prv\u00e9 sv\u00e4t\u00e9 prij\u00edmanie"],
        ["PHOTOGRAPHY", "Fotenie"],
    ],
    p_type: [
        ["CLOTHING", "Oble\u010denie"],
        ["BABY_WRAP", "Perinka"],
        ["FASHION_ACCESSORIES", "M\u00f3dny doplnok"],
    ],
    p_size: commonProp7,
    p_sex: commonProp5,
    p_color: commonProp4,
};
