/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp18 } from './commonProp18';
import { commonProp4 } from './commonProp4';

export const other_electronics : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["DIGITAL_PHOTO_FRAME", "Digit\u00e1lny fotor\u00e1mik"],
        ["RECHARGEABLE_BATTERIES_CHARGERS", "Bat\u00e9rie a nab\u00edjanie"],
        ["HEADPHONES", "Sl\u00fachadl\u00e1"],
        ["WEATHER_STATIONS", "Meteostanice"],
        ["SMART_WATCHES", "Inteligentn\u00e9 hodinky"],
        ["OTHER_TYPE_ELECTRONICS", "In\u00e1 elektronika"],
    ],
    p_brand: commonProp18,
    p_color: commonProp4,
};
