/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp0: Array<[string, string]> = [
    ["SHORT_SLEEVE", "Kr\u00e1tky ruk\u00e1v"],
    ["THREE_QUARTER_SLEEVE", "3/4 ruk\u00e1v"],
    ["LONG_SLEEVE", "Dlh\u00fd ruk\u00e1v"],
    ["NO_SLEEVE", "Bez ruk\u00e1vov"],
];
