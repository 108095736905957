/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp16 } from './commonProp16';
import { commonProp4 } from './commonProp4';

export const face_masks : {[key: string]: Array<[string, string]>} = {
    p_for_whom: commonProp16,
    p_material: [
        ["NANOROUCHES", "Nanor\u00fa\u0161ka"],
        ["FROM_FABRIC", "L\u00e1tkov\u00e9"],
        ["DISPOSABLE", "Jednorazov\u00e9"],
    ],
    p_fit: [
        ["FIT_RUBBER_BANDS", "Gumi\u010dky"],
        ["FIT_LACES", "\u0160n\u00farky"],
    ],
    p_layers: [
        ["SINGLE_LAYER", "Jednovrstvov\u00e9"],
        ["MULTI_LAYER", "Viacvrstvov\u00e9"],
    ],
    p_color: commonProp4,
};
