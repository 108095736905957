/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const kids_party : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["PLATES", "Taniere"],
        ["CUPS", "Poh\u00e1re"],
        ["TABLE_CLOTHS", "Obrusy"],
        ["NAPKINS", "Serv\u00edtky"],
        ["DRINKING_STRAWS", "Slamky"],
        ["PARTY_SET", "P\u00e1rty set"],
        ["PARTY_CANDLES_CAKE_ACCESSORIES", "Svie\u010dky a ozdoby na tortu"],
        ["BALLONS", "Nafukovacie bal\u00f3ny"],
        ["TABLE_DECORATIONS", "Dekor\u00e1cia na st\u00f4l"],
        ["HANGING_DECORATIONS", "Z\u00e1vesn\u00e9 dekor\u00e1cie"],
        ["INVITATIONS_NAME_CARDS", "Pozv\u00e1nky, menovky"],
        ["HEAD_ACCESSORIES", "\u010celenky, klob\u00fa\u010diky"],
        ["PARTY_GAMES", "Hry a z\u00e1bava na p\u00e1rty"],
        ["KIDS_PARTY_OTHER", "Ostatn\u00e9"],
    ],
    p_thema: [
        ["THEMA_TRANSPORTATIONS", "Aut\u00e1, vlaky a dopravn\u00e9 prostriedky"],
        ["THEMA_DINOSAUR", "Dinosauri"],
        ["THEMA_FOOTBALL", "Futbal"],
        ["THEMA_PIRATES_SAILORS", "Pir\u00e1ti, pir\u00e1tky a n\u00e1morn\u00edci"],
        ["THEMA_PRINCESS_FAIRY_BALLET", "Princezn\u00e9, v\u00edly a tane\u010dnice"],
        ["THEMA_PRINCE_KNIGHT", "Princovia a rytieri"],
        ["THEMA_CLOWN", "\u0160a\u0161ovia a klauni"],
        ["THEMA_SPACE", "Vesm\u00edr"],
        ["THEMA_ANIMALS", "Zvierat\u00e1 a hmyz"],
        ["THEMA_101_DALMATIANS", "101 dalmat\u00edncov"],
        ["THEMA_ANGRY_BRIDS", "Angry Birds"],
        ["THEMA_CARS_MCQUEEN", "Aut\u00e1 (Blesk McQueen)"],
        ["THEMA_AVENGERS", "Avengers"],
        ["THEMA_BARBIE", "Barbie"],
        ["THEMA_BATMAN", "Batman"],
        ["THEMA_DUMBO", "Dumbo"],
        ["THEMA_HELLO_KITTY", "Hello Kitty"],
        ["THEMA_PLANES_DISNEY", "Lietadl\u00e1 (Planes)"],
        ["THEMA_LITTLE_PET_SHOP", "Little Pet Shop"],
        ["THEMA_FROZEN_DISNEY", "\u013dadov\u00e9 kr\u00e1\u013eovstvo (Frozen)"],
        ["THEMA_WINNIE-THE-POOH", "Macko Pu"],
        ["THEMA_MASHA_AND_THE_BEAR", "M\u00e1\u0161a a medve\u010f"],
        ["THEMA_MIA_AND_ME", "Mia a ja (Mia and Me)"],
        ["THEMA_MICKEY_MOUSE", "Mickey Mouse"],
        ["THEMA_MINIE_MOUSE", "Minie Mouse"],
        ["THEMA_THE_MINIONS", "Mimoni (The Minions)"],
        ["THEMA_MONSTER_HIGH", "Monster High"],
        ["THEMA_MY_LITTLE_PONY", "My Little Pony"],
        ["THEMA_NEMO", "Nemo"],
        ["THEMA_NINJA_TURTLES", "Ninja korytna\u010dky"],
        ["THEMA_PEPPA_PIG", "Peppa Pig"],
        ["THEMA_CINDERELLA", "Popolu\u0161ka (Cinderella)"],
        ["THEMA_FIREMAN_SAM", "Po\u017eiarnik Sam"],
        ["THEMA_SOFIA_THE_FIRST", "Princezn\u00e1 Sofia prv\u00e1"],
        ["THEMA_MONSTERS_INC", "Pr\u00ed\u0161erky (Monsters)"],
        ["THEMA_SCOOBY_DOO", "Scooby Doo"],
        ["THEMA_SHREK", "Shrek"],
        ["THEMA_SNOW_WHITE", "Snehulienka"],
        ["THEMA_SPIDERMAN", "Spiderman"],
        ["THEMA_SPONGE_BOB", "Sponge Bob"],
        ["THEMA_STAR_WARS", "Star Wars"],
        ["THEMA_THE_SMURFS", "\u0160molkovia"],
        ["THEMA_TRANSFORMERS", "Transformers"],
        ["THEMA_MAYA_THE_BEE", "V\u010dielka Maja"],
        ["THEMA_THOMAS", "Vl\u00e1\u010dik Tom\u00e1\u0161"],
        ["THEMA_CILILING", "Zvonilka (Cililing)"],
        ["THEMA_WEDDING", "Svadba"],
        ["THEMA_BABY_BORN", "Narodenie die\u0165a\u0165a"],
        ["THEMA_ANNIVERSARY", "V\u00fdro\u010die"],
        ["THEMA_STAG_PARTY", "Rozl\u00fa\u010dka so slobodou"],
        ["THEMA_OTHER", "In\u00e1 t\u00e9ma"],
    ],
    p_sex: commonProp5,
    p_color: commonProp4,
};
