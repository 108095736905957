/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp7 } from './commonProp7';
import { commonProp5 } from './commonProp5';
import { commonProp6 } from './commonProp6';
import { commonProp4 } from './commonProp4';

export const sweatshirts_jumpers_vests : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["SWEATSHIRT", "Mikina"],
        ["KNITWEAR", "Sveter, pul\u00f3ver"],
        ["VESTS", "Vesta (nezateplen\u00e1)"],
        ["BOLERO", "Bolerko"],
        ["ROLLNECK", "Rol\u00e1k"],
        ["PONCHO", "Pon\u010do"],
    ],
    p_size: commonProp7,
    p_sex: commonProp5,
    p_dressing: [
        ["WITH_CLOSING", "So zap\u00ednan\u00edm"],
        ["OVER_HEAD", "Cez hlavu"],
    ],
    p_hood: [
        ["WITH_HOOD", "S kapuc\u0148ou"],
        ["WITHOUT_HOOD", "Bez kapucne"],
    ],
    p_brand: commonProp6,
    p_color: commonProp4,
};
