/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp21 } from './commonProp21';
import { commonProp4 } from './commonProp4';

export const home_accessories_decorations : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["PHOTOS_PHOTO_FRAMES", "Obrazy a r\u00e1my"],
        ["MIRRORS", "Zrkadl\u00e1"],
        ["TAPESTRY_WALL_STICKERS", "Tapety, bord\u00fary a n\u00e1lepky"],
        ["NOTICEBOARDS", "Menovky, tabu\u013eky a z\u00e1vesn\u00e9 dekor\u00e1cie"],
        ["CLOCKS", "Hodiny, bud\u00edky"],
        ["HOME_SCULPTURES_FIGURINES", "Mal\u00e9 nez\u00e1vesn\u00e9 dekor\u00e1cie"],
        ["MAGNETS", "Magnetky"],
        ["FLOWER_ARRANGEMENTS", "Kytice, ikebany"],
        ["VASES_BOWLS", "V\u00e1zy a misky"],
        ["PLANTS_PLANT_POTS", "Izbov\u00e9 kvety a kvetin\u00e1\u010de"],
        ["CANDLE_HOLDERS_CANDLES", "Svie\u010dky, svietniky a lamp\u00e1\u0161e"],
        ["STORAGE_BOXES_BASKETS_BAGS", "\u0160katule, ko\u0161e a ta\u0161ky"],
        ["WASTE_SORTING_BINS_BAGS", "Vence"],
        ["LAUNDRY_BASKETS", "Doplnky do skrine, \u0161atn\u00edka"],
        ["BATHROOM_ACCESSORIES", "Doplnky do k\u00fape\u013ene a WC"],
        ["OFFICE_ACCESSORIES", "Doplnky do pracovne"],
        ["OTHER_HOME_ACCESSORIES", "In\u00e9"],
    ],
    p_thema: commonProp21,
    p_color: commonProp4,
};
