/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp7 } from './commonProp7';
import { commonProp5 } from './commonProp5';
import { commonProp6 } from './commonProp6';
import { commonProp4 } from './commonProp4';

export const trousers_jeans_joggers : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["TROUSERS", "Nohavice"],
        ["JEANS", "Rifle"],
        ["JOGGERS", "Tepl\u00e1ky"],
    ],
    p_braces: [
        ["WITH_BRACES", "S trakmi"],
        ["NO_BRACES", "Bez trakov"],
    ],
    p_size: commonProp7,
    p_sex: commonProp5,
    p_brand: commonProp6,
    p_color: commonProp4,
};
