/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp4 } from './commonProp4';

export const work_clothes_footwear : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["WORK_CLOTHES", "Oble\u010denie"],
        ["WORK_FOOTWEAR", "Obuv"],
        ["PROTECTIVE_EQUIPMENT", "Ochrann\u00e9 pom\u00f4cky"],
    ],
    p_size: [
        d("XS"),
        d("S"),
        d("M"),
        d("L"),
        d("XL"),
        d("XXL"),
        d("XXXL"),
        d("4XL"),
        d("5XL"),
        d("24"),
        d("26"),
        d("28"),
        d("30"),
        d("32"),
        d("34"),
        d("35"),
        d("36"),
        d("37"),
        d("38"),
        d("39"),
        d("40"),
        d("41"),
        d("42"),
        d("43"),
        d("44"),
        d("45"),
        d("46"),
        d("47"),
        d("48"),
        d("49"),
        d("50"),
        d("51"),
        d("52"),
        d("54"),
        d("56"),
        d("58"),
        d("60"),
        d("62"),
        ["ONE_SIZE_FIT_ALL", "Univerz\u00e1lna ve\u013ekos\u0165"],
    ],
    p_sex: [
        ["WOMENS", "Pre d\u00e1my"],
        ["MENS", "Pre p\u00e1nov"],
    ],
    p_color: commonProp4,
};
