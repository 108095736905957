/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const hobby_classes : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["MUSIC_CLASS", "Hudba"],
        ["THEATER_AND_SINGING_CLASS", "Divadlo a spev"],
        ["SPORTS_AND_DANCING_CLASS", "\u0160port a tanec"],
        ["HORSEBACK_RIDING_CLASS", "Jazdectvo"],
        ["CERAMICS_CLASS", "Keramika"],
        ["MODELER_AND_HANDYMAN_CLASS", "Model\u00e1r a kutil"],
        ["MULTIMEDIA_AND_GAMES_CLASS", "Multim\u00e9dia a PC hry"],
        ["OUTDOOR_AND_SELFDEFENSE_CLASS", "Outdoor a sebaobrana"],
        ["SCIENCE_AND_BREEDING_CLASS", "Pr\u00edrodoveda a chovate\u013estvo"],
        ["CREATING_AND_SEWING_CLASS", "Tvorenie a \u0161itie"],
        ["ART_ACTIVITIES_CLASS", "V\u00fdtvarn\u00e9 \u010dinnosti"],
        ["FOREIGN_LANGUAGES_CLASS", "Cudzie jazyky"],
    ],
    p_for_whom: [
        ["FOR_KIDS", "Pre deti"],
        ["FOR_PARENTS_WITH_KIDS", "Pre rodi\u010dov s de\u0165mi"],
        ["FOR_ADULTS", "Pre dospel\u00fdch"],
    ],
};
