/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp9: Array<[string, string]> = [
    ["SEASON_SUMMER", "\u013eahk\u00e9 / letn\u00e9"],
    ["SEASON_WINTER", "ve\u013emi tepl\u00e9 / zimn\u00e9"],
    ["SEASON_OTHER", "in\u00e9"],
];
