/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp4 } from './commonProp4';

export const twins_misc : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["CAR_SEATS", "Autoseda\u010dky"],
        ["COTS", "Postele, ohr\u00e1dky a pr\u00edslu\u0161enstvo"],
        ["BABY_FEEDING", "Potreby pre doj\u010dat\u00e1"],
        ["TOYS", "Hra\u010dky"],
        ["BABY_MISC", "In\u00e9 detsk\u00e9"],
    ],
    p_color: commonProp4,
};
