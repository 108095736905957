/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const misc_motherhood : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["PREGNANCY_BELT", "Podporn\u00fd p\u00e1s"],
        ["PREGNANCY_PILLOW", "Tehotensk\u00fd vank\u00fa\u0161"],
        ["PREGNANCY_MASSAGE", "Mas\u00e1\u017e a cvi\u010denie"],
        ["PREGNANCY_COSMETICS", "Tehotensk\u00e1 kozmetika, kr\u00e9my, strie"],
        ["PREGNANCY_DIAGNOSTICS", "Ovula\u010dn\u00fd a tehotensk\u00fd test"],
        ["PREGNANCY_GADGETS", "Dom\u00e1ci ultrazvuk, mikroskop, tlak, teplomer"],
        ["SEAT_BELT", "Bezpe\u010dnostn\u00fd p\u00e1s"],
    ],
};
