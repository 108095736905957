/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const trampolines_trampolines : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: [
        d("<150 cm"),
        d("150-199 cm"),
        d("200-249 cm"),
        d("250-299 cm"),
        d("300-349 cm"),
        d("350-399 cm"),
        d("400-449 cm"),
        d("450-499 cm"),
        d("500+ cm"),
    ],
    p_brand: [
        d("acra"),
        d("aga"),
        d("aga sport fit"),
        d("aga sport pro"),
        d("duvlan"),
        d("fun fit"),
        d("galt"),
        d("marimex"),
        d("masterjump"),
        d("nabbi"),
        d("plum"),
        d("sedco"),
        d("spartan"),
        d("sulov"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
