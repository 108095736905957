/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp7 } from './commonProp7';
import { commonProp5 } from './commonProp5';
import { commonProp6 } from './commonProp6';
import { commonProp4 } from './commonProp4';

export const outfits : {[key: string]: Array<[string, string]>} = {
    p_season: [
        ["SEASON_SUMMER", "\u013eahk\u00e9 / letn\u00e9"],
        ["SEASON_SPRING_AUTUMN", "prechodn\u00e9 / jarn\u00e9, jesenn\u00e9"],
        ["SEASON_ANYTIME_HOME", "celoro\u010dn\u00e9 / na doma"],
    ],
    p_size: commonProp7,
    p_sex: commonProp5,
    p_brand: commonProp6,
    p_color: commonProp4,
};
