/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp22 } from './commonProp22';

export const tutoring : {[key: string]: Array<[string, string]>} = {
    p_school_subject: [
        ["SLOVAK_LANGUAGE", "Slovensk\u00fd jazyk"],
        ["CZECH_LANGUAGE", "\u010cesk\u00fd jazyk"],
        ["ENGLISH_LANGUAGE", "Anglick\u00fd jazyk"],
        ["GERMAN_LANGUAGE", "Nemeck\u00fd jazyk"],
        ["ITALIAN_LANGUAGE", "Taliansky jazyk"],
        ["RUSSIAN_LANGUAGE", "Rusk\u00fd jazyk"],
        ["FRENCH_LANGUAGE", "Franc\u00fazsky jazyk"],
        ["SPANISH_LANGUAGE", "\u0160panielsky jazyk"],
        ["SUBJECT_OTHER_LANGUAGE", "In\u00fd jazyk"],
        ["MATHEMATICS", "Matematika"],
        ["CHEMISTRY", "Ch\u00e9mia"],
        ["PHYSICS", "Fyzika"],
        ["BIOLOGY", "Biol\u00f3gia"],
        ["GEOGRAPHY", "Zemepis"],
        ["HISTORY", "Dejepis"],
    ],
    p_level: [
        ["ELEMENTARY_SCHOOL", "1. stupe\u0148 Z\u0160"],
        ["MIDDLE_SCHOOL", "2. stupe\u0148 Z\u0160"],
        ["HIGH_SCHOOL", "S\u0160"],
        ["COLLEGE", "V\u0160"],
    ],
    p_frequency: [
        ["REGULARLY", "Pravidelne"],
        ["IRREGULARLY", "Nepravidelne"],
    ],
    p_place: [
        ["PUPIL_RESIDENCE", "V bydlisku \u017eiaka"],
        ["TEACHER_RESIDENCE", "V bydlisku u\u010dite\u013ea"],
    ],
    p_practice_length: commonProp22,
};
