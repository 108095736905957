/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp4 } from './commonProp4';

export const cots : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["WOODEN_COTS", "Dreven\u00e1"],
        ["METAL_BEDS", "Kovov\u00e9"],
        ["BUNK_BEDS", "Poschodov\u00e1"],
        ["TRAVEL_COTS", "Cestovn\u00e1"],
        ["BOX_COTS", "Ohr\u00e1dky"],
        ["MATTRESS", "Matrac"],
        ["BED_SHEETS", "Oblie\u010dka, plachta, vank\u00fa\u0161, perina"],
        ["COT_NESTS", "Hniezdo"],
        ["SAFETY_BARRIER", "Bezpe\u010dnostn\u00e1 z\u00e1brana"],
        ["POSITIONING_ROLLERS", "Polohovacie valce apod."],
        ["WEDGE_PILLOW", "Klinov\u00e9 vank\u00fa\u0161e, podhlavn\u00ed\u010dky"],
        ["COT_ACCESSORIES", "Pr\u00edslu\u0161enstvo"],
    ],
    p_width_ordinal: [
        d("30"),
        d("35"),
        d("40"),
        d("45"),
        d("50"),
        d("55"),
        d("60"),
        d("65"),
        d("70"),
        d("75"),
        d("80"),
        d("85"),
        d("90"),
        d("95"),
        d("100"),
        d("110"),
        d("120"),
        d("130"),
        d("135"),
        d("140"),
        d("150"),
        d("160"),
        d("170"),
        d("180"),
        ["SIZE_OTHER", "In\u00e1"],
    ],
    p_length_ordinal: [
        d("30"),
        d("40"),
        d("50"),
        d("60"),
        d("70"),
        d("80"),
        d("90"),
        d("100"),
        d("110"),
        d("120"),
        d("130"),
        d("135"),
        d("140"),
        d("150"),
        d("160"),
        d("170"),
        d("180"),
        d("190"),
        d("200"),
        d("210"),
        d("220"),
        ["SIZE_OTHER", "In\u00e1"],
    ],
    p_color: commonProp4,
};
