/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp22 } from './commonProp22';

export const craftsmen : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["ELECTRICIAN", "Elektrik\u00e1r"],
        ["PLUMBER", "In\u0161talat\u00e9r"],
        ["CABINET_MAKER", "Stol\u00e1r"],
        ["BRICKLAYER", "Mur\u00e1r"],
        ["PAINTER", "Maliar"],
        ["MOVER", "S\u0165ahovanie"],
        ["ROOFER", "Pokr\u00fdva\u010d"],
        ["EXCAVATOR", "Bagrista"],
        ["LOCKSMITH", "Z\u00e1mo\u010dn\u00edk"],
        ["DRILLER", "V\u0155tanie"],
        ["ASSEMBLY", "Mont\u00e1\u017e"],
        ["CARPET_CLEANING", "\u010cistenie kobercov"],
        ["CHIMNEY_SWEEP", "Komin\u00e1r"],
        ["CAR_SERVICE", "Servis a oprava \u00e1ut"],
        ["BICYCLE_SERVICE", "Cykloservis"],
        ["WASHING_MACHINE_DRYER_DISHWASHER_SERVICE", "Servis a oprava pr\u00e1\u010diek, su\u0161i\u010diek, um\u00fdva\u010diek"],
        ["STOVE_SERVICE", "Servis a oprava spor\u00e1kov"],
        ["REFRIGERATOR_AND_FREEZER_SERVICE", "Servis a oprava chladni\u010diek a mrazni\u010diek"],
        ["PC_AND_MOBILE_PHONE_SERVICE", "Servis a oprava PC a mobilov"],
        ["TV_SERVICE", "Servis a oprava TV"],
        ["GARDEN_SERVICE", "Z\u00e1hrada"],
        ["OTHER_CRAFTSMEN", "In\u00e9"],
    ],
    p_practice_length: commonProp22,
};
