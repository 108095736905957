/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const disinfection : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["SPRAY", "Sprej"],
        ["GEL", "G\u00e9l"],
        ["UNIVERSAL", "Univerz\u00e1lna"],
        ["OTHER", "In\u00e9"],
    ],
};
