/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const horseback_riding_helmets : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_brand: [
        d("Casco"),
        d("Choplin"),
        d("Covalliero"),
        d("Dainese"),
        d("Equestro"),
        d("Equi-Th\u00e9me"),
        d("Freejump"),
        d("HKM"),
        d("Horze"),
        d("HyaCli"),
        d("Kasp"),
        d("Kavalkade"),
        d("KED"),
        d("Kentaur"),
        d("Kep"),
        d("KOO"),
        d("LAS"),
        d("Pikeur"),
        d("QHP"),
        d("Samshield"),
        d("Swing"),
        d("Swing K4"),
        d("Tattini"),
        d("Uvex"),
        d("Zandona"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
