/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp21: Array<[string, string]> = [
    ["THEMA_ALL_YEAR_ROUND", "Celoro\u010dn\u00e9"],
    ["THEMA_XMAS", "Vianoce"],
    ["THEMA_EASTER", "Ve\u013ek\u00e1 noc"],
    ["THEMA_HALLOWEEN", "Halloween"],
    ["THEMA_SPRING", "Jar"],
    ["THEMA_SUMMER", "Leto"],
    ["THEMA_AUTUMN", "Jese\u0148"],
    ["THEMA_WINTER", "Zima"],
];
