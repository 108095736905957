/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp19 } from './commonProp19';
import { commonProp4 } from './commonProp4';

export const trucks : {[key: string]: Array<[string, string]>} = {
    p_brand: [
        d("Avia"),
        d("Citro\u00ebn"),
        d("DAF"),
        d("Iveco"),
        d("Fiat"),
        d("Ford"),
        d("GAZ"),
        d("LIAZ"),
        d("Mercedes-Benz"),
        d("Mitsubishi"),
        d("Multicar"),
        d("Peugeot"),
        d("Renault"),
        d("Scania"),
        d("\u0160koda"),
        d("Tatra"),
        d("Volvo"),
        ["OTHER_BRAND", "In\u00e1 zna\u010dka"],
    ],
    p_weight: [
        ["UNDER_3_5T", "do 3.5t"],
        ["3_5_TO_7_5T", "3.5t-7.5t"],
        ["OVER_7_5T", "nad 7.5t"],
    ],
    p_transmission: commonProp19,
    p_color: commonProp4,
};
